import React from 'react';

import path from 'path';

import {
	AssignmentTurnedInOutlined as AssignmentTurnedInOutlinedIcon,
	CalendarToday as CalendarTodayIcon,
	CheckCircleOutlined as CheckCircleOutlinedIcon,
	Devices as DevicesIcon,
	Euro as EuroIcon,
	GroupWorkOutlined as GroupWorkOutlinedIcon,
	Handshake as HandshakeIcon,
	HomeOutlined as HomeOutlinedIcon,
	HowToRegOutlined as HowToRegOutlinedIcon,
	LocationOnOutlined as LocationOnOutlinedIcon,
	Login as LoginIcon,
	MapOutlined as MapOutlinedIcon,
	Money as MoneyIcon,
	PeopleAltOutlined as PeopleAltOutlinedIcon,
	RadioButtonChecked as RadioButtonCheckedIcon,
	SettingsOutlined as SettingsOutlinedIcon,
	ShowChart as ShowChartIcon,
	WebAsset as WebAssetIcon,
} from '@mui/icons-material';
import {
	Avatar,
	Link as MuiLink,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	ListSubheader,
	Stack,
	Divider,
	useTheme,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link as RouterLink } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { pagePathSegments } from '~constants';
import { UserRoleEnum } from '~enums';

type Page = {
	// Define this property to be able to differentiate between a section
	// and a page.
	key: string;
	path: string;
	roles?: UserRoleEnum[];
	icon?: JSX.Element;
};

type Section = {
	key: string;
	pages: Page[];
	roles?: UserRoleEnum[];
};

interface MainNavBarProps {}

const MainNavBar = () => {
	const { t } = useTranslation('general');
	const auth = useAuth();
	const theme = useTheme();
	const role = (auth.user?.profile.role as string).toLowerCase() as UserRoleEnum;

	const userInfo = useAtomValue(userInfoAtom);

	/**
	 * TODO: this definitely shouldn't be done like this.
	 * Seems it was done like this to clear the filters used all over the app. But
	 * this is asking for trouble when other components used the session storage
	 * @deprecated
	 */
	const handleClearFilters = () => {
		sessionStorage.clear();
	};

	const sections: Section[] = [
		{
			key: 'bookings',
			pages: [
				{
					key: 'nav.category.dashboard',
					path: pagePathSegments.Dashboard,
					icon: <HomeOutlinedIcon />,
				},
				{
					key: 'nav.category.planboard',
					path: pagePathSegments.Planboard,
					icon: <MoneyIcon />,
				},
				{
					key: 'nav.category.bookings',
					path: pagePathSegments.Bookings,
					icon: <CalendarTodayIcon />,
				},
			],
		},
		{
			key: 'items',
			pages: [
				{
					key: 'nav.category.items',
					path: path.join(pagePathSegments.ItemManagement, pagePathSegments.Items),
					icon: <WebAssetIcon />,
				},
				{
					key: 'nav.category.fleetManagement',
					path: pagePathSegments.MapView,
					icon: <MapOutlinedIcon />,
				},
				{
					key: 'nav.category.locations',
					path: path.join(pagePathSegments.LocationManagement, pagePathSegments.Locations),
					icon: <LocationOnOutlinedIcon />,
				},
				{
					key: 'nav.category.maintenance',
					path: path.join(pagePathSegments.Ticketing, pagePathSegments.Open),
					icon: <AssignmentTurnedInOutlinedIcon />,
				},
				{
					key: 'nav.category.policies',
					path: path.join(pagePathSegments.PolicyManagement, pagePathSegments.ItemPolicies),
					icon: <CheckCircleOutlinedIcon />,
				},
				...(import.meta.env.VITE_ENABLE_ACCESS_MANAGEMENT === 'true' ?
					[
						{
							key: 'nav.category.accessManagement',
							path: path.join(pagePathSegments.Access, pagePathSegments.Rules),
							icon: <LoginIcon />,
						},
					]
				:	[]),
				...(userInfo.organisation.features.includes('devices') || role === UserRoleEnum.SuperAdmin ?
					[
						{
							key: 'nav.category.devices',
							path: path.join(pagePathSegments.Devices, pagePathSegments.Assigned),
							icon: <DevicesIcon />,
						},
					]
				:	[]),
			],
		},
		{
			key: 'users',
			pages: [
				{
					key: 'nav.category.users',
					path: path.join(pagePathSegments.UserManagement, pagePathSegments.Users),
					icon: <PeopleAltOutlinedIcon />,
				},
				{
					key: 'nav.category.organisations',
					path: path.join(pagePathSegments.OrganisationManagement, pagePathSegments.Organisations),
					icon: <RadioButtonCheckedIcon />,
					roles: [UserRoleEnum.SuperAdmin],
				},
				{
					key: 'nav.category.contracts',
					path: path.join(pagePathSegments.Contracts, pagePathSegments.Contracts),
					icon: <HandshakeIcon />,
				},
				{
					key: 'nav.category.partnerships',
					path: path.join(pagePathSegments.Partnerships, pagePathSegments.Partners),
					icon: <GroupWorkOutlinedIcon />,
					roles: [UserRoleEnum.Admin],
				},
				{
					key: 'nav.category.licenses',
					path: path.join(pagePathSegments.Licenses, pagePathSegments.Pending),
					icon: <HowToRegOutlinedIcon />,
					roles: [UserRoleEnum.SuperAdmin],
				},
			],
		},
		{
			key: 'finances',
			pages: [
				{
					key: 'ui.finance',
					path: path.join(pagePathSegments.FinanceManagement, pagePathSegments.Revenue),
					icon: <EuroIcon />,
				},
			],
		},
		{
			key: 'statistics',
			pages: [
				{
					key: 'ui.header.co2',
					path: path.join(pagePathSegments.Statistics, pagePathSegments.Co2),
					icon: <ShowChartIcon />,
				},
			],
		},
		{
			key: 'settings',
			roles: [UserRoleEnum.SuperAdmin],
			pages: [
				{
					key: 'nav.category.settings',
					path: path.join(pagePathSegments.Settings, pagePathSegments.Categories),
					icon: <SettingsOutlinedIcon />,
				},
			],
		},
	];

	return (
		<Stack divider={<Divider />} spacing={2} mx={2} mt={2} mb={4}>
			<Stack direction='column' spacing={1} alignItems='center'>
				<Avatar
					alt='organisation'
					src={userInfo.organisation.logo}
					sx={{ width: 60, height: 60, '& img': { objectFit: 'scale-down' } }}
				/>
				<Stack direction='column' alignItems='center'>
					<MuiLink
						color='inherit'
						variant='h5'
						component={RouterLink}
						to={`/${path.join(pagePathSegments.Organisations, userInfo.organisation.id, pagePathSegments.Summary)}`}
					>
						{userInfo.organisation.label}
					</MuiLink>
					<MuiLink
						color='inherit'
						variant='body2'
						component={RouterLink}
						to={`/${path.join(pagePathSegments.UserManagement, pagePathSegments.Users, userInfo.id, pagePathSegments.Summary)}`}
					>
						{userInfo.firstName} {userInfo.lastName}
					</MuiLink>
				</Stack>
			</Stack>

			<List disablePadding>
				{sections
					.filter((el) => !el.roles || el.roles.includes(role))
					.map((section) => (
						<React.Fragment key={section.key}>
							<ListSubheader sx={{ lineHeight: 2, pt: 2, pb: 0 }}>
								{t(`nav.topic.${section.key}`)}
							</ListSubheader>
							{section.pages
								.filter((el) => !el.roles || el.roles?.includes(role))
								.map((page) => (
									<ListItem key={page.key} disablePadding>
										<ListItemButton
											component={RouterLink}
											to={`${page.path}`}
											onClick={handleClearFilters}
											sx={{ borderRadius: 10 }}
										>
											<ListItemIcon>{page.icon}</ListItemIcon>
											<ListItemText
												sx={{ fontSize: 14, fontWeight: theme.typography.fontWeightMedium }}
											>
												{t(page.key)}
											</ListItemText>
										</ListItemButton>
									</ListItem>
								))}
						</React.Fragment>
					))}
			</List>
		</Stack>
	);
};

export default MainNavBar;
