import { useState, useEffect } from 'react';

import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Box, Link } from '@mui/material';
import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { userInfoAtom } from '~atoms';
import { ServiceTicketStatusChip, TicketSeverityChip } from '~features/maintenance';

import Dialog from './Dialog/Dialog';
import { useStyles } from './style';
import { Table, GenericMoreButton, SearchBar, Label, EmptyState } from '../../../components';
import { commaTimeStrings } from '../../../shared/datetime';
import {
	useSearchComponent,
	useBasicFilter,
	useComplexFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isObject,
	isEmptyString,
	isFullString,
	isNumber,
	isEmptyArray,
	isUndefined,
	isFullArray,
	isInteger,
	isArray,
	isNull,
} from '../../../shared/utility';
import * as actions from '../../../store/actions/index';
import { useAuthorize } from '~features/authentication';

const ServiceTickets = (props) => {
	const {
		typeOfTickets,
		onFetchTickets,
		fetchTickets,

		onFetchPartnerships,
		fetchPartnerships,
		updateTicket,
		setShouldRefresh,
		shouldRefresh,
		setOpenAddTicket,

		organisationsList,
		onFetchOrganisations,
		onFetchCategories,
		categoriesList,
	} = props;
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const classes = useStyles();

	const [dialogOpen, setDialogOpen] = useState(false);

	const [orderDescending, setOrderDescending] = useState(true);
	const [order, setOrder] = useState('asc');
	const [sortingBy, setSortingBy] = useState('id');
	const [status, setStatus] = useState('');
	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldSort, setShouldSort] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);

	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);
	const [ticketData, setTicketData] = useState(null);

	const {
		data: fetchTicketsData,
		loading: fetchTicketsLoading,
		error: fetchTicketsError,
	} = fetchTickets;
	const fetchTicketsReady =
		isObject(fetchTicketsData) && !fetchTicketsLoading && !fetchTicketsError;

	const currentUserReady = isObject(userInfo);

	const {
		data: partnershipsData,
		loading: partnershipsLoading,
		error: partnershipsError,
	} = fetchPartnerships;
	const partnershipsReady =
		isObject(partnershipsData) && !partnershipsLoading && !partnershipsError;

	const serviceData =
		isObject(partnershipsData) ?
			partnershipsData.results
				.map((item) => ({
					label: item.partnerOrganisation.name,
					value: item.partnerOrganisation.id,
				}))
				.concat([
					{
						label: userInfo.organisation.label,
						value: userInfo.organisation.id,
					},
				])
		:	null;

	const {
		data: updateTicketData,
		loading: updateTicketLoading,
		error: updateTicketError,
	} = updateTicket;
	const updateTicketDone = isObject(updateTicketData) && !updateTicketLoading && !updateTicketError;

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const ticketCategoryFilter = useBasicFilter('ticketCategoryFilter');

	const ticketServiceFilter = useBasicFilter('ticketServiceFilter');

	const ticketProviderFilter = useBasicFilter('ticketProviderFilter');

	const ticketStatusFilter = useBasicFilter('ticketStatusFilter', 'none');

	const ticketTypeFilter = useBasicFilter('ticketTypeFilter', 'all');

	const ticketOrganisationFilter = useComplexFilter(
		'ticketOrganisationNameFilter',
		'ticketOrganisationIdFilter',
	);

	const pagination = usePagination(
		`pageNumber${typeOfTickets === 'closed' ? 'Closed' : ''}`,
		`pageSize${typeOfTickets === 'closed' ? 'Closed' : ''}`,
	);

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'ticketSearch');

	const filterItems = {
		...(isFullString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingBy) && { sortBy: sortingBy }),
		...(ticketStatusFilter.value === 'none' || typeOfTickets === 'closed' ?
			{ status: typeOfTickets }
		:	{ status: ticketStatusFilter.value }),
		...(isNumber(ticketServiceFilter.value) ? { serviceId: ticketServiceFilter.value } : null),
		...(isNumber(ticketProviderFilter.value) ?
			{ providerId: ticketProviderFilter.value }
		:	null),
		...(ticketTypeFilter.value !== 'all' && { type: ticketTypeFilter.value }),
		...(ticketOrganisationFilter.valueId !== 'all' && {
			organisationId: ticketOrganisationFilter.valueId,
		}),
		...(ticketCategoryFilter.value !== 'all' && { categoryId: ticketCategoryFilter.value }),
		orderDescending,
	};

	const statusFilterValue = [
		{ value: 'none', label: t('ui.filter.status.all') },
		{ value: 'open', label: t('ui.open') },
		{ value: 'pending', label: t('ui.status.pending') },
		{ value: 'planned', label: t('ui.status.planned') },
		{ value: 'ready', label: t('ui.status.ready') },
	];

	const ticketTypes = [
		{ value: 'nonCritical', label: t('views.tableResults.serviceTickets.typeNonCritical') },
		{ value: 'critical', label: t('views.tableResults.serviceTickets.typeCritical') },
		{ value: 'missing', label: t('views.tableResults.serviceTickets.typeMissing') },
	];

	const fetchData = () => {
		if (
			(!updateTicketLoading &&
				(shouldSearch || pagination.fetch || shouldSort || shouldFilter || shouldDoInitialFetch)) ||
			(updateTicketDone && !updateTicketLoading)
		) {
			if ((shouldFilter || shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			onFetchTickets(pagination.page, filterItems);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		}

		if (shouldRefresh) {
			setShouldRefresh(false);
		}
	};

	useEffect(() => {
		if (
			shouldSearch ||
			order ||
			pagination.fetch ||
			shouldFilter ||
			shouldDoInitialFetch ||
			typeOfTickets ||
			(updateTicketDone && !updateTicketLoading)
		) {
			fetchData();
		}
		if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		shouldSearch,
		order,
		pagination,
		shouldFilter,
		shouldDoInitialFetch,
		typeOfTickets,
		updateTicketDone,
		updateTicketLoading,
	]);

	useEffect(() => {
		if (!categoriesLoading && isNull(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);

	useEffect(() => {
		if (!isSuperAdmin()) {
			onFetchPartnerships(pagination.page, { status: 'accepted' });
		}
	}, []);

	useEffect(() => {
		if (shouldRefresh) {
			pagination.setPageNumber(1);
			setShouldDoInitialFetch(true);
			setShouldRefresh(false);
		}
	}, [shouldRefresh]);

	const handleRequestSort = (property) => {
		const isDesc = sortingBy === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(!orderDescending);
		setSortingBy(property);
		setShouldSort(true);
	};

	const handleChangeTicket = (ticket) => {
		setDialogOpen(true);
		setTicketData(ticket);
		setStatus(ticket.status);
	};

	const createMenuItems = (ticket) => [
		{
			icon: <ImportExportIcon />,
			text: t('view.ticketmanagement.button.inline.changestatus'),
			action: () => handleChangeTicket(ticket),
		},
	];

	const extraButtons = [
		...(!isUndefined(setOpenAddTicket) && isSuperAdmin() ?
			[
				{
					text: t('view.ticketmanagement.button.contained.addticket'),
					onClick: () => setOpenAddTicket(true),
					variant: 'contained-primary',
				},
			]
		:	[]),
	];

	const handleOrganisationFilters = (event) => {
		if (event.id === ticketOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(event.id)) {
			ticketOrganisationFilter.setValueName(event.name);
		} else if (isEmptyString(event.id)) {
			ticketOrganisationFilter.setValueName('');
		}
		ticketOrganisationFilter.setValueId(event.id);
		setShouldFilter(true);
	};

	const handleFilters = (event, filter) => {
		const value = event.target.value;
		if (value !== filter.value) {
			filter.setValue(value);
			setShouldFilter(true);
		}
	};

	const mainFilters = [
		...(isSuperAdmin() ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleOrganisationFilters,
						searchHandle: ticketOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: ticketOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: t('ui.filter.organisations.all'),
					},
				},
			]
		:	[]),
		...(serviceData && !isSuperAdmin() ?
			[
				{
					events: { onChange: (e) => handleFilters(e, ticketProviderFilter) },
					value: ticketProviderFilter.value,
					selectOptions: [].concat(
						[
							{
								value: 'all',
								label: t('ui.filter.providers.all'),
							},
						].concat(serviceData),
					),
				},
			]
		:	[]),
		...(serviceData && !isSuperAdmin() ?
			[
				{
					events: { onChange: (e) => handleFilters(e, ticketServiceFilter) },
					value: ticketServiceFilter.value,
					selectOptions: [].concat(
						[
							{
								value: 'all',
								label: t('ui.filter.serviceCompany.all'),
							},
						].concat(serviceData),
					),
				},
			]
		:	[]),
		{
			events: { onChange: (e) => handleFilters(e, ticketTypeFilter) },
			value: ticketTypeFilter.value,
			selectOptions: [{ value: 'all', label: t('ui.filter.ticketType.all') }].concat(ticketTypes),
		},
		...(typeOfTickets === 'closed' ?
			[]
		:	[
				{
					events: { onChange: (e) => handleFilters(e, ticketStatusFilter) },
					value: ticketStatusFilter.value,
					selectOptions: [].concat(statusFilterValue),
				},
			]),
		{
			events: { onChange: (e) => handleFilters(e, ticketCategoryFilter) },
			value: ticketCategoryFilter.value,
			selectOptions: [
				{ value: 'all', label: t('views.assets.items.mainFilters.category.all') },
			].concat(
				categoriesReady ?
					categoriesData.map((category) => ({
						value: category.id.toString(),
						label: category.name,
					}))
				:	[],
			),
		},
	];

	const handleClearFilters = () => {
		ticketServiceFilter.setValue('all');
		ticketProviderFilter.setValue('all');
		ticketTypeFilter.setValue('all');
		ticketStatusFilter.setValue('none');
		ticketOrganisationFilter.setValueName('');
		ticketOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
		ticketCategoryFilter.setValue('all');
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			ticketCategoryFilter.value !== 'all' ||
			ticketServiceFilter.value !== 'all' ||
			(typeOfTickets !== 'closed' && ticketStatusFilter.value !== 'none') ||
			ticketProviderFilter.value !== 'all' ||
			ticketOrganisationFilter.valueId !== 'all' ||
			ticketTypeFilter.value !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const tableHeader = [
		{ name: 'id', content: t('views.tableResults.serviceTickets.ticketId'), hasSorting: true },
		{ name: 'dateCreated', content: t('ui.dateCreated') },
		{ name: 'itemName', content: t('ui.label.itemGroup'), hasSorting: true },
		{ name: 'item', content: t('ui.label.item') },
		{ name: 'category', content: t('ui.label.category') },
		{ name: 'location', content: t('ui.label.location') },
		{ name: 'Provider', content: t('ui.provider') },
		{ name: 'serviceCompany', content: t('ui.serviceCompany.label') },
		{ name: 'defectTitle', content: t('ui.label.defect'), hasSorting: true },
		{ name: 'ticketType', content: t('ui.label.ticketType') },
		{ name: 'status', content: t('ui.label.status'), hasSorting: true },
		...(typeOfTickets === 'closed' ?
			[]
		:	[
				{
					name: 'action',
					content: '',
				},
			]),
	];

	const tableBody =
		(
			(isSuperAdmin() && fetchTicketsReady && currentUserReady) ||
			(!isSuperAdmin() &&
				partnershipsReady &&
				fetchTicketsReady &&
				currentUserReady)
		) ?
			fetchTicketsData.results.map((ticket) => [
				{
					content: (
						<Link
							className={classes.itemGroup}
							color='inherit'
							component={RouterLink}
							to={`/ticket-details/${ticket.id}`}
						>
							{ticket.id}
						</Link>
					),
				},
				{ content: commaTimeStrings(ticket.dateCreated) },
				{ content: ticket.itemInstanceReference.itemReference.name },
				{ content: ticket.itemInstanceReference.name },
				{ content: ticket.itemInstanceReference.itemReference.categoryReference.name },
				{ content: ticket.itemInstanceReference.hubReference.name },
				{ content: ticket.itemInstanceReference.hubReference.organisationReference.name },
				{ content: isObject(ticket.serviceReference) ? ticket.serviceReference.name : '-' },
				{ content: ticket.defectReference.title },
				{
					content: (
						<TicketSeverityChip status={ticket.defectReference.type} />
					),
				},

				{
					content: (
						<ServiceTicketStatusChip status={ticket.status} />
					),
				},
				...(typeOfTickets === 'closed' ?
					[]
				:	[
						{
							content: (
								<Box display='flex' flexDirection='row-reverse'>
									<GenericMoreButton menuItems={createMenuItems(ticket)} />
								</Box>
							),
						},
					]),
			])
		:	Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const ticketsEmpty =
		isObject(fetchTicketsData) && !fetchTicketsLoading && isEmptyArray(fetchTicketsData.results) ?
			showingInitialResults ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.tableResults.serviceTickets.emptyState.zeroInSystem')}
					title={t('views.tableResults.serviceTickets.emptyState.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.tableResults.serviceTickets.emptyState.filters')}
					title={t('views.tableResults.serviceTickets.emptyState.title')}
				/>
		:	null;

	return (
		<div className={classes.root}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons={isFullArray(extraButtons)}
				hasMainFilter
				mainFilters={mainFilters}
				placeholder={t('ui.search')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{ticketsEmpty ?
				ticketsEmpty
			:	<Table
					body={tableBody}
					cellStyle={classes.cellStyle}
					data={fetchTicketsData ? fetchTicketsData.results : []}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					header={tableHeader}
					loading={fetchTicketsLoading}
					order={order}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.tableResults.serviceTickets.tableTitle')}
					total={fetchTicketsData ? fetchTicketsData.total : 0}
				/>
			}

			<Dialog
				data={ticketData}
				dialogOpen={dialogOpen}
				filterItems={filterItems}
				page={pagination.page}
				setDialogOpen={setDialogOpen}
				setStatus={setStatus}
				status={status}
			/>
		</div>
	);
};

ServiceTickets.propTypes = {
	shouldRefresh: PropTypes.bool,
	typeOfTickets: PropTypes.string,

	onFetchOrganisations: PropTypes.func,
	onFetchPartnerships: PropTypes.func,
	onFetchTickets: PropTypes.func,
	onUpdateTicket: PropTypes.func,
	setOpenAddTicket: PropTypes.func,
	setShouldRefresh: PropTypes.func,
	onFetchCategories: PropTypes.func,
	fetchTickets: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	fetchPartnerships: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateTicket: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		fetchTickets: state.paged.fetchTickets,
		updateTicket: state.details.updateTicket,
		fetchPartnerships: state.paged.fetchPartnerships,
		organisationsList: state.paged.organisations,

		categoriesList: state.list.categories,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchTickets: (page, filters) => dispatch(actions.fetchTickets(page, filters)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchPartnerships: (page, filters) => dispatch(actions.fetchPartnerships(page, filters)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTickets);
