import { useEffect, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

import { ConfirmationDialog, FormDialog, ResourceNotFoundPaper } from '~components';
import { useSnackbar } from '~hooks';
import { DetailsGridLayout, usePageHeader } from '~layouts';

import AccessNfcTagsCard from '../components/cards/accessNfcTagsCard';
import AccessUsersCard from '../components/cards/accessUsersCard';
import AccessUserGroupGeneralInfoCard from '../components/cards/userGroupGeneralInfoCard';
import SkcUserGroupForm from '../forms/skcUserGroupForm/skcUserGroupForm';
import SkcUserGroupsService from '../services/skcUserGroupsService';

const service = new SkcUserGroupsService();

type UserAction = {
	type: 'delete' | 'edit';
	label?: string;
	id: string;
};

interface SkcUserGroupDetailsLayoutProps {
	id: string;
}

const SkcUserGroupDetailsLayout = ({ id }: SkcUserGroupDetailsLayoutProps) => {
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();
	const { setPageTitle, setPageButtons } = usePageHeader();

	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const { data, error } = useSWR(
		[service.basePath, id],
		([_, args]) => service.getUserGroupById(args)
	);

	const { trigger: triggerEdit, isMutating: isEditMutating } = useSWRMutation(
		[service.basePath, id],
		([_, id], { arg }) => service.updateCompleteUserGroup(id, arg, data),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('edited'));
				setUserAction(null);
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	const { trigger: triggerDelete, isMutating: isDeleteMutating } = useSWRMutation(
		[service.basePath, id],
		([_, id]) => service.deleteUserGroup(id),
		{
			onSuccess: () => {
				enqueueSuccessSnackbar(t('deleted'));
				navigate('..', { relative: 'path' });
			},
			onError: (error) => enqueueErrorSnackbar(error),
		},
	);

	useEffect(() => {
		setPageButtons([
			{
				children: t('ui.edit'),
				startIcon: <EditIcon />,
				onClick: () => setUserAction({ type: 'edit', id: id }),
			},
			{
				children: t('ui.delete'),
				color: 'error',
				startIcon: <DeleteOutlinedIcon />,
				onClick: () => setUserAction({ type: 'delete', id: id }),
			},
		]);
	}, []);

	useEffect(() => {
		if (data) {
			setPageTitle(data.label);
		}
	}, [data]);

	if (error?.status === 404) {
		return <ResourceNotFoundPaper label={id} type={t('rule')} />;
	}

	return (
		<>
			<DetailsGridLayout>
				<AccessUserGroupGeneralInfoCard id={id} />
				<AccessUsersCard id={id} />
				<AccessNfcTagsCard id={id} />
			</DetailsGridLayout>
			<FormDialog
				title={t('editResource', { resource: t('userGroup') })}
				open={userAction?.type === 'edit'}
				loading={isEditMutating}
				maxWidth='md'
				fullWidth
				onClose={() => setUserAction(null)}
			>
				<SkcUserGroupForm
					id={id}
					onSubmit={async (data) => await triggerEdit(data)}
				/>
			</FormDialog>
			<ConfirmationDialog
				subTitle={t('thisWillDeleteResourceWithName', {
					resource: t('deviceGroup'),
					name: userAction?.label ?? userAction?.id,
				})}
				open={userAction?.type === 'delete'}
				loading={isDeleteMutating}
				onClose={() => setUserAction(null)}
				onConfirm={async () => await triggerDelete()}
			/>
		</>
	);
};

export default SkcUserGroupDetailsLayout;
