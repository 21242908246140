import path from 'path';

import { IdReference, PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import {
	IdReferenceResponse,
	SkcPagedResponse,
	SkcSingleResponse,
} from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';
import { getMutationDifferences } from '~utils/arrayUtils';

import SkcDeviceGroup from '../interfaces/skcDeviceGroup';

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcDeviceGroupsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'device-groups';

	async getDeviceGroups({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<SkcDeviceGroup>> {
		const { data } = await this._client.get<SkcPagedResponse<DeviceGroupResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
				textQuery: args.searchQuery,
			},
		});

		return this.mapPagedResponse(data, SkcDeviceGroupsService.fromResponse);
	}

	async getDeviceGroupById(id: string): Promise<SkcDeviceGroup> {
		const { data } = await this._client.get<SkcSingleResponse<DeviceGroupResponse>>(
			path.join(this.path, id),
			{
				headers: {
					prefer: 'return=representation',
				},
			},
		);

		return SkcDeviceGroupsService.fromResponse(data.data);
	}

	async createDeviceGroup(data: SkcDeviceGroup): Promise<IdReference> {
		const content = SkcDeviceGroupsService.toRequest(data);

		const response = await this._client.post<SkcSingleResponse<IdReferenceResponse>>(
			this.path,
			content,
			{
				params: {
					organisationId: data.organisation.id,
				},
			},
		);

		return this.mapIdResponse(response.data);
	}

	async createCompleteDeviceGroup(data: SkcDeviceGroup): Promise<IdReference> {
		const response = await this.createDeviceGroup(data);

		if (data.devices && data.devices.length > 0) {
			await this.assignDevices(response.id, data.devices);
		}

		return response;
	}

	async assignDevices(groupId: string, devices: IdReference[]): Promise<null> {
		const { data } = await this._client.post<null>(
			path.join(this.path, groupId, 'devices'),
			devices.map((el) => el.id),
		);

		return data;
	}

	async updateDeviceGroup(groupId: string, data: SkcDeviceGroup): Promise<null> {
		const content = SkcDeviceGroupsService.toRequest(data);
		const response = await this._client.put(path.join(this.path, groupId), content);

		return null;
	}

	/**
	 * Updating the group details, assigning and deleting devices are all together
	 * three different calls. Combine it here
	 * @param groupId
	 * @param newData
	 * @param oldData
	 */
	async updateCompleteDeviceGroup(
		groupId: string,
		newData: SkcDeviceGroup,
		oldData: SkcDeviceGroup,
	): Promise<null> {
		await this.updateDeviceGroup(groupId, newData);

		const changes = getMutationDifferences(oldData.devices ?? [], newData.devices ?? []);

		if (changes.added.length > 0) {
			await this.assignDevices(groupId, changes.added);
		}
		if (changes.removed.length > 0) {
			await this.removeDevices(groupId, changes.removed);
		}

		return null;
	}

	async removeDevices(groupId: string, devices: IdReference[]): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, groupId, 'devices'), {
			data: devices.map((el) => el.id),
		});

		return data;
	}

	async deleteDeviceGroup(id: string): Promise<null> {
		const { data } = await this._client.delete(path.join(this.path, id));

		return null;
	}
	
	static toRequest(data: SkcDeviceGroup): DeviceGroupRequest {
		const { label, description } = data;

		return {
			name: label,
			// Prevent empty strings
			description: description || undefined,
		};
	}

	static fromResponse(data: DeviceGroupResponse): SkcDeviceGroup {
		const { id, name, description, devices } = data;

		return {
			id: id.toString(),
			label: name,
			description: description,
			devices: devices.map((el) => ({
				id: el.id.toString(),
				hardwareId: el.deviceHardwareId,
				type: el.deviceType,
				skopeiNumber: el.skopeiNr,
			})),
		};
	}
}

interface DeviceResponse extends IdReferenceResponse {
	deviceHardwardId: string;
	deviceType: string;
	skopeiNr: string;
}

interface DeviceGroupRequest {
	name: string;
	description?: string;
}

interface DeviceGroupResponse extends DeviceGroupRequest, IdReferenceResponse {
	devices: IdReferenceResponse[] | DeviceResponse[];
}

export default SkcDeviceGroupsService;
