import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { DeleteOutlined as DeleteOutlinedIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link as MuiLink, Portal, Stack } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
} from '@mui/x-data-grid';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem } from '~components';
import { useAuthorize } from '~features/authentication';
import { BaseReference } from '~interfaces';
import { ListMutationRefProps } from '~interfaces/refProps';

import selectedOrganisationAtom from '../../atoms/selectedOrganisationAtom';
import SkcUserGroup from '../../interfaces/skcUserGroup';
import SkcUserGroupsService from '../../services/skcUserGroupsService';

const toolbarIdString = 'topology-participant-toolbar';

const service = new SkcUserGroupsService();

interface SkcUserGroupsDataGridProps {
	onEdit?: (value: BaseReference) => void;
	onDelete?: (value: BaseReference) => void;
	onCreate?: () => void;
}

const SkcUserGroupsDataGrid = forwardRef<ListMutationRefProps, SkcUserGroupsDataGridProps>(
	({ onEdit, onDelete, onCreate }, ref) => {
		const { t } = useTranslation('general');
		const location = useLocation();

		const { isSuperAdmin } = useAuthorize();
		const [selectedOrganisation, setSelectedOrganisation] = useAtom(selectedOrganisationAtom);

		const [fetchParameters, setFetchParameters] = useState<object | null>({
			organisationId: selectedOrganisation?.id,
		});

		const columns = useMemo<GridColDef<SkcUserGroup>[]>(
			() => [
				{
					field: 'id',
					headerName: 'ID',
					width: 80,
					hideable: false,
					renderCell: (params) => (
						<MuiLink
							component={ReactRouterLink}
							to={params.value.toString()}
							state={{ from: location.pathname }}
						>
							{params.value}
						</MuiLink>
					),
				},
				{
					field: 'label',
					headerName: t('ui.label.name'),
					flex: 1,
				},
				{
					field: 'description',
					headerName: t('ui.label.description'),
					valueFormatter: (value: string) => value ?? '-',
					flex: 1,
				},
				{
					field: 'organisation',
					headerName: t('organisation'),
					valueFormatter: (value: BaseReference) => value?.label ?? '-',
					flex: 1,
				},
				{
					field: 'userCount',
					headerName: t('users'),
					valueGetter: (_, row) => row.participants.users?.length,
					type: 'number',
					width: 180,
				},
				{
					field: 'nfcTagCount',
					headerName: t('nfcTags'),
					valueGetter: (_, row) => row.participants.nfcTags?.length,
					type: 'number',
					width: 180,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-edit`}
							icon={<EditIcon />}
							label={t('ui.edit')}
							color='inherit'
							onClick={() => onEdit?.({ id: params.id.toString(), label: params.row.label })}
							showInMenu
						/>,
						<GridActionsCellItem
							key={`${params.id}-delete`}
							icon={<DeleteOutlinedIcon color='error' />}
							label={t('ui.delete')}
							onClick={() => onDelete?.({ id: params.id.toString(), label: params.row.label })}
							color='error'
							showInMenu
						/>,
					],
				},
			],
			[],
		);

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getUserGroups(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const searchQuery =
				model.quickFilterValues && model.quickFilterValues.length > 0 ?
					model.quickFilterValues[0]
				:	undefined;
			const organisation =
				model.items.length > 0 && model.items[0].field === 'organisation' ?
					model.items[0].value
				:	undefined;

			setSelectedOrganisation(organisation);

			setFetchParameters((prev) => ({
				...prev,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			}));
		};

		const getTogglableColumns = (columns: GridColDef[]): string[] => {
			const hiddenFields = isSuperAdmin() ? [] : ['organisation'];
			const visibleColumns = columns.filter(
				(column) => column.hideable && !hiddenFields.includes(column.field),
			);
			return visibleColumns.map((column) => column.field);
		};

		return (
			<Stack
				spacing={2}
				sx={{
					display: 'flex',
					height: 1,
				}}
			>
				<Box id={toolbarIdString} />
				<DataGrid
					snapshotKey='accug'
					columns={columns}
					rows={data?.results ?? []}
					rowCount={totalCount}
					loading={isLoading || isValidating}
					error={error}
					onPaginationModelChange={handlePaginationChange}
					onFilterModelChange={handleFilterModelChange}
					// columnVisibilityModel={columnVisibilityModel}
					// onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					initialState={{
						filter: {
							filterModel: {
								items:
									selectedOrganisation ?
										[
											{
												field: 'organisation',
												operator: 'contains',
												value: selectedOrganisation,
											},
										]
									:	[],
							},
						},
					}}
					slots={{
						toolbar: Toolbar,
					}}
					slotProps={{
						toolbar: {
							onCreate: onCreate,
						},
						columnsManagement: {
							getTogglableColumns,
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('userGroups') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('userGroups') }),
						},
					}}
					sx={{ flexGrow: 1, flexBasis: 200 }}
				/>
			</Stack>
		);
	},
);
SkcUserGroupsDataGrid.displayName = 'SkcUserGroupsDataGrid';

/**
 * 20241003 Only in develop this is rendered twice. So this causes a list of renders
 * when hot reloading. So this means something is wrong, but we can't really figure out
 * what or why.
 * Not sure if it because of this component or system, because the same happens with
 * drawers and dialog (the blur backgrounds are stacking)
 * 
 * @param props 
 * @returns 
 */
const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar>
				<Button variant='contained' onClick={() => props.onCreate?.()}>
					{t('addResource', { resource: t('userGroup') })}
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default SkcUserGroupsDataGrid;
