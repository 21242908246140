import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Box } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { DatePicker, FormContainer, TextField, Tip } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { DateRange } from '~interfaces/dateRanges';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './skcAccessRuleSchema.json';
import DeviceGroupAutocomplete from '../../components/autocompletes/deviceGroupsAutocomplete';
import ScheduleAutocomplete from '../../components/autocompletes/schedulesAutocomplete';
import SkcUserGroupAutocomplete from '../../components/autocompletes/skcUserGroupsAutocomplete';
import AccessRule from '../../interfaces/accessRule';
import SkcAccessRulesService from '../../services/skcAccessRulesService';

const service = new SkcAccessRulesService();

interface SkcAccessRuleFormProps {
	id?: string;
	onSubmit?: (value: AccessRule) => void;
}

const SkcAccessRuleForm = forwardRef<FormWrapperRefProps, SkcAccessRuleFormProps>(({ id, onSubmit }, ref) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const [datePickerFocus, setDatePickerFocus] = useState({});

	const { data, isLoading, isValidating, error } = useSWRImmutable(
		id ? [service.basePath, id] : null,
		([_, args]) => service.getAccessRuleById(args),
		{
			revalidateOnMount: true,
		},
	);

	const { setDisabled } = useFormContainerState();
	const { getValues, control, formState, reset, watch } = useForm<AccessRule>({
		defaultValues: useMemo(() => ({
			period: {
				start: dayjs().startOf('day').toDate()
			},
			organisation: isSuperAdmin() ? undefined : userInfo.organisation,
			...data
		}), [data]),
		mode: 'onChange',
		resolver: ajvResolver(schema),
	});

	const watchOrganisation = watch('organisation');

	useEffect(() => {
		if (data) {
			console.log(data)
			// Used to reset the useform, otherwise the page won't properly reload
			reset(data);
		}
	}, [data]);

	useImperativeHandle(
		ref,
		() => ({
			onSubmit: () => onSubmit?.(getValues()),
		}),
		[onSubmit],
	);

	useEffect(() => {
		setDisabled?.(!formState.isValid);
	}, [formState.isValid, setDisabled]);

	const handleDateChange = (newValue, onChange: (period: Partial<DateRange>) => void) => {
		newValue.startDate?.set('hour', 0);
		newValue.startDate?.set('minute', 0);
		newValue.startDate?.set('second', 0);
		newValue.endDate?.set('hour', 23);
		newValue.endDate?.set('minute', 59);
		newValue.endDate?.set('second', 59);

		onChange({
			start: newValue.startDate?.toDate(),
			end: newValue.endDate?.toDate(),
		});
	};

	return (
		<FormContainer loading={isLoading}>
			{isSuperAdmin() && (
				<Controller
					name='organisation'
					control={control}
					render={({ field }) => (
						<OrganisationAutocomplete
							value={field.value}
							onChange={(_, newValue) => field.onChange(newValue)}
							required
						/>
					)}
				/>
			)}
			<Controller
				name='label'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.name')}
						required
						slotProps={{
							htmlInput: {
								minLength: schema.properties.label.minLength,
								maxLength: schema.properties.label.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='description'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.description')}
						slotProps={{
							htmlInput: {
								maxLength: schema.properties.description.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='period'
				control={control}
				render={({ field }) => (
					<Box
						sx={{
							width: 'fit-content',
							overflow: 'visible',
							zIndex: 10000,
						}}
					>
						<DatePicker
							focused={datePickerFocus?.focusedInput}
							handleDatesChange={(val) => handleDateChange(val, field.onChange)}
							handleFocusChange={(focusedInput) => setDatePickerFocus({ focusedInput })}
							start={field.value?.start != null ? moment(field.value?.start) : null}
							end={field.value?.end != null ? moment(field.value?.end) : null}
							minDate={moment().subtract(1, 'days')}
						/>
					</Box>
				)}
			/>
			<Tip message={t('addAccessRuleTipMessage')} />
			<Controller
				name='deviceGroup'
				control={control}
				render={({ field }) => (
					<DeviceGroupAutocomplete
						value={field.value}
						enableInfiniteScroll
						disabled={!watchOrganisation}
						// organisation={watchOrganisation}
						onChange={(_, newVal) => field.onChange(newVal)}
					/>
				)}
			/>
			<Controller
				name='userGroup'
				control={control}
				render={({ field }) => (
					<SkcUserGroupAutocomplete
						value={field.value}
						enableInfiniteScroll
						disabled={!watchOrganisation}
						// organisation={watchOrganisation}
						onChange={(_, newVal) => field.onChange(newVal)}
					/>
				)}
			/>
			<Controller
				name='schedule'
				control={control}
				render={({ field }) => (
					<ScheduleAutocomplete
						value={field.value}
						enableInfiniteScroll
						disabled={!watchOrganisation}
						// organisation={watchOrganisation}
						onChange={(_, newVal) => field.onChange(newVal)}
					/>
				)}
			/>
		</FormContainer>
	);
});
SkcAccessRuleForm.displayName = 'SkcAccessRuleForm';

export default SkcAccessRuleForm;
