import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Divider, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom';

import pageButtonsAtom from '../atoms/pageButtonsAtom';
import PageNavBar from '../navBar/pageNavBar';
import { useTranslation } from 'react-i18next';
import pageTitleAtom from '../atoms/pageTitleAtom';

interface Page {
	label: string;
	path: string;
}

interface PageHeaderProps {
	title: string;
	pages?: Page[];
}

const PageHeader = ({ pages, title }: PageHeaderProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation('general');

	const pageTitle = useAtomValue(pageTitleAtom);
	const pageButtons = useAtomValue(pageButtonsAtom);

	const pathDepth = location.pathname.split('/').length - 1;

	const handleGoBackClick = () => {
		if (location.state?.from != null) {
			navigate(location.state?.from);
		} else {
			navigate('..', { relative: 'path' });
		}
	};

	return (
		<Stack width={1} spacing={1} alignItems='flex-start'>
			{(pathDepth > 2 || location.state?.from != null) && (
				<Button startIcon={<KeyboardArrowLeftIcon />} onClick={handleGoBackClick}>
					{location.state?.label ?? t('ui.button.contained.back')}
				</Button>
			)}
			<Stack
				width={1}
				spacing={1}
				direction='row'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='h3' py={1}>
					{pageTitle || title}
				</Typography>

				<Stack direction='row' gap={1}>
					{pageButtons.length > 0 && (
						<ButtonGroup
							variant='text'
							sx={{
								'.MuiButtonGroup-grouped:not(:last-of-type)': {
									borderColor: 'transparent',
								},
							}}
						>
							{pageButtons.map((el, i) => (
								<Button key={i} {...el} />
							))}
						</ButtonGroup>
					)}
				</Stack>
			</Stack>
			<Box width={1}>
				{pages != null && pages.length > 0 && <PageNavBar pages={pages} />}
				<Divider orientation='horizontal' sx={{ m: 0, width: 1 }} />
			</Box>
		</Stack>
	);
};

export type { Page };

export default PageHeader;
