import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Stack, TextField } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { userInfoAtom } from '~atoms';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { NfcAutocomplete, NfcTag } from '~features/nfc';
import { Organisation, OrganisationAutocomplete } from '~features/organisations';
import { BaseReference } from '~interfaces';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './createAnonymousUserSchema.json';

interface CreateAnonymousFormData {
	organisation: Organisation;
	firstName: string;
	lastName: string;
	nfcTag: NfcTag;
}

interface CreateAnonymousFormProps {
	onSubmit?: (value: CreateAnonymousFormData) => void;
}

const CreateAnonymousForm = forwardRef<FormWrapperRefProps, CreateAnonymousFormProps>(({ onSubmit }, ref) => {
	const { t } = useTranslation('general');
	const userInfo = useAtomValue(userInfoAtom);
	const { isSuperAdmin } = useAuthorize();

	const { setDisabled } = useFormContainerState();
	const { getValues, control, formState, watch, setValue } = useForm<
		Partial<CreateAnonymousFormData>
	>({
		defaultValues:
			isSuperAdmin() ?
				{}
			:	{
					organisation: userInfo.organisation,
				},
		mode: 'onChange',
		resolver: ajvResolver(schema),
	});

	const watchOrganisation = watch('organisation');

	useImperativeHandle(
		ref,
		() => ({
			onSubmit: () => onSubmit?.(getValues()),
		}),
		[onSubmit],
	);

	useEffect(() => {
		setDisabled?.(!formState.isValid);
	}, [formState.isValid, setDisabled]);

	const handleOrganisationChange = (
		newOrganisation: BaseReference | undefined,
		onChange: () => void,
	) => {
		setValue('nfcTag', undefined);
		onChange(newOrganisation);
	};

	const handleNfcTagChange = (newNfcTag: NfcTag | undefined, onChange: () => void) => {
		setValue('organisation', newNfcTag?.organisation);
		onChange(newNfcTag);
	};

	return (
		<Stack
			direction='column'
			spacing={2}
			sx={{
				my: 1,
			}}
		>
			{isSuperAdmin() && (
				<Controller
					name='organisation'
					control={control}
					render={({ field }) => (
						<OrganisationAutocomplete
							{...field}
							required
							label={t('organisation')}
							// onChange={(_, newValue) => field.onChange(newValue)}
							onChange={(_, newValue) => handleOrganisationChange(newValue, field.onChange)}
						/>
					)}
				/>
			)}
			<Controller
				name='nfcTag'
				control={control}
				render={({ field }) => (
					<NfcAutocomplete
						{...field}
						required
						label={t('ui.label.anonymousUserNFCTag')}
						organisation={watchOrganisation}
						onChange={(_, newValue) => handleNfcTagChange(newValue, field.onChange)}
						linkedFilter={false}
					/>
				)}
			/>
			<Controller
				name='firstName'
				control={control}
				render={({ field }) => <TextField {...field} required label={t('ui.label.firstName')} />}
			/>
			<Controller
				name='lastName'
				control={control}
				render={({ field }) => <TextField {...field} required label={t('ui.label.lastName')} />}
			/>
		</Stack>
	);
});
CreateAnonymousForm.displayName = 'CreateAnonymousForm';

export default CreateAnonymousForm;
