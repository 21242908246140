import { useState, useEffect } from 'react';

import { InsertDriveFileOutlined as InsertDriveFileOutlinedIcon } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { Avatar, Box, Link, Typography } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import {
	Dialog,
	FormDialog
} from '~components';
import { NfcTagsService } from '~features/nfc';
import CreateAnonymousForm from '~features/users/forms/createAnonymousUser/createAnonymousUserForm';

import AddNFCTag from './AddNFCTag/AddNFCTag';
import { useStyles, MyTooltip } from './style';
import WarningIcon from '../../../assets/icons/label-filled-icon-warning@3x.png';
import {
	SearchBar,
	EmptyState,
	GenericMoreButton,
	Table,
	AlertDialog,
	OpenEmail,
	IconInfoLabel,
} from '../../../components';
import { exportType } from '../../../constantsOld';
import { useExport } from '../../../shared/downloadHooks';
import {
	useError,
	useSearchComponent,
	useComplexFilter,
	usePagination,
} from '../../../shared/hooks';
import {
	isEmptyString,
	isEmptyArray,
	isObject,
	getInitials,
	isFullString,
	isSuperAdmin,
	isInteger,
	isFullArray,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';
import CustomerEdit from '../Details/UserDetails/Summary/CustomerInformation/CustomerEdit';
import UserIdentitiesService from '~features/users/services/userIdentitiesService';

const userIdentitiesService = new UserIdentitiesService();
const nfcTagsService = new NfcTagsService();

const UsersList = (props) => {
	const {
		className,
		usersList,
		onFetchUsers,
		onDisableUser,
		disableUser,
		updateUser,
		removeUserFromOrganisation,
		onRemoveUserFromOrganisation,
		organisationsList,
		onFetchOrganisations,

		onResetStateCondition,
		onFetchUsersUserRole,
		roles,
		onFetchUsersExport,
		exportUsersDetails,
		currentUser,
	} = props;
	const { t } = useTranslation('general');
	const navigate = useNavigate();
	const location = useLocation();
	const auth = useAuth();

	const { enqueueSnackbar } = useSnackbar();
	const classes = useStyles();

	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(true);
	const [shouldSort, setShouldSort] = useState(false);

	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldSearch, setShouldSearch] = useState(false);
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);

	const { data: usersData, loading: usersLoading, error: usersError } = usersList;
	const usersReady = isObject(usersData) && !usersLoading && !usersError;

	const { success: disableSuccess, loading: disableLoading, error: disableError } = disableUser;
	const disableUserDone = disableSuccess && !disableLoading && !disableError;

	const {
		success: removeSuccess,
		loading: removeLoading,
		error: removeError,
	} = removeUserFromOrganisation;
	const removeUserDone = removeSuccess && !removeLoading && !removeError;

	const { data: currentUserRoleData, loading: currentUserRoleLoading } = roles;
	const currentRoleReady = isFullString(currentUserRoleData) && !currentUserRoleLoading;

	const { data: updateUserData, loading: updateUserLoading } = updateUser;

	const [userName, setUserName] = useState(null);

	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [openDialogAnonymousUser, setOpenDialogAnonymousUser] = useState(false);
	const [openDialogDisable, setOpenDialogDisable] = useState(false);
	const [userId, setUserId] = useState(null);
	const [userData, setUserData] = useState(null);

	const [openDialogRemove, setOpenDialogRemove] = useState(false);

	const [addNFCTag, setAddNFCTag] = useState(false);

	const pagination = usePagination('pageNumberUsers', 'pageSizeUsers');

	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'searchUser');

	const userOrganisationFilter = useComplexFilter(
		'userOrganisationNameFilter',
		'userOrganisationIdFilter',
	);

	const userfilters = {
		...(isFullString(search.value) && { searchTerm: encodeURIComponent(search.value) }),
		...(userOrganisationFilter.valueId !== 'all' && {
			organisationId: userOrganisationFilter.valueId,
		}),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		orderDescending,
	};

	const removeUserMessage = useError({
		value: removeUserFromOrganisation,
		message: `${t('ui.successfully')} ${t('ui.removed')} ${isFullString(userName) && userName}`,
	});

	const disableUserMessage = useError({
		value: disableUser,
		message: `${t('ui.successfullyDisabled')} ${isFullString(userName) && userName}`,
	});

	useEffect(() => {
		if (
			!usersLoading &&
			(pagination.fetch ||
				shouldSearch ||
				shouldSort ||
				shouldFilter ||
				removeSuccess ||
				disableSuccess ||
				updateUserData ||
				shouldDoInitialFetch)
		) {
			onFetchUsers(pagination.page, userfilters);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [
		pagination.fetch,
		shouldSort,
		shouldFilter,
		shouldSearch,
		updateUserLoading,
		removeSuccess,
		disableSuccess,
		updateUserData,
	]);

	useEffect(() => {
		if (!currentUserRoleLoading && userId) {
			onFetchUsersUserRole(userId);
		}
	}, [userId]);

	useEffect(() => {
		if (!addNFCTag) {
			onResetStateCondition('createAnonymousUser', false);
		}
	}, [addNFCTag]);

	/* * * * * *
	 * FILTER  *
	 * * * * * */
	const handleChangeOrganisationFilterSelect = (item) => {
		if (item.id === userOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			userOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			userOrganisationFilter.setValueName('');
		}
		userOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilterSelect,
						searchHandle: userOrganisationFilter.setValueName,
					},
					value: userOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: {
						id: '',
						name: 'All organisations',
					},
				},
			]
		:	[]),
	];

	const toggleAddNFCTagDialog = () => {
		setAddNFCTag(!addNFCTag);
	};

	const exportUsers = useExport({
		label: 'User details',
		exportData: exportUsersDetails,
	});

	const handleExportUsers = () => {
		onFetchUsersExport(currentUser.data.organisationReference.id);
		exportUsers.setDownload(true);
		exportUsers.setExportType(exportType.CSV);
	};

	const extraButtons = [
		{
			variant: 'contained-tertiary',
			onClick: handleExportUsers,
			text: t('views.userDetails.summary.exportUsers'),
			icon: <InsertDriveFileOutlinedIcon />,
		},
		{
			variant: 'contained-tertiary',
			onClick: () => setOpenDialogAnonymousUser(true),
			text: t('addAnonymousUser'),
		},
		{
			variant: 'contained-primary',
			onClick: () => navigate('/user-management/users/invite'),
			text: t('ui.button.contained.inviteusers'),
		},
	];

	/* * * * * *
	 * SORTING *
	 * * * * * */
	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	/* * * * * * * * *
	 * TABLE ACTIONS *
	 * * * * * * * * */
	const disableSingleUser = (data) => {
		setUserName(`${data.firstName} ${data.lastName}`);
		setUserId(data.id);
		setOpenDialogDisable(true);
	};

	const removeSingleUser = (data) => {
		setUserName(`${data.firstName} ${data.lastName}`);
		setUserId(data.id);
		setOpenDialogRemove(true);
	};

	const [openEdit, setOpenEdit] = useState(false);

	const handleEditClose = () => {
		setOpenEdit(false);
	};

	const editSingleUser = (data) => {
		setOpenEdit(true);
		setUserData(data);
	};

	/* * * * * * * * *
	 * TABLE CONTENT *
	 * * * * * * * * */
	const createMenuItems = (data) => [
		{
			icon: <EditIcon />,
			text: t('ui.button.inline.edit'),
			action: () => editSingleUser(data),
		},
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					icon: <BlockIcon />,
					text: t('ui.button.inline.deactivate'),
					action: () => disableSingleUser(data),
					isRed: true,
				},
			]
		:	[
				{
					icon: <span className='material-icons'>person_remove</span>,
					text: t('ui.button.inline.removefromorganisation'),
					action: () => removeSingleUser(data),
					isRed: true,
				},
			]),
	];

	const editActionButtons = [
		{
			icon: <GroupAddOutlinedIcon className={classes.buttonIcon} />,
			text: t('ui.button.inline.assigntousergroup'),
			action: (selectedUsers) =>
				navigate('/user-management/users/assign/user-group', {
					state: { selectedUsers },
				}),
			variant: 'inline-default',
		},
	];

	const tableHeader = [
		{ name: 'fullName', content: t('ui.label.name'), hasSorting: true },
		{ name: 'id', content: t('ui.label.id') },
		{ name: 'email', content: t('ui.label.email'), hasSorting: true },
		...(isSuperAdmin(auth.user?.profile.role) ?
			[{ name: 'organisation', content: t('ui.label.organisation'), hasSorting: true }]
		:	[]),
		{ name: 'nfcId', content: t('rfidUid'), hasSorting: false },
		{ name: 'userGroup', content: t('ui.label.userGroup'), hasSorting: true },
		// { name: 'licenseStatus',  content: t('ui.label.licenseStatus') },
		{ name: 'more', content: '' },
	];

	const handleLinks = (path, label, returnButton = 'ui.category.users') => (
		<Link
			className={classes.itemGroup}
			color='primary'
			component={RouterLink}
			to={path}
			state={{ from: location.pathname, label: t(returnButton) }}
		>
			{label}
		</Link>
	);

	const handleNFCCardsDisplay = ([...nfcCards]) => {
		if (nfcCards.length === 1) {
			const [{ nfcId }] = nfcCards;
			return nfcId;
		}

		return (
			<Box display='flex'>
				<MyTooltip
					arrow
					placement='top'
					title={nfcCards.map((nfcCard) => (
						<Box key={nfcCard.nfcId} pb={1} pt={1}>
							<Typography className={classes.tooltipText}>{nfcCard.nfcId}</Typography>
						</Box>
					))}
				>
					<Typography className={classes.outerTooltipText}>
						{`${nfcCards.length} ${t('ui.label.nfcTags')}`}
					</Typography>
				</MyTooltip>
			</Box>
		);
	};

	const tableBody =
		!usersLoading && isObject(usersData) ?
			usersData.results.map((user) => [
				{
					content: (
						<div className={classes.nameCell}>
							{user.imagesReference && user.imagesReference[0] ?
								<Avatar alt='User' className={classes.avatar} src={user.imagesReference[0]} />
							:	<Avatar alt='User' className={classes.avatar}>
									{getInitials(`${user.firstName} ${user.lastName}`)}
								</Avatar>
							}
							<div>
								{handleLinks(
									`/user-management/users/${user.id}/summary`,
									user.firstName + ' ' + user.lastName,
								)}
							</div>
						</div>
					),
				},
				{ content: handleLinks(`/user-management/users/${user.id}/summary`, user.id) },
				{
					content: (
						<IconInfoLabel
							content={<OpenEmail email={user.emailAddress} />}
							icon={
								<img
									alt='warning icon'
									className={classes.icon}
									height='16'
									src={WarningIcon}
									width='16'
								/>
							}
							infoTitle={t('ui.emailNotVerified')}
							showIcon={!user.isEmailConfirmed}
						/>
					),
				},
				//user organisation
				...(isSuperAdmin(auth.user?.profile.role) ?
					[
						{
							content: handleLinks(
								`/organisations/${user.organisationReference.id}/summary`,
								user.organisationReference.name,
							),
						},
					]
				:	[]),
				{ content: isFullArray(user.cards) ? handleNFCCardsDisplay(user.cards) : '-' },
				{ content: user?.userGroupReference && user.userGroupReference.name },
				{ content: <GenericMoreButton menuItems={createMenuItems(user)} /> },
			])
		:	Array(4)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => arr.map(() => ({ loading: true })));

	const handleCancelDialogDisable = () => {
		setOpenDialogDisable(false);
	};

	const handleConfirmDialogDisable = () => {
		if (userId) {
			onDisableUser(userId);
			disableUserMessage.setStartAction(true);
		}
	};

	const handleCancelDialogRemove = () => {
		setOpenDialogRemove(false);
	};

	const handleConfirmRemove = () => {
		if (userId) {
			onRemoveUserFromOrganisation(userId);
			removeUserMessage.setStartAction(true);
		}
	};

	const handleClearFilters = () => {
		userOrganisationFilter.setValueName('');
		userOrganisationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const handleCreateAnonymousUserAndLink = async (data) => {
		setUserActionProcessing(true);

		try {
			const response = await userIdentitiesService.createAnonymousUser(data);
			await nfcTagsService.updateNfcTag(data.nfcTag.id, {
				...data.nfcTag,
				user: { id: response.id }
			});
			enqueueSnackbar('Succesful created', { variant: 'success' });
			setOpenDialogAnonymousUser(false);
			setShouldSearch(true);
		} catch (error) {
			console.error(error);
			enqueueSnackbar('somethingwrong', { variant: 'error' });
		} finally {
			setUserActionProcessing(false);
		}
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			userOrganisationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const usersListEmpty =
		usersReady && isEmptyArray(usersData.results) ?
			isEmptyString(search.value) ?
				<EmptyState
					image={'booking'}
					subTitle={t('views.usersAndOrganisations.users.empty.description.zeroInSystem')}
					title={t('views.usersAndOrganisations.users.empty.title')}
				/>
			:	<EmptyState
					image={'booking'}
					subTitle={t('views.usersAndOrganisations.users.empty.description.zeroMatching')}
					title={t('views.usersAndOrganisations.users.empty.title')}
				/>
		:	null;

	return (
		<div className={clsx(classes.root, className)}>
			<SearchBar
				clearFilters={clearFilters}
				extraButtons={extraButtons}
				hasExtraButtons
				hasMainFilter={true}
				mainFilters={mainFilters}
				placeholder={t('views.usersAndOrganisations.users.searchPlaceholder')}
				searchEvents={search.events}
				searchValue={search.value}
			/>
			{usersListEmpty ?
				usersListEmpty
			:	<Table
					body={tableBody}
					cellStyle={classes.cellStyle}
					data={usersData ? usersData.results : []}
					editActionButtons={editActionButtons}
					handlePageChange={pagination.pageNumberChange}
					handleSorting={handleRequestSort}
					hasSelectionEnabled={true}
					header={tableHeader}
					loading={usersLoading}
					order={order}
					orderBy={sortingCategory}
					page={pagination.pageNumber}
					rowsPerPage={pagination.pageSize}
					setRowsPerPage={pagination.pageSizeChange}
					title={t('views.usersAndOrganisations.users.table.title')}
					total={usersData ? usersData.total : 0}
				/>
			}
			{openDialogDisable ?
				<AlertDialog
					dialogDescription={`${t('ui.disable.dialogDescription')} ${userName}`}
					dialogTitle={t('ui.disable.uppercase')}
					handleClose={handleCancelDialogDisable}
					handleConfirm={handleConfirmDialogDisable}
					loading={disableLoading && !disableUserDone}
					open={openDialogDisable}
				/>
			:	null}
			{openDialogRemove ?
				<AlertDialog
					dialogDescription={`${userName} ${t('views.userDetails.summary.actions.description')}`}
					dialogTitle={t('ui.remove')}
					furtherLoading={currentRoleReady}
					handleClose={handleCancelDialogRemove}
					handleConfirm={handleConfirmRemove}
					loading={removeLoading && !removeUserDone}
					loadingState={true}
					open={openDialogRemove}
					textDialog={{
						title: t('views.userDetails.summary.actions.isTextDialog.title'),
						description: t('views.userDetails.summary.actions.isTextDialog.description'),
						notAllowed: currentUserRoleData !== 'user',
					}}
				/>
			:	null}
			{isObject(userData) ?
				<CustomerEdit customer={userData} onClose={handleEditClose} open={openEdit} />
			:	null}
			<AddNFCTag isOpen={addNFCTag} toggleAddNFCTagDialog={toggleAddNFCTagDialog} />
			<FormDialog
				title={t('addAnonymousUser')}
				open={openDialogAnonymousUser}
				onClose={() => setOpenDialogAnonymousUser(false)}
				maxWidth='sm'
				fullWidth
				loading={userActionProcessing}
				variant='legacy'
				saveLabel={t('ui.add')}
			>
				<CreateAnonymousForm
					onSubmit={handleCreateAnonymousUserAndLink}
				/>
			</FormDialog>
		</div>
	);
};

UsersList.propTypes = {
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	className: PropTypes.string,
	usersList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	disableUser: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	userObject: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	filters: PropTypes.object,

	//functions
	onFetchUsers: PropTypes.func,
	onDisableUser: PropTypes.func,
	onRemoveUserFromOrganisation: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	removeUserFromOrganisation: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchUsersExport: PropTypes.func,
	exportUsersDetails: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchUsersUserRole: PropTypes.func,
	roles: PropTypes.shape({
		data: PropTypes.string,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	onFetchOrganisations: PropTypes.func,
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		currentUser: state.details.currentUser,
		usersList: state.paged.users,
		disableUser: state.condition.disableUser,
		updateUser: state.details.updateUser,
		userObject: state.details.user,
		removeUserFromOrganisation: state.condition.removeUserFromOrganisation,
		organisationsList: state.paged.organisations,

		roles: state.details.userRole,
		exportUsersDetails: state.details.exportUsers,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUsers: (page, filters) => dispatch(actions.fetchUsers(page, filters)),
		onDisableUser: (id) => dispatch(actions.disableUser(id)),
		onRemoveUserFromOrganisation: (id) => dispatch(actions.removeUserFromOrganisation(id)),
		onFetchUsersExport: (organisationId) => dispatch(actions.exportUsers(organisationId)),
		onFetchUsersUserRole: (id) => dispatch(actions.fetchUsersUserRole(id)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onResetStateCondition: (identifier, value) =>
			dispatch(actions.resetStateCondition(identifier, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
