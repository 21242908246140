import { AxiosError } from 'axios';
import { useSnackbar as useNotistackSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const largerThanAcceptation = import.meta.env.VITE_APP_ENV === 'acceptation' || import.meta.env.VITE_APP_ENV === 'production';

/**
 * A snackbar wrapper to handle common use case, like a rest call that
 * went ok or error handling.
 */
const useSnackbar = () => {
	const snackbar = useNotistackSnackbar();
	const { t } = useTranslation('general');

	const enqueueSuccessSnackbar = (message: string) => {
		snackbar.enqueueSnackbar(message, { variant: 'success'});
	};

	const enqueueErrorSnackbar = (error: AxiosError, message?: string) => {
		console.error(error);

		let errorMessageKey;

		if (message) {
			errorMessageKey = message;
		} else {
			switch(error?.status) {
				case 401:
					errorMessageKey = 'unauthorized';
					break;
				case 403:
					errorMessageKey = 'forbidden';
					break;
				case 404:
					errorMessageKey = 'notFound';
					break;
				case 409:
					errorMessageKey = 'conflict';
					break;
				case 502:
					errorMessageKey = largerThanAcceptation ? 'somethingWentWrong' : 'somethingWentWrongOutsideTopology';
					break;
				default:
					errorMessageKey = 'somethingWentWrong';
					break;
			}
		}

		snackbar.enqueueSnackbar(t(errorMessageKey), { variant: 'error' });
	};

	return {
		...snackbar,
		enqueueSuccessSnackbar: enqueueSuccessSnackbar,
		enqueueErrorSnackbar: enqueueErrorSnackbar
	};
};

export default useSnackbar;
