import path from 'path';

import { ItemCategoryEnum } from '~enums';
import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import ItemGroup from '../interfaces/itemGroup';

class ItemGroupsService extends TopologyService {
	/**
	 * Notice!! To prevent confusement. In this application (the admin panel)
	 * we the following terminilogy
	 * - Item = Instance aka ItemInstance
	 * - ItemGroup = Group of items (in other aspects of Topology may be referred as item)
	 * So the backend uses items for item groups
	 */
	public readonly path = 'items';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of item groups
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getItemGroups({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters & {
		categoryId?: string;
	}): Promise<PagedResults<ItemGroup>> {
		const { data } = await this._client.get<PagedResponse<ItemGroupResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				categoryId: args.categoryId,
				searchTerm: args.searchQuery,
			},
		});

		return this.mapPagedResponse(data, ItemGroupsService.fromResponse);
	}

	/**
	 * Get details of a single item group
	 * @param id The identifyer of the item group
	 * @returns
	 */
	async getItemGroupById(id: string): Promise<ItemGroup> {
		const { data } = await this._client.get<ItemGroupResponse>(path.join(this.path, id));

		return ItemGroupsService.fromResponse(data);
	}

	/**
	 * Delete an item group by id
	 * @param id The identifyer of the item group
	 * @returns
	 */
	async deleteItemGroup(id: string): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, id));

		return data;
	}

	static fromResponse(data: ItemGroupResponse): ItemGroup {
		const {
			id,
			name,
			categoryReference,
			hubReference,
			totalItemInstances,
			imagesReference,
			...rest
		} = data;

		return {
			...ItemGroupsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			hub: ItemGroupsService.fromBaseReferenceResponse(hubReference),
			category: categoryReference.id as unknown as ItemCategoryEnum,
			itemCount: totalItemInstances,
			imageSrc: imagesReference != null ? imagesReference[0] : undefined,
		};
	}
}

interface ItemGroupResponse extends BaseReferenceResponse {
	categoryReference: BaseReferenceResponse;
	totalItemInstances: number;
	description?: string;
	imagesReference?: string[];
	hubReference: {
		latitude: number;
		longitude: number;
		organisationReference: BaseReferenceResponse;
	} & BaseReferenceResponse;
}

// Not used, but half defined what could be in the response.
// Based on /api/v1/items/:itemId
interface ItemGroupSingleResponse extends BaseReferenceResponse {
	description?: string;
	totalItemInstances: number;
	termsReference: BaseReferenceResponse;
	pricingModelReference: BaseReferenceResponse;
	businessPricingModelReference: BaseReferenceResponse;
	isPublished: boolean;
	// characteristics: todo;
	// policies: todo;
	bookingTypes: string[];
	categoryReference: {
		type: string;
	} & BaseReferenceResponse;
	hubReference: {
		latitude: number;
		longitude: number;
		organisationReference: BaseReferenceResponse;
		// address: todo;
		// openHours: todo;
	} & BaseReferenceResponse;
	imagesReference: string[];
}

export default ItemGroupsService;
