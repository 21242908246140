import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import path from 'path';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Stack, Switch, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { userInfoAtom } from '~atoms';
import { AsyncTextField, FormContainer, Select, TextField, TransferList } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { useAuthorize } from '~features/authentication';
import { NfcTag, SkcNfcTagsService } from '~features/nfc';
import { OrganisationAutocomplete } from '~features/organisations';
import { User, UsersService } from '~features/users';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './skcUserGroupSchema.json';
import SkcUserGroup from '../../interfaces/skcUserGroup';
import SkcUserGroupsService from '../../services/skcUserGroupsService';

enum ParticipantTypeEnum {
	NfcTag = 'nfcTag',
	User = 'user',
}

const userGroupsService = new SkcUserGroupsService();
const nfcTagsService = new SkcNfcTagsService();
const usersService = new UsersService();

interface SkcUserGroupFormProps {
	id?: string;
	onSubmit?: (value: SkcUserGroup) => void;
}

const SkcUserGroupForm = forwardRef<FormWrapperRefProps, SkcUserGroupFormProps>(({ id, onSubmit }, ref) => {
	const { t } = useTranslation('general');

	const { isSuperAdmin } = useAuthorize();
	const userInfo = useAtomValue(userInfoAtom);

	const [selectionType, setSelectionType] = useState<ParticipantTypeEnum>(
		ParticipantTypeEnum.NfcTag,
	);

	const [rightPage, setRightPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const [totalLeftCount, setTotalLeftCount] = useState<number>(0);
	const [availableLeftList, setAvailableLeftList] = useState([]);
	const [leftPagination, setLeftPagination] = useState({
		page: 1,
		pageSize: rowsPerPage,
	});

	const [searchQuery, setSearchQuery] = useState<string>('');

	const {
		data: userGroupData,
		isLoading: isUserGroupLoading,
		error: userGroupError,
	} = useSWRImmutable(
		id ? [userGroupsService.basePath, id] : null,
		([_, args]) => userGroupsService.getUserGroupById(args),
		{
			revalidateOnMount: true,
		},
	);

	const { setDisabled } = useFormContainerState();
	const { getValues, control, formState, reset, watch } = useForm({
		defaultValues: useMemo(
			() => userGroupData ?? { organisation: isSuperAdmin() ? undefined : userInfo.organisation },
			[userGroupData],
		),
		mode: 'onChange',
		resolver: ajvResolver(schema),
	});

	const watchOrganisation = watch('organisation');

	const nfcTagsFetchParameters = useMemo(
		() => ({
			...leftPagination,
			organisationId: watchOrganisation?.id,
			searchQuery: searchQuery,
		}),
		[leftPagination, watchOrganisation, searchQuery],
	);

	const usersFetchParameters = useMemo(
		() => ({
			...leftPagination,
			organisationId: watchOrganisation?.id,
			searchQuery: searchQuery,
			excludeAnonymous: true,
		}),
		[leftPagination, watchOrganisation, searchQuery],
	);

	const {
		data: nfcTagsData,
		isLoading: isNfcTagsLoading,
		isValidating: isNfcTagsValidating,
		error: nfcTagsError,
	} = useSWR(
		selectionType === ParticipantTypeEnum.NfcTag && [
			nfcTagsService.basePath,
			nfcTagsFetchParameters,
		],
		([_, args]) => nfcTagsService.getNfcTags(args),
		{
			onSuccess: (res) => setTotalLeftCount(res.total),
		},
	);

	const {
		data: usersData,
		isLoading: isUsersLoading,
		isValidating: isUsersValidating,
		error: usersError,
	} = useSWR(
		selectionType === ParticipantTypeEnum.User && [usersService.basePath, usersFetchParameters],
		([_, args]) => usersService.getUsers(args),
		{
			onSuccess: (res) => setTotalLeftCount(res.total),
		},
	);

	const leftData = nfcTagsData || usersData;
	const isLeftLoading = isNfcTagsLoading || isUsersLoading;
	const isLeftValidating = isNfcTagsValidating || isUsersLoading;
	const leftError = nfcTagsError || usersError;

	useImperativeHandle(
		ref,
		() => ({
			onSubmit: () => onSubmit?.(getValues()),
		}),
		[onSubmit],
	);

	useEffect(() => {
		if (userGroupData) {
			// Used to reset the useform, otherwise the page won't properly reload
			reset(userGroupData);
		}
	}, [userGroupData]);

	useEffect(() => {
		if (!leftData) {
			setAvailableLeftList([]);
			return;
		}
	}, [leftData]);

	useEffect(() => {
		setDisabled?.(!formState.isValid);
	}, [formState.isValid, setDisabled]);

	useEffect(() => {
		if (!leftData) {
			setAvailableLeftList([]);
			return;
		}

		determineAvailableLeftItems();
	}, [leftData]);

	const handleRowsPerPageChange = (val) => {
		setRowsPerPage(val);
		setLeftPagination((prev) => ({ ...prev, pageSize: val }));
	};

	const mapNfcTagToTransferListItem = (element: NfcTag) => ({
		key: element.id,
		primary: element.label,
		secondary: `${element.id} - ${element.tagNumber}`,
		target: {
			type: ParticipantTypeEnum.NfcTag,
			value: element,
		},
	});

	const mapUserToTransferListItem = (element: User) => ({
		key: element.id,
		primary: element.label,
		secondary: element.email,
		target: {
			type: ParticipantTypeEnum.User,
			value: element,
		},
	});

	const handleTransferListChange = (val, onChange: () => void) => {
		// TODO: should look at this. Could be no change and still trigger an
		// onChange
		const uniqueUsers: User[] = [
			...new Map(
				val.rightItems
					.filter((el) => el.target.type === ParticipantTypeEnum.User)
					.map((el) => [el?.key, el.target.value]),
			).values(),
		];
		const uniqueNfcTags: NfcTag[] = [
			...new Map(
				val.rightItems
					.filter((el) => el.target.type === ParticipantTypeEnum.NfcTag)
					.map((el) => [el?.key, el.target.value]),
			).values(),
		];

		const newValue = { nfcTags: uniqueNfcTags, users: uniqueUsers };
		onChange(newValue);

		determineAvailableLeftItems();
	};

	const determineAvailableLeftItems = () => {
		const participants = getValues().participants;
		const chosenArray =
			(selectionType === ParticipantTypeEnum.NfcTag ?
				participants?.nfcTags
			:	participants?.users
			)?.map((el) => el.id) ?? [];
		if (nfcTagsData) {
			const mapped = nfcTagsData.results.map((el) => ({
				...mapNfcTagToTransferListItem(el),
				disabled: chosenArray.includes(el.id),
			}));
			setAvailableLeftList(mapped);
		} else if (usersData) {
			const mapped = usersData.results.map((el) => ({
				...mapUserToTransferListItem(el),
				disabled: chosenArray.includes(el.id),
			}));
			setAvailableLeftList(mapped);
		}
	};

	const getRightList = (value) => {
		const items = [
			...(value?.nfcTags?.map((el) => mapNfcTagToTransferListItem(el)) ?? []),
			...(value?.users?.map((el) => mapUserToTransferListItem(el)) ?? []),
		];
		return items;
	};

	return (
		<FormContainer loading={isUserGroupLoading}>
			{isSuperAdmin() && (
				<Controller
					name='organisation'
					control={control}
					render={({ field }) => (
						<OrganisationAutocomplete
							required
							value={field.value}
							onChange={(e, newValue) => field.onChange(newValue)}
						/>
					)}
				/>
			)}
			<Controller
				name='label'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.name')}
						required
						slotProps={{
							htmlInput: {
								minLength: schema.properties.label.minLength,
								maxLength: schema.properties.label.maxLength,
							},
						}}
					/>
				)}
			/>
			<Controller
				name='description'
				control={control}
				render={({ field }) => (
					<TextField
						{...field}
						label={t('ui.label.description')}
						slotProps={{
							htmlInput: {
								maxLength: schema.properties.description.maxLength,
							},
						}}
					/>
				)}
			/>
			<Stack
				direction='row'
				spacing={1}
				sx={{
					width: 'fit-content',
				}}
			>
				<AsyncTextField
					placeholder={t('views.usersAndOrganisations.users.searchPlaceholder')}
					defaultValue={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					manual
				/>
				<Stack
					direction='row'
					spacing={1}
					sx={{
						alignItems: 'center',
					}}
				>
					<Typography>{t('ui.label.users')}</Typography>
					<Switch
						checked={selectionType === ParticipantTypeEnum.NfcTag}
						onChange={(e) =>
							setSelectionType(
								e.target.checked ? ParticipantTypeEnum.NfcTag : ParticipantTypeEnum.User,
							)
						}
					/>
					<Typography>{t('ui.label.nfcTags')}</Typography>
				</Stack>
			</Stack>
			<Controller
				name='participants'
				control={control}
				render={({ field }) => (
					<TransferList
						leftTitle={t('availableResource', {
							resource: t(selectionType === ParticipantTypeEnum.NfcTag ? 'tags' : 'users'),
						})}
						leftItems={availableLeftList}
						leftItemsCount={totalLeftCount}
						leftLoading={isLeftLoading || isLeftValidating}
						leftPage={leftPagination.page}
						onLeftPageChange={(val) => setLeftPagination((prev) => ({ ...prev, page: val }))}
						rowsPerPage={rowsPerPage}
						rightTitle={t('selectedResource', { resource: `${t(`tags`)} & ${t('users')}` })}
						rightItems={getRightList(field.value)}
						rightPage={rightPage}
						onRightPageChange={(val) => setRightPage(val)}
						onChange={(val) => handleTransferListChange(val, field.onChange)}
						onRowsPerPageChange={handleRowsPerPageChange}
					/>
				)}
			/>
		</FormContainer>
	);
});
SkcUserGroupForm.displayName = 'SkcUserGroupForm';

export default SkcUserGroupForm;
