import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';

import { ajvResolver } from '@hookform/resolvers/ajv';
import { Nfc as NfcIcon } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import useSWR from 'swr';

import { FormContainer } from '~components';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { UserAutocomplete } from '~features/users';
import { IdReference } from '~interfaces';
import { FormWrapperRefProps } from '~interfaces/refProps';

import schema from './linkUserToNfcTagSchema.json';
import NfcTag from '../../interfaces/nfcTag';
import NfcTagsService from '../../services/nfcTagsService';

const service = new NfcTagsService();

interface LinkUserToNfcTagData {
	user: IdReference;
	nfcTag: NfcTag;
}

interface LinkUserToNfcTagFormProps {
	nfcId: string;
	onSubmit: (value: LinkUserToNfcTagData) => void;
}

const LinkUserToNfcTagForm = forwardRef<FormWrapperRefProps, LinkUserToNfcTagFormProps>(
	({ nfcId, onSubmit }, ref) => {
		const { data, isLoading, error } = useSWR([service.basePath, nfcId], ([_, id]) =>
			service.getNfcTagById(id),
		);

		const { setDisabled } = useFormContainerState();
		const { getValues, control, formState, reset } = useForm<Partial<LinkUserToNfcTagData>>({
			defaultValues: useMemo(() => ({ nfcTag: data }), [data]),
			mode: 'onChange',
			resolver: ajvResolver(schema),
		});

		useEffect(() => {
			if (data) {
				// Used to reset the useform, otherwise the page won't properly reload
				reset({ user: data });
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				onSubmit: () => onSubmit?.(getValues()),
			}),
			[onSubmit],
		);

		useEffect(() => {
			setDisabled?.(!formState.isValid);
		}, [formState.isValid, setDisabled]);

		return (
			<FormContainer loading={isLoading}>
				<Paper>
					<ListItem>
						<ListItemIcon>
							<NfcIcon />
						</ListItemIcon>
						<ListItemText primary={data?.tagNumber} secondary={data?.id} />
					</ListItem>
				</Paper>
				<Controller
					name='user'
					control={control}
					render={({ field }) => (
						<UserAutocomplete
							{...field}
							organisationId={data?.organisation?.id}
							onChange={(_, newValue) => field.onChange(newValue)}
						/>
					)}
				/>
			</FormContainer>
		);
	},
);
LinkUserToNfcTagForm.displayName = 'LinkUserToNfcTagForm';

export default LinkUserToNfcTagForm;
