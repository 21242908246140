import path from 'path';

import { IdReference, PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import ApprovalStateEnum from '../enums/approvalStateEnum';
import BookingStatusEnum from '../enums/bookingStatusEnum';
import UsageStateEnum from '../enums/usageStateEnum';
import Booking from '../interfaces/booking';
import BookingWriteable from '../interfaces/bookingWriteable';

class BookingsService extends TopologyService {
	public readonly path = 'bookings';

	constructor() {
		super('v1');
	}

	/**
	 * Create a new booking
	 * TODO: planboard vs booking endpoint?
	 * @param data
	 * @returns
	 */
	async createBooking(data: BookingWriteable): Promise<IdReference> {
		const content = BookingsService.toRequest(data);

		const response = await this._client.post('planboard', content);

		return response.data;
	}

	/**
	 * Get a paginated list of bookings
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getBookings({
		page = 1,
		pageSize = 10,
		sortBy = 'id',
		descending = true,
		...args
	}: GeneralPagedParameters<'id'>): Promise<PagedResults<Booking>> {
		const { data } = await this._client.get<PagedResponse<BookingResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				providerId: args.organisationId,
				sortBy: sortBy,
				orderDescending: descending,
			},
		});

		return this.mapPagedResponse(data, BookingsService.fromResponse);
	}

	/**
	 * Get details of a single booking
	 * @param id
	 * @returns
	 */
	async getBookingById(id: string): Promise<Booking> {
		const { data } = await this._client.get<BookingResponse>(path.join(this.path, id));

		return BookingsService.fromResponse(data);
	}

	async sendBookingInvoice(
		bookingId: string,
		email: string,
		action: 'reuseExisting' | 'replace' | 'generateNew' = 'reuseExisting'
	): Promise<null> {
		const { data } = await this._client.post(
			path.join(this.path, bookingId, 'sendInvoice'), {
				params: {
					emailAddress: email,
					action: action
				}
			}
		);

		return null;
	}

	/**
	 * Delete a booking
	 * @param id
	 * @returns
	 */
	async deleteBooking(id: string): Promise<null> {
		const { data } = await this._client.get<null>(path.join(this.path, id));

		return data;
	}
	
	static fromResponse(data: BookingResponse): Booking {
		const { id, name, periodStart, periodEnd, itemInstance, hubReference, userReference, ...rest } =
			data;

		return {
			...rest,
			...BookingsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			period: {
				start: new Date(periodStart),
				end: new Date(periodEnd),
			},
			user: BookingsService.fromBaseReferenceResponse(userReference),
			item: BookingsService.fromBaseReferenceResponse(itemInstance),
			hub: BookingsService.fromBaseReferenceResponse(hubReference),
		};
	}

	static fromSlimResponse(data: BookingSlimResponse): Booking {
		const { id, name, start, end, hub, instance, user, ...rest } = data;

		return {
			...rest,
			...BookingsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			period: {
				start: new Date(start),
				end: new Date(end),
			},
			user: BookingsService.fromBaseReferenceResponse(user),
			item: BookingsService.fromBaseReferenceResponse(instance),
			hub: BookingsService.fromBaseReferenceResponse(hub),
		};
	}

	static toRequest(data: BookingWriteable): BookingRequest {
		const { ...rest } = data;

		return {
			...rest,
		};
	}
}

interface BaseBookingResponse extends BaseReferenceResponse {
	status: BookingStatusEnum;
	approvalState: ApprovalStateEnum;
	usageState: UsageStateEnum;
	attentionStates: string[];
	billingType: 'personal' | 'business';
	price: 'todo';
}

interface BookingSlimResponse extends BaseBookingResponse {
	start: string;
	end: string;
	instance: BaseReferenceResponse;
	user: BaseReferenceResponse;
	hub: BaseReferenceResponse;
}

interface BookingResponse extends BaseBookingResponse {
	periodStart: string;
	periodEnd: string;
	created: string;
	userReference: BaseReferenceResponse;
	hubReference: BaseReferenceResponse;
	itemReference: BaseReferenceResponse;
	itemInstance: BaseReferenceResponse;
	paymentStatus: string;
	priceDetails: 'todo';
	createdByUser: BaseReferenceResponse;
	comment: string;
}

interface BookingRequest {}

export default BookingsService;
