import { useState } from 'react';

import { Grid } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';

import { ConfirmationDialog, Dialog, FormDialog } from '~components';
import { NfcGeneralInfoCard, NfcTag, NfcTagsService } from '~features/nfc';
import {
	LinkNfcTagToUserForm,
	UserGeneralInfoCard,
	UserOrganisationInfoCard,
} from '~features/users';
import { BaseReference, IdReference } from '~interfaces';

import BillingInfo from './BillingInfo';
import CustomerInformation from './CustomerInformation';
import CustomerEdit from './CustomerInformation/CustomerEdit';
import LicenseDetails from './LicenseDetails';
import NfcCard from './NfcCard';
import OrganisationInfo from './OrganisationInfo';
import OtherActions from './OtherActions';
import { useStyles } from './style';
import { isFullString } from '../../../../../shared/utility';
import * as actions from '../../../../../store/actions';
import SummaryLoading from '../../../../OrganisationManagement/Details/OrganisationDetails/Summary/SummaryLoading/SummaryLoading';

interface UserAction {
	type: 'link';
}

const nfcTagsService = new NfcTagsService();

const Summary = (props) => {
	const {
		className,
		customer,
		paymentMethod,
		loading,
		userRole,
		isInternalUser,
		userIsCurrentUser,
		license,
		NFCTags,
		onFetchUser
	} = props;
	const classes = useStyles();
	const auth = useAuth();
	const { t } = useTranslation('general');
	const { enqueueSnackbar } = useSnackbar();

	const [userActionProcessing, setUserActionProcessing] = useState(false);
	const [userAction, setUserAction] = useState<UserAction | null>(null);

	const role = auth.user?.profile.role.toLowerCase();
	const superAdmin = isFullString(role) && role === 'superadmin';

	const gridProps = { item: true, xs: 12, md: 6, lg: 6, xl: 4, flexGrow: 1 };

	const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);

	const userProps = {
		customer,
		isSuperAdmin: superAdmin,
		userIsCurrentUser,
		isInternalUser,
		NFCTags,
	};

	// Use this temporary just to mutate the nfc by id
	const nfcId = userProps.NFCTags && userProps.NFCTags.length > 0 ? userProps.NFCTags[0].nfcId : undefined;
	const [refreshObject, setRefreshObject] = useState<object | null>(null);

	const handleLinkNfcTag = async (userId: string, nfcTagData: NfcTag) => {
		setUserActionProcessing(true);

		try {
			const newData = {
				...nfcTagData,
				user: { id: userId },
			};
			const res = await nfcTagsService.updateNfcTag(nfcTagData.id, newData);

			// Success
			enqueueSnackbar('Linked', { variant: 'success' });
			setUserAction(null);
			onFetchUser(customer.id);
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
		} finally {
			setUserActionProcessing(false)
		}
	};

	const handleUnlinkNfcTag = async (nfcId: string) => {
		setUserActionProcessing(true);

		try {
			await nfcTagsService.unlinkNfcTag(nfcId);
			enqueueSnackbar('Nfc unlinked', { variant: 'success' });

			// TODO: this probably doesn't work, because we lose the reference to
			// the id once unlinked
			// Look why it doesn't mutate
			setUserAction(null);
			onFetchUser(customer.id);
		} catch (error) {
			enqueueSnackbar(t('somethingWentWrong'), { variant: 'error' });
			console.error(error);
		} finally {
			setUserActionProcessing(false);
		}
	};

	return (
			customer &&
				((isInternalUser ? isFullString(userRole) : true) || customer?.isAnonymous) &&
				!loading
		) ?
			<>
				<Grid className={clsx(classes.root, className)} container spacing={3} display='flex'>
					<Grid {...gridProps}>
						<UserGeneralInfoCard
							userId={customer.id?.toString()}
							onEditClick={() => setEditUserDialogOpen((prev) => !prev)}
						/>
					</Grid>
					{/* <Grid {...gridProps}>
						<UserOrganisationInfoCard
							userId={customer.id?.toString()}
						/>
					</Grid> */}
					<Grid {...gridProps}>
						<OrganisationInfo {...userProps} />
					</Grid>
					{superAdmin ?
						<>
							<Grid {...gridProps}>
								<BillingInfo paymentMethod={paymentMethod} />
							</Grid>
							<Grid {...gridProps}>
								<LicenseDetails {...userProps} license={license} />
							</Grid>
						</>
					:	null}
					{superAdmin || (!customer?.isAnonymous && isInternalUser) ?
						<Grid {...gridProps}>
							<OtherActions {...userProps} userRole={userRole} />
						</Grid>
					:	null}
					<Grid {...gridProps}>
						<NfcGeneralInfoCard
							id={nfcId}
							onLinkClick={() => setUserAction({ type: 'link' })}
							onUnlinkClick={(nfcId) => setUserAction({ type: 'unlink', id: nfcId })}
							tmpRefreshNum={refreshObject}
						/>
					</Grid>
				</Grid>
				<CustomerEdit
					customer={customer}
					onClose={() => setEditUserDialogOpen(false)}
					open={editUserDialogOpen}
				/>
				<FormDialog
					title='Link nfc tag'
					open={userAction?.type === 'link'}
					loading={userActionProcessing}
					onClose={() => setUserAction(null)}
					saveLabel={t('ui.confirm')}
					fullWidth
					maxWidth='sm'
				>
					<LinkNfcTagToUserForm
						userId={customer.id?.toString()}
						onSubmit={(data) => handleLinkNfcTag(customer.id.toString(), data.nfcTag)}
					/>
				</FormDialog>
				<ConfirmationDialog
					open={userAction?.type === 'unlink'}
					title={t('views.usersAndOrganisations.nfcUnlink.title')}
					subTitle={`${customer.name || ''} ${t('views.usersAndOrganisations.nfcUnlink.description')}`}
					loading={userActionProcessing}
					onConfirm={() => handleUnlinkNfcTag(userAction?.id)}
					onClose={() => setUserAction(null)}
				/>
			</>
		:	<SummaryLoading />;
};

Summary.propTypes = {
	className: PropTypes.string,
	customer: PropTypes.object,
	paymentMethod: PropTypes.object,
	license: PropTypes.object,
	loading: PropTypes.bool,
	userRoleData: PropTypes.string,

	currentUserData: PropTypes.object,
	isInternalUser: PropTypes.bool,
	userIsCurrentUser: PropTypes.bool,
	NFCTags: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUser: (id) => dispatch(actions.fetchUser(id)),
	};
};

export default connect(null, mapDispatchToProps)(Summary);
