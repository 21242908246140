import path from 'path';

import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, IdReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import ServiceTicket from '../interfaces/serviceTicket';
import ServiceTicketStatusEnum from '../enum/serviceTicketStatusEnum';
import { ItemCategoryEnum } from '~enums';
import TicketSeverityEnum from '../enum/ticketSeverityEnum';

class ServiceTicketsService extends TopologyService {
	public readonly path = 'tickets';

	constructor() {
		super('v1');
	}

	/**
	 * Get a list of access rules
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getServiceTickets({
		page = 1,
		pageSize = 10,
		access = ['owned', 'shared'],
		...args
	}: {
		access: ('owned' | 'shared' | 'public')[];
		status?: ('none' | 'open' | 'pending' | 'planned' | 'ready' | 'closed' | 'unclosed')[]
	} & SearchPagedParameters): Promise<PagedResults<ServiceTicket>> {
		const { data } = await this._client.get<PagedResponse<ServiceTicketResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
				access: access.join(','),
				status: args.status != null && args.status?.length > 0 ? args.status.join(',') : undefined
			},
		});

		return this.mapPagedResponse(data, ServiceTicketsService.fromResponse);
	}

	/**
	 * Get the details of a single service ticket
	 * @param id The id of the service ticket
	 * @returns
	 */
	async getServiceTicketById(id: string): Promise<ServiceTicket> {
		const { data } = await this._client.get<ServiceTicketResponse>(path.join(this.path, id));

		return ServiceTicketsService.fromResponse(data);
	}
	
	static fromResponse(data: ServiceTicketResponse): ServiceTicket {
		const {
			id,
			name,
			itemInstanceReference,
			dateCreated,
			dateModified,
			status,
			defectReference,
			serviceReference,
			...rest
		} = data;

		return {
			...rest,
			...ServiceTicketsService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			dateCreated: new Date(dateCreated),
			dateModified: new Date(dateModified),
			item: {
				...ServiceTicketsService.fromBaseReferenceResponse(
					itemInstanceReference
				),
				itemGroup: ServiceTicketsService.fromBaseReferenceResponse(
					itemInstanceReference.itemReference
				),
				category: itemInstanceReference.itemReference.categoryReference.type as ItemCategoryEnum,
				hub: ServiceTicketsService.fromBaseReferenceResponse(
					itemInstanceReference.hubReference
				)
			},
			status: status as ServiceTicketStatusEnum,
			defect: {
				id: defectReference.id.toString(),
				label: defectReference.title,
				severity: defectReference.type as TicketSeverityEnum
			},
			// Why from the hub?
			provider: ServiceTicketsService.fromBaseReferenceResponse(
				itemInstanceReference.hubReference.organisationReference
			),
			serviceOrganisation: ServiceTicketsService.fromBaseReferenceResponse(
				serviceReference
			)
		};
	}
}

// This is validated for the tripsinsights
// Different for the normal trip?
interface ServiceTicketResponse extends BaseReferenceResponse {
	description: string;
	dateCreated: string;
	dateModified: string;
	status: string;
	defectReference: {
		title: string;
		type: string;
		categoryReference: CategoryResponse
	} & IdReferenceResponse;
	itemInstanceReference: {
		itemReference: {
			categoryReference: CategoryResponse
		} & BaseReferenceResponse;
		hubReference: {
			latitude: number;
			longitude: number;
			organisationReference: BaseReferenceResponse
		} & BaseReferenceResponse;
	} & BaseReferenceResponse;
	userReference: IdReferenceResponse;
	serviceReference?: BaseReferenceResponse;
}

interface CategoryResponse extends BaseReferenceResponse {
	type: string;
}

export default ServiceTicketsService;
