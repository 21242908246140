import { PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { Refresh as RefreshIcon } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import {
	GridFilterModel,
	gridFilterModelSelector,
	GridToolbarContainer,
	useGridApiContext,
	useGridSelector,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import AsyncTextField from '~components/textFields/asyncTextField';
import { useAuthorize } from '~features/authentication';
import { OrganisationAutocomplete } from '~features/organisations';
import { BaseReference } from '~interfaces';
import i18n from '~lib/i18n';

interface DataGridToolbarProps extends PropsWithChildren {
	disableSearch?: boolean;
	disabledOrganisationFilter?: boolean;
	searchPlaceholder?: string;
	filters?: JSX.Element;
}

const DataGridToolbar = ({
	searchPlaceholder = i18n.t('ui.placeholders.search.generic'),
	filters,
	disableSearch = false,
	disabledOrganisationFilter = false,
	children,
}: DataGridToolbarProps) => {
	const { t } = useTranslation('general');
	const { isSuperAdmin } = useAuthorize();
	const apiRef = useGridApiContext();

	const [inputSearchValue, setInputSearchValue] = useState<string>('');

	const filterModel = useGridSelector(apiRef, gridFilterModelSelector);

	const organisation = useMemo(() => {
		return filterModel.items.length > 0 && filterModel.items[0].field === 'organisation' ?
				filterModel.items[0].value
			:	undefined;
	}, [filterModel.items]);

	const handleOrganisationFilterChange = (_, newValue: BaseReference | null) => {
		const newFilterModel: GridFilterModel = {
			...filterModel,
			items:
				newValue != null ?
					[
						{
							field: 'organisation',
							operator: 'contains',
							value: newValue,
						},
					]
				:	[],
		};
		apiRef.current.setFilterModel(newFilterModel);
	};

	const handleQuickFilterChange = (e) => {
		const newFilterModel: GridFilterModel = {
			...filterModel,
			quickFilterValues: e.target.value ? [e.target.value] : [],
		};

		apiRef.current.setFilterModel(newFilterModel);
	};

	const handleClearAllFilters = () => {
		const newFilterModel: GridFilterModel = {
			...filterModel,
			items: [],
			quickFilterValues: [],
		};

		setInputSearchValue('');
		apiRef.current.setFilterModel(newFilterModel);
	};

	const canClearAllFilters = () =>
		(isSuperAdmin() &&
			filterModel.items.length > 0 &&
			filterModel.items[0].field === 'organisation') ||
		(filterModel.quickFilterValues && filterModel.quickFilterValues[0]?.length > 0);

	return (
		<GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
			<Stack direction='row' spacing={1}>
				{!disableSearch && (
					<AsyncTextField
						manual
						size='small'
						placeholder={searchPlaceholder}
						inputValue={inputSearchValue}
						onInputChange={(e) => setInputSearchValue(e.target.value)}
						onChange={handleQuickFilterChange}
						sx={{ width: 220 }}
					/>
				)}
				{isSuperAdmin() && !disabledOrganisationFilter && (
					<OrganisationAutocomplete
						size='small'
						value={organisation}
						onChange={handleOrganisationFilterChange}
						sx={{ width: 220 }}
					/>
				)}
				{filters}
				{canClearAllFilters() && (
					<Button startIcon={<RefreshIcon />} onClick={handleClearAllFilters}>
						{t('ui.clearFilters')}
					</Button>
				)}
			</Stack>
			<Stack direction='row' spacing={1}>
				{children}
			</Stack>
		</GridToolbarContainer>
	);
};

export default DataGridToolbar;
