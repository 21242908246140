import React, { useRef, useState } from 'react';

import { AutocompleteInputChangeReason, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { IdReference, PagedResults } from '~interfaces';
import i18n from '~lib/i18n';

import Item from '../../interfaces/item';
import ItemsService from '../../services/itemsService';
import ItemCard from '../cards/itemCard';

const service = new ItemsService();

interface ItemAutocompleteProps extends Omit<AsyncAutocompleteProps<Item>, 'options' | 'label'> {
	label?: string;
	count?: number;
	organisation?: IdReference;
}

const ItemAutocomplete = ({
	label = i18n.t('item'),
	count = 10,
	organisation,
	value,
	...asyncAutocompleteProps
}: ItemAutocompleteProps) => {
	const { t } = useTranslation('general');

	const [searchQuery, setSearchQuery] = useState('');
	const [open, setOpen] = useState(false);

	// Let's set the on the first page response. Accept that it may happen
	// if an organisation is added in the mean time
	// Small changes right..?
	const total = useRef<number>();
	const page = useRef(1);

	const getKey = (i: number, previousData: PagedResults<Item[]>) => {
		if (!open || (previousData && !previousData.hasMore)) {
			// reached the end
			return null;
		}

		page.current = i + 1;

		return [
			service.basePath,
			{
				page: page.current,
				pageSize: count,
				organisationId: organisation?.id,
				searchQuery: searchQuery || undefined,
			},
		] as const;
	};

	const { data, isLoading, isValidating, size, setSize } = useSWRInfinite(
		getKey,
		([_, args]) => service.getItems(args),
		{
			keepPreviousData: true,
			revalidateFirstPage: false,
			onSuccess: (res) => (total.current = res[0].total),
		},
	);

	/**
	 * Just set the size of the pages
	 */
	const handleOverflow = () => {
		if ((size - 1) * count <= total.current) {
			setSize(size + 1);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setSearchQuery(value);

		asyncAutocompleteProps.onInputChange?.(e, value, reason);
	};

	if (value != null) {
		return (
			<ItemCard
				data={value}
				clearable
				onClear={() => asyncAutocompleteProps.onChange?.({}, null, 'clear')}
			/>
		);
	}

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			delay={1000}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			options={data?.flatMap((el) => el.results) ?? []}
			onInputChange={handleInputChange}
			onOverflow={handleOverflow}
			renderOption={(props, option) => {
				const { key, ...optionProps } = props;

				return (
					<Stack component='li' {...optionProps} key={option.id} direction='row' spacing={1}>
						{/* <Avatar src={option.imageUrl} /> */}
						<Stack>
							<Typography>{`${option.itemGroup.label} - ${option.label}`}</Typography>
							<Typography color='textSecondary'>{option.hub.address?.toStreetAndLowerString()}</Typography>
						</Stack>
					</Stack>
				);
			}}
		/>
	);
};

export default ItemAutocomplete;
