import { FETCH_PAGED_START, FETCH_PAGED_SUCCESS, FETCH_PAGED_FAIL } from './actionTypes';
import events, { Counter } from './eventServices';
import { isEmptyArray, isArray, isFullArray, isFunction } from '../../shared/utility';
import { pagedStates } from '../states';

/* * * * * * * * * * * * * * *
 * ACTIVE ACTION TYPE METHODS *
 * * * * * * * * * * * * * *  */
const PagedStatesCounter = new Counter(pagedStates);

// action type methods return current active action type that is determined by the state of the fetch requests.
// Also these methods pass data passed from user methods to Redux reducers to update states
export const fetchPagedStart = (identifier) => {
	return {
		type: FETCH_PAGED_START,
		identifier: identifier,
	};
};

export const fetchPagedSuccess = (identifier, data = {}, concat = false) => {
	PagedStatesCounter.reset(identifier);
	return {
		type: FETCH_PAGED_SUCCESS,
		identifier: identifier,
		data: data,
		concat: concat,
	};
};

export const fetchPagedFail = (
	identifier,
	error = 'Error message missing. Please contact site administrator.',
) => {
	PagedStatesCounter.reset(identifier);
	return {
		type: FETCH_PAGED_FAIL,
		identifier: identifier,
		error: error,
	};
};

export const updatePagedState = (identifier, data = null, concat = false) => {
	return (dispatch) => {
		dispatch(fetchPagedSuccess(identifier, data, concat));
	};
};

/* * * * * * * * * * * * * * * *
 * GENERALIZED FETCH FUNCTION  *
 ** * * * * * * * * * * * * * * */
const fetchPaged = (identifier, path, settings = {}) => {
	const current = PagedStatesCounter.increment(identifier);
	const { method = 'get', transformer, concat = false, bodyData = null, errorCallback } = settings;
	return async (dispatch) => {
		dispatch(fetchPagedStart(identifier));
		try {
			let data = await (isArray(path) ?
				Promise.all(path.map((p) => events[method](p, bodyData)))
			:	events[method](path, bodyData));
			if (PagedStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(transformer)) {
					data = transformer(data);
				}
				dispatch(fetchPagedSuccess(identifier, data, !!concat));
			}
		} catch (error) {
			if (PagedStatesCounter.isCurrent(identifier, current)) {
				if (isFunction(errorCallback)) {
					errorCallback(dispatch, error);
				} else {
					dispatch(fetchPagedFail(identifier, error));
				}
			}
		}
	};
};

/* * * * * * * * * * * * * * * *
 * UTILITY METHODS  *
 ** * * * * * * * * * * * * * * */
//flatten filters

export const flattenFilters = (filters) => {
	const filtersStr =
		isFullArray(Object.keys(filters)) ?
			Object.entries(filters)
				.reduce(
					(arr, map) =>
						// flatten filterproperties is passed in array
						isEmptyArray(map[1]) ? arr
						: isFullArray(map[1]) ? [...arr, ...map[1].map((value) => [map[0], value])]
						: [...arr, ...[map]],
					[],
				)
				.filter((map) => isFullArray(map))
				.map((map) => `${map[0]}=${map[1]}`)
				.join('&')
		:	'';

	const filterString = '&' + [filtersStr].filter((str) => str.length).join('&');

	return filterString;
};

/* * * * * * * * * * * *  *
 * USER ACCESSIBLE METHODS *
 * * * * * * * * * * * * * */
// User accessible methods are exported and can be accessed across the whole project.
// These methods execute fetch calls and dispatch correct method that updates active action type according the state of the request

/* * * * * * * * *
 * USER METHODS  *
 * * * * * * * * */
export const fetchUsers = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
	includeConsumers = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'users',
		`users?pageNumber=${page.number}&pageSize=${page.size}&includeConsumers=${includeConsumers}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchAssignedUsers = (
	userGroupId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'assignedUsers',
		`users?pageNumber=${page.number}&pageSize=${page.size}&userGroupId=${userGroupId}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * * * * *
 * ORGANISATION METHODS  *
 * * * * * * * * * * * * */
export const fetchOrganisations = (
	page = { number: 1, size: 10 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'organisations',
		`organisations?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * * *
 * LOCATIONS METHODS *
 * * * * * * * * * * */
export const fetchHubs = (page = { number: 1, size: 40 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged('hubs', `hubs?pageNumber=${page.number}&pageSize=${page.size}${filterString}`, {
		concat: concat ? page.number > 1 : concat,
	});
};

export const fetchUnrelatedHubs = (
	page = { number: 1, size: 40 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchUnrelatedHubs',
		`hubs?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * * *
 * USER GROUPS METHODS *
 * * * * * * * * * * * */
export const fetchUserGroups = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'userGroups',
		`userGroups?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * *
 * ITEMS METHODS *
 * * * * * * * * */
export const fetchItems = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'items',
		`items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchAssignedItems = (
	userGroupId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'assignedItems',
		`items?pageNumber=${page.number}&pageSize=${page.size}&userGroupId=${userGroupId}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * * * * * * * * *
 * TERMS AND CONDITIONS METHODS  *
 * * * * * * * * * * * * * * * * */
export const fetchTerms = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'terms',
		`terms?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * *
 * ITEM INSTANCES *
 * * * * * * * * */
export const fetchItemInstances = (
	itemId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'itemInstances',
		`items/${itemId}/iteminstances?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * *
 * PRICING MODELS *
 * * * * * * * * */
export const fetchPricingModels = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'pricingModels',
		`pricingmodels?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * POLICIES  MODELS *
 * * * * * * * * * */
export const fetchPolicies = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'policies',
		`policies?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * BOOKINGS  MODELS *
 * * * * * * * * * */
export const fetchBookings = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'bookings',
		`bookings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * *
 * ACTIVITIES *
 * * * * * *  */
export const fetchItemActivities = (
	itemId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'itemActivities',
		`items/${itemId}/activity?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * *
 * PARTNERSHIPS *
 * * * * * * * */
export const fetchPartnerships = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchPartnerships',
		`partnerships?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchAvailablePartnerships = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'availablePartnerships',
		`partnerships/available?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * USER GROUP ITEMS *
 * * * * * * * * * */
export const userGroupItems = (
	userGroupsId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'items',
		`usergroups/${userGroupsId}/items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchSharedItems = (
	partnerId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'items',
		`partnerships/${partnerId}/items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchInvitations = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'invitations',
		`invitations?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchTickets = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	filters['access'] = 'owned,shared';
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchTickets',
		`tickets?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * REVENUES METHODS *
 * * * * * * * * * */
export const fetchRevenues = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'revenues',
		`finances/revenues?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchRevenueBookings = (
	dateTime = null,
	partnerId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'revenueBookings',
		`finances/revenues/${dateTime}/partners/${partnerId}/bookings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};
/* * * * * * * * * *
 * EXPENSES METHODS *
 * * * * * * * * * */
export const fetchExpenses = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'expenses',
		`finances/expenses?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * BILLINGS METHODS *
 * * * * * * * * * */
export const fetchBillings = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'billings',
		`finances/billings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchExpenseBookings = (
	dateTime = null,
	partnerId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'expenseBookings',
		`finances/expenses/${dateTime}/partners/${partnerId}/bookings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchUserBillingBookings = (
	dateTime = null,
	userId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'userBillingBookings',
		`finances/billings/${dateTime}/users/${userId}/bookings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * BILLINGS DEVICES *
 * * * * * * * * * */
export const fetchDevices = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'devices',
		`devices?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchLinkedDevices = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchLinkedDevices',
		`devices?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchHeartbeats = (
	page = { number: 1, size: 20 },
	filters = {},
	deviceId = null,
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchHeartbeats',
		`devices/${deviceId}/heartbeats?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchAllIteminstances = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'allIteminstances',
		`iteminstances?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * * *
 * Licenses *
 * * * * * * * * * */
export const fetchLicenses = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'licenses',
		`userlicenses?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

/* * * * * * * * *
 * Email Domains  *
 * * * * * * * * */

export const fetchEmailDomains = (
	organisationId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'emailDomains',
		`organisations/${organisationId}/domains?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchAssignableInstances = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'assignableInstances',
		`iteminstances/assignable?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchNfcCards = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchNfcCards',
		`nfccards?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};
/* * * * * * * * * *
 * UNAVAILABILITIES *
 * * * * * * * * * */
export const fetchUnavailabilities = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchUnavailabilities',
		`unavailabilities?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchDockingLocations = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchDockingLocations',
		`dockinglocations?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const fetchDevicesOrganisations = (
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'fetchDevicesOrganisations',
		`devices/filters/organisations?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const deviceTrips = (
	deviceId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'deviceTrips',
		`devices/${deviceId}/trips?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const itemInstanceTrips = (
	itemInstanceId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'itemInstanceTrips',
		`iteminstances/${itemInstanceId}/trips?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const bookingTrips = (
	bookingId = null,
	page = { number: 1, size: 20 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'bookingTrips',
		`bookings/${bookingId}/trips?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const contracts = (page = { number: 1, size: 20 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'contracts',
		`contracts?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const dashboardBookings = (page = { number: 1, size: 5 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'dashboardBookings',
		`dashboard/bookings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const dashboardTickets = (page = { number: 1, size: 5 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'dashboardTickets',
		`dashboard/tickets?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const dashboardUnavailabilities = (
	page = { number: 1, size: 5 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'dashboardUnavailabilities',
		`dashboard/unavailabilities?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const sharings = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'sharings',
		`sharings?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const sharingsConsumers = (
	providerId = null,
	page = { number: 1, size: 10 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'sharingsConsumers',
		`sharings/${providerId}/consumers?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const consumerSharingItems = (
	providerId = null,
	consumerId = null,
	page = { number: 1, size: 10 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'consumerSharingItems',
		`sharings/${providerId}/externals/consumers/${consumerId}/items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const internalSharingItems = (
	providerId = null,
	page = { number: 1, size: 10 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'internalSharingItems',
		`sharings/${providerId}/internals/consumers/items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const publicSharingItems = (
	providerId = null,
	page = { number: 1, size: 10 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'publicSharingItems',
		`sharings/${providerId}/publics/consumers/items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const organisationsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'organisationsSlim',
		`organisations/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const pricingModelsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'pricingModelsSlim',
		`pricingmodels/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const policiesSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'policiesSlim',
		`policies/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const termsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'termsSlim',
		`terms/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const itemsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'itemsSlim',
		`items/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const userGroupsSlim = (page = { number: 1, size: 10 }, filters = {}, concat = false) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'userGroupsSlim',
		`usergroups/slim?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const planboardItems = (page = { number: 1, size: 30 }, filters = {}, concat = true) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'planboardItems',
		`planboard/items?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};

export const userLicensesActivities = (
	userId = null,
	page = { number: 1, size: 10 },
	filters = {},
	concat = false,
) => {
	const filterString = flattenFilters(filters);

	return fetchPaged(
		'userLicensesActivities',
		`userlicenses/${userId}/activities?pageNumber=${page.number}&pageSize=${page.size}${filterString}`,
		{ concat: concat ? page.number > 1 : concat },
	);
};
