import { atomWithDefault } from 'jotai/utils';

import { userInfoAtom } from '~atoms';
import { Organisation } from '~features/organisations';
import { User } from '~features/users';

/**
 * An atom for a shared filter state
 */
const selectedOrganisationAtom = atomWithDefault<Promise<Organisation> | Organisation | null>(
	async (get) => {
		try {
			const userInfo = (await get(userInfoAtom)) as User;
			if (userInfo?.role?.toLowerCase() === 'admin') {
				return userInfo?.organisation;
			}
			return null;
		} catch (error) {
			console.warn(error);
			return null;
		}
	},
);

export default selectedOrganisationAtom;
