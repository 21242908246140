import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import useSWRImmutable from 'swr/immutable';

import ItemGroupsService from '../../services/itemGroupsService';

const service = new ItemGroupsService();

interface ItemGroupCardProps {
	id: string;
}

const ItemGroupCard = ({
	id
}: ItemGroupCardProps) => {
	const { data, isLoading } = useSWRImmutable(
		[service.basePath, id],
		([_, args]) => service.getItemGroupById(args),
		{
			revalidateOnMount: true,
		},
	);

	if (isLoading) {
		return (
			<>Loading</>
		);
	} else if (!data) {
		return (
			<>No data</>
		);
	}

	return (
		<Card>
			<Stack direction='row' spacing={1} sx={{ height: 80 }}>
				<CardMedia
					component='img'
					image={data?.imageSrc}
					sx={{ maxWidth: 150, objectFit: 'cover' }}
				/>
				<CardContent
					
					sx={{
						alignContent: 'center',
						'&:last-child': {
							pb: 2
						}
					}}
				>
					<Typography variant='h5'>
						{data?.label}
					</Typography>
					<Typography>
						{data?.hub.label}
					</Typography>
				</CardContent>
			</Stack>
		</Card>
	);
};

export default ItemGroupCard;
