/**
 * Aka ticketTypeEnum
 * @
 */
enum TicketSeverityEnum {
	NonCritical = 'nonCritical',
	Critical = 'critical',
	// Missing seems like the odd one out. But this was meant for the apps
	// during the onboarding flow that for example a car is not there and that
	// the user cannot use the car
	Missing = 'missing'
}

export default TicketSeverityEnum;
