import { HTMLAttributes, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { Error as ErrorIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Box, Button, LinearProgress, Stack, Typography, useTheme } from '@mui/material';
import {
	DataGrid as MuiDataGrid,
	DataGridProps,
	GridCallbackDetails,
	GridPaginationModel,
	GridSortModel,
	NoResultsOverlayPropsOverrides,
	GridInitialState,
	useGridApiRef,
	GridFilterModel,
	GridFilterItem,
	GridColType,
	gridClasses,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';

import { TooltipNew } from '~components';
import EmptyListIcon from '~components/elements/EmptyState/assets/booking.svg?react';
import { dataGridPageSizeOptions, queryParameters } from '~constants';
import { ComparisonOperatorEnum } from '~enums';
import { useAuthorize } from '~features/authentication';

const snapshotDataGridsKey = 'tdg';

interface CustomDataGridProps extends DataGridProps {
	/**
	 * The key to use to persist part of the datagrid data
	 */
	snapshotKey?: string;
	/**
	 * Set this boolean to allow error overlay inside the datagrid
	 */
	error?: boolean;
}

/**
 * Basically the MUI Datagrid component, but with added functionality like:
 * - Url parameter synchronisation
 * - Default slots components, like a toolbar and overlays
 * - Custom overridden slot components, like the pagination
 * - User datagrid configuration persistence
 * TODO: https://mui.com/x/react-data-grid/custom-columns/#date-pickers
 * @param props
 * @returns
 */
const DataGrid = ({
	snapshotKey,
	error = false,
	paginationMode = 'server',
	sortingMode = 'server',
	sortingOrder = ['desc', 'asc'],
	filterMode = 'server',
	disableColumnMenu = true,
	pageSizeOptions = dataGridPageSizeOptions,
	disableRowSelectionOnClick = true,
	...dataGridProps
}: CustomDataGridProps) => {
	const theme = useTheme();
	const apiRef = useGridApiRef();
	const { isSuperAdmin } = useAuthorize();

	const [paginationModel, setPaginationModel] = useUrlSearchParamsPagination();
	const [sortModel, setSortModel] = useUrlSearchParamsSorting(dataGridProps.sortModel);
	// const [filterModel, setFilterModel] = useUrlSearchParamsFiltering();
	const [filterModel, setFilterModel] = useState<GridFilterModel>(dataGridProps.initialState?.filter?.filterModel);

	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
		organisation: isSuperAdmin(),
	});

	/**
	 * Save a snapshot of the datagrid state to the localstorage
	 */
	const saveSnapshot = useCallback(() => {
		if (!snapshotKey || !apiRef.current?.exportState) {
			return;
		}

		const stateFromLocalStorageString = localStorage?.getItem(snapshotDataGridsKey);

		let state: object = {};
		try {
			// Get the existing state from the localstorage. If for some reason
			state = stateFromLocalStorageString ? JSON.parse(stateFromLocalStorageString) : {};
		} catch (error) {
			console.warn('Invalid datagrid state JSON in localstorage. Reset state', error);
			state = {};
		}

		state[snapshotKey] = apiRef.current.exportState();
		localStorage.setItem(snapshotDataGridsKey, JSON.stringify(state));
	}, [apiRef]);

	useLayoutEffect(() => {
		restoreState();

		return () => {
			saveSnapshot();
		};
	}, [saveSnapshot]);

	/**
	 * Initialize
	 */
	const restoreState = () => {
		const localStorageStateString = localStorage?.getItem(snapshotDataGridsKey);
		let state: GridInitialState = {};
		try {
			if (snapshotKey) {
				// If we have a snapshot key it means we want to persist the state of the datagrid.
				// Try to parse if, but just skips if it fails. In that case we will probably overwrite
				// the corrupt settings on the next saveSnapshot call
				const localStorageState: object = localStorageStateString ? JSON.parse(localStorageStateString) : {};
				state = {
					...state,
					...localStorageState[snapshotKey]
				};
			}
		} catch (error) {
			console.warn('Invalid datagrid state', error);
		} finally {
			// Overwrite certain states. Like the state that is in the url
			state = {
				...state,
				columns: {
					// Why we ignore the columnvisibilitymodel
					// https://github.com/mui/mui-x/issues/11494
					dimensions: state.columns?.dimensions,
					// The orderedfields can give a weird order on a update
					// orderedFields: state.columns?.orderedFields
				},
				filter: {
					filterModel: dataGridProps.initialState?.filter?.filterModel
				},
				pagination: {
					paginationModel: paginationModel,
				},
				sorting: {
					sortModel: sortModel
				},
			};
		}

		apiRef.current?.restoreState?.(state);

		dataGridProps.onPaginationModelChange?.(paginationModel, { reason: 'restoreState', api: apiRef.current });
		if (dataGridProps.onSortModelChange && sortModel) {
			dataGridProps.onSortModelChange(sortModel, { reason: 'restoreState', api: apiRef.current });
		}
		// if (dataGridProps.onFilterModelChange && filterModel) {
		// 	dataGridProps.onFilterModelChange(filterModel, { reason: 'restoreState', api: apiRef.current });
		// }
	};

	/**
	 * Handle a change in pagination and synchronize the changes with the url
	 * @param value
	 * @param details
	 */
	const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
		if (dataGridProps.onPaginationModelChange) {
			// TODO: 20240329 the reason should always be set. Seems like a bug in mui. For that reason, set it
			// ourselfs for now if it is not defined
			dataGridProps.onPaginationModelChange(value, { reason: 'setPaginationModel', ...details });
		}

		setPaginationModel(value);
	};

	/**
	 * Handle a change in sorting and sync this with the url
	 * @param model
	 */
	const handleSortModelChange = (model: GridSortModel, details: GridCallbackDetails) => {
		if (dataGridProps.onSortModelChange) {
			dataGridProps.onSortModelChange(model, details);
		}

		setSortModel(model);
	};

	const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
		if (dataGridProps.onFilterModelChange) {
			dataGridProps.onFilterModelChange(model, details);
		}
		
		setFilterModel(model);
		// setFilterModel(model, details);
	};

	const handleColumnVisibilityModelChange = (model: GridColumnVisibilityModel, details: GridCallbackDetails) => {
		setColumnVisibilityModel(model);

		if (dataGridProps.onColumnVisibilityModelChange) {
			dataGridProps.onColumnVisibilityModelChange(model, details);
		}
	};

	// Take notice about the order of the injected props!
	// Everything before e.g. {...rest} can be overwritten by a child component.
	// Everything after {...rest} will not be overwritten. Additions from the child should
	// be handled in a different way.
	return (
		<MuiDataGrid
			apiRef={apiRef}
			{...dataGridProps}
			// autoPageSize={true}
			disableRowSelectionOnClick={disableRowSelectionOnClick}
			pageSizeOptions={pageSizeOptions}
			disableColumnMenu={disableColumnMenu}
			pagination
			paginationMode={paginationMode}
			paginationModel={paginationModel}
			onPaginationModelChange={handlePaginationChange}
			sortingMode={sortingMode}
			sortingOrder={sortingOrder}
			sortModel={sortModel}
			onSortModelChange={handleSortModelChange}
			filterMode={filterMode}
			filterModel={filterModel}
			onFilterModelChange={handleFilterModelChange}
			// Enabling the columnvisibilitymodel will do weird things with the persist and
			// restore state. Not restoring properly
			// Maybe do it similar to the columns, put it in a usememo?
			columnVisibilityModel={columnVisibilityModel}
			onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
			slots={{
				baseTooltip: TooltipNew,
				loadingOverlay: LoadingOverlay,
				noResultsOverlay: error ? ErrorOverlay : NoResultsOverlay,
				noRowsOverlay: error ? ErrorOverlay : NoResultsOverlay,
				// moreActionsIcon: GridMoreActionsIcon,
				// toolbar: DataGridToolbar,
				...dataGridProps.slots,
			}}
			slotProps={{
				noRowsOverlay: {
					title: 'noResults',
				},
				noResultsOverlay: {
					title: 'noResults',
				},
				...dataGridProps.slotProps,
			}}
			sx={{
				border: 1,
				borderColor: theme.palette.secondary.main,
				color: theme.palette.text.primary,
				fontWeight: 400,
				'.MuiDataGrid-columnHeaderTitle': {
					fontSize: theme.typography.h6.fontSize,
				},
				'.MuiDataGrid-actionsCell': {
					height: 1,
					display: 'flex',
					alignItems: 'center',
				},
				'& .MuiDataGrid-cell': {
					borderTopColor: theme.palette.secondary.main,
					borderLeft: 0,
					borderRight: 0,
					borderBottom: 0,
				},
				[`& .${gridClasses.columnHeader}, & .${gridClasses.cell}`]: {
					outline: 'transparent',
				},
				[`& .${gridClasses.columnHeader}:focus-within, & .${gridClasses.cell}:focus-within`]: {
					outline: 'none',
				},
				...dataGridProps.sx,
			}}
		/>
	);
};

/**
 * A hook to use pagination that keeps its state within the url
 * Notice that the first page for the grid = 0, but the first page
 * for the url = 1
 * @param defaultPageSize 
 * @returns 
 */
const useUrlSearchParamsPagination = (defaultPageSize = 10): [
	GridPaginationModel,
	(model: GridPaginationModel) => void,
] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const page = Number(searchParams.get(queryParameters.PageNumber));
	const pageSize = Number(searchParams.get(queryParameters.PageSize));
	const paginationModel: GridPaginationModel = useMemo(
		() => ({
			page: page > 1 ? page - 1 : 0,
			pageSize: pageSize > 0 ? pageSize : defaultPageSize,
		}),
		[page, pageSize],
	);

	const setPaginationModel = (model: GridPaginationModel) => {
		if (model.page > 0) {
			searchParams.set(queryParameters.PageNumber, (model.page + 1).toString());
		} else {
			searchParams.delete(queryParameters.PageNumber);
		}

		if (model.pageSize > 0) {
			searchParams.set(queryParameters.PageSize, model.pageSize.toString());
		}

		setSearchParams(searchParams, { replace: true });
	};

	return [paginationModel, setPaginationModel];
};

const useUrlSearchParamsSorting = (
	initialSortModel?: GridSortModel | undefined
): [GridSortModel | undefined, (model: GridSortModel) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();

	const sortingParam = searchParams.get(queryParameters.SortBy);
	const orderParam = searchParams.get(queryParameters.OrderBy);
	const sortModel: GridSortModel | undefined = useMemo(
		() =>
			sortingParam != null ? (
				[
					{
						field: sortingParam,
						sort: orderParam === 'desc' ? 'desc' : 'asc',
					},
				]
			) : initialSortModel,
		[sortingParam, orderParam],
	);

	const setSortModel = (model: GridSortModel) => {
		if (!model[0]) {
			searchParams.delete(queryParameters.SortBy);
			searchParams.delete(queryParameters.OrderBy);
		} else {
			searchParams.set(queryParameters.SortBy, model[0].field);

			if (model[0].sort === 'desc' || model[0].sort === 'asc') {
				searchParams.set(queryParameters.OrderBy, model[0].sort);
			} else {
				searchParams.delete(queryParameters.OrderBy);
			}
		}

		setSearchParams(searchParams, { replace: true });
	};

	return [sortModel, setSortModel];
};

const filterSplitRegex = /_|=/;

const useUrlSearchParamsFiltering = (): [GridFilterModel, (model: GridFilterModel, details: GridCallbackDetails) => void] => {
	const [searchParams, setSearchParams] = useSearchParams();
	
	const searchQueryParam = searchParams.get(queryParameters.SearchQuery);
	const filterParams = searchParams.getAll(queryParameters.Filter);
	const filterModel: GridFilterModel = useMemo(
		() => ({
			items: filterParams.map((el) => {
				const elements = el.split(filterSplitRegex);
				const columnType = 'string';
				return mapSearchParamsToFilterModel(el, columnType);
			}),
			quickFilterValues: [searchQueryParam],
		}),
		[searchQueryParam, filterParams],
	);

	const mapSearchParamsToFilterModel = (
		queryParamString: string,
		type: GridColType,
	): GridFilterItem | null => {
		const elements = queryParamString.split(filterSplitRegex);

		let operator;
		const value: string | null = elements[2];

		switch (elements[1]) {
			case ComparisonOperatorEnum.Contains:
				operator = 'contains';
				break;
			default:
				console.info('Filter not supported', type);
				return null;
		}
		
		return {
			field: elements[0],
			operator: operator,
			...(value != null && { value: { id: value, label: 'TODO' } }),
		};
	};

	const mapFilterModelToSearchParams = (
		entry: GridFilterItem,
		type: GridColType,
	): string | null => {
		let shortOperator: ComparisonOperatorEnum;

		switch (entry.operator) {
			case 'contains':
				shortOperator = ComparisonOperatorEnum.Contains;
				break;
			default:
				console.info('Filter not supported', entry.operator);
				return null;
		}

		if (!entry.value) {
			return null;
		}

		return `${entry.field}_${shortOperator}_${entry.value.id}`;
	};

	const setFilterModel = (model: GridFilterModel, details: GridCallbackDetails) => {
		console.log(model)
		if (model.quickFilterValues && model.quickFilterValues[0] != '' && model.quickFilterValues[0] != null) {
			searchParams.set(queryParameters.SearchQuery, model.quickFilterValues[0]);
		} else {
			searchParams.delete(queryParameters.SearchQuery);
		}

		searchParams.delete(queryParameters.Filter);
		model.items.forEach((el) => {
			const column = details.api.getColumn(el.field);
			const queryParamEntry = mapFilterModelToSearchParams(el, column.type);

			if (queryParamEntry) {
				searchParams.append(queryParameters.Filter, queryParamEntry);
			}
		});

		setSearchParams(searchParams, { replace: true });
	};

	return [
		filterModel,
		setFilterModel
	];
};

const NoResultsOverlay = (props: HTMLAttributes<NoResultsOverlayPropsOverrides>) => (
	<Stack
		width={1}
		height={1}
		display='flex'
		alignItems='center'
		justifyContent='center'
		direction='column'
		spacing={1}
		minHeight={300}
	>
		<EmptyListIcon />
		<Typography variant='h6'>{props.title}</Typography>
	</Stack>
);

const LoadingOverlay = () => (
	<Box width={1} height={1} display='flex' flexDirection='column'>
		<LinearProgress />
	</Box>
);

const ErrorOverlay = () => {
	const { t } = useTranslation('general');

	return (
		<Box
			width={1}
			height={1}
			flexDirection='column'
			display='flex'
			alignItems='center'
			justifyContent='center'
		>
			<ErrorIcon fontSize='large' />
			<Typography variant='body1'>{t('somethingWentWrong')}</Typography>
		</Box>
	);
};

export default DataGrid;
