import path from 'path';

import { AxiosResponse } from 'axios';

import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { BaseReferenceResponse, SkcPagedResponse } from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';

import NfcTag from '../interfaces/nfcTag';

class SkcNfcTagsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'rfid-cards';

	/**
	 * Get a list of Nfc Cards
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getNfcTags({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<NfcTag>> {
		const { data } = await this._client.get<SkcPagedResponse<RfidCardResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
				textQuery: args.searchQuery,
			},
		});

		return this.mapPagedResponse(data, SkcNfcTagsService.fromResponse);
	}
	
	static fromResponse(data: RfidCardResponse): NfcTag {
		const { id, name, cardNumber, type } = data;

		return {
			id: id.toString(),
			label: name,
			tagNumber: cardNumber,
			tagType: type === 'none' ? 'external' : 'skopei',
		};
	}
}

// This is validated for the tripsinsights
// Different for the normal trip?
interface RfidCardResponse extends BaseReferenceResponse {
	cardNumber: string;
	type: string;
}

export default SkcNfcTagsService;
