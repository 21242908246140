import path from 'path';

import { PagedResults } from '~interfaces';
import { BasePagedParameters } from '~interfaces/requests';
import {
	BaseReferenceResponse,
	IdReferenceResponse,
	SkcPagedResponse,
	SkcSingleResponse,
} from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';

import AccessLog from '../interfaces/accessLog';

/**
 * A service that does calls to the Skopei Connect API through
 * the Topology backend
 */
class SkcAccessLogsService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'access-logs';

	async getAccessLogs({
		page = 1,
		pageSize = 10,
		...args
	}: BasePagedParameters): Promise<PagedResults<AccessLog>> {
		const { data } = await this._client.get<SkcPagedResponse<SkcAccessLogResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
			},
		});

		return this.mapPagedResponse(data, SkcAccessLogsService.fromResponse);
	}

	async getAccessLogById(id: string): Promise<AccessLog> {
		const { data } = await this._client.get<SkcSingleResponse<SkcAccessLogResponse>>(
			path.join(this.path, id),
		);

		return SkcAccessLogsService.fromResponse(data.data);
	}

	static fromResponse(data: SkcAccessLogResponse): AccessLog {
		const { id, event, timestamp, rule, card, device } = data;

		return {
			id: id.toString(),
			timestamp: new Date(timestamp),
			eventType: event,
			rule: SkcAccessLogsService.fromBaseReferenceResponse(rule),
			nfcTag: SkcAccessLogsService.fromBaseReferenceResponse(card),
			device: SkcAccessLogsService.fromIdReferenceResponse(device),
		};
	}
}

interface SkcAccessLogResponse extends IdReferenceResponse {
	event: string;
	timestamp: string;
	rule?: BaseReferenceResponse;
	card?: BaseReferenceResponse;
	device?: IdReferenceResponse;
}

export default SkcAccessLogsService;
