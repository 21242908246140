import path from 'path';

import { UserRoleEnum } from '~enums';
import { PagedResults } from '~interfaces';
import { GeneralPagedParameters } from '~interfaces/requests';
import { AddressResponse, BaseReferenceResponse, PagedResponse } from '~interfaces/responses';
import { TopologyService } from '~services';

import AddressModel from '../../../models/addressModel';
import User from '../interfaces/user';

/**
 * A service to get "business logic" users. E.g. user data from
 * the Core service
 */
class UsersService extends TopologyService {
	public readonly path = 'users';

	constructor() {
		super('v1');
	}

	/**
	 * Get a paginated list of users
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getUsers({
		page = 1,
		pageSize = 10,
		descending = true,
		sortBy = 'id',
		...args
	}: GeneralPagedParameters<'id' | 'label' | 'email' | 'organisation' | 'userGroup'> & {
		excludeAnonymous?: boolean;
		userGroupId?: string;
	}): Promise<PagedResults<User>> {
		const sortByMapped = sortBy === 'label' ? 'fullName' : sortBy;
		const { data } = await this._client.get<PagedResponse<UserResponse>>(this.path, {
			params: {
				pageNumber: page,
				pageSize: pageSize,
				organisationId: args.organisationId,
				userGroupId: args.userGroupId,
				sortBy: sortByMapped,
				orderDescending: descending,
				hideAnonymous: args.excludeAnonymous,
				...(args.searchQuery && { searchTerm: args.searchQuery }),
			},
		});

		return this.mapPagedResponse(data, UsersService.fromResponse);
	}

	/**
	 * Get the role of the specified user
	 * @param id
	 * @returns
	 */
	async getUserRole(id: string): Promise<UserRoleEnum> {
		const { data } = await this._client.get<string>(path.join('identityusers', id, 'role'));

		return data.toLowerCase() as UserRoleEnum;
	}

	/**
	 * Get information about the user that is logged in.
	 * @returns
	 */
	async getUserInformation(): Promise<User> {
		const { data } = await this._client.get<UserResponse>(path.join(this.path, 'me'));

		return UsersService.fromResponse(data);
	}

	async getUsersForExport(organisationId?: string) {
		const { data } = await this._client.get(path.join(this.path, 'exports'), {
			responseType: 'blob',
			params: {
				organisationId: organisationId,
			},
		});

		return data;
	}

	/**
	 * Get details about a specific user
	 * @param id
	 * @returns
	 */
	async getUserById(id: string): Promise<User> {
		const { data } = await this._client.get<UserResponse>(path.join(this.path, id));

		return UsersService.fromResponse(data);
	}

	/**
	 * Delete a user
	 * @param id
	 * @returns
	 */
	async deleteUser(id: string, hard = false): Promise<null> {
		const { data } = await this._client.delete<null>(path.join(this.path, id), {
			params: {
				hardDelete: hard,
			},
		});

		return data;
	}

	async removeUserFromOrganisation(userId: string): Promise<null> {
		const { data } = await this._client.put(path.join(this.path, userId, 'remove'));

		return null;
	}
	
	static fromResponse(data: UserResponse): User {
		const {
			id,
			name,
			emailAddress,
			userGroupReference,
			organisationReference,
			dateOfBirth,
			imagesReference,
			address,
			cards,
			validationStatus,
			...rest
		} = data;

		return {
			...rest,
			...UsersService.fromBaseReferenceResponse({
				id: id,
				name: name,
			}),
			email: emailAddress,
			userGroup: UsersService.fromBaseReferenceResponse(userGroupReference),
			organisation: {
				...UsersService.fromBaseReferenceResponse(organisationReference),
				logo: organisationReference.logo,
				features: organisationReference.features,
			},
			...(address != null && {
				address: new AddressModel(
					address.country,
					address.city,
					address.postalCode,
					address.street,
					Number(address.number),
					address.numberAddition,
				),
			}),
			imageUrl: imagesReference && imagesReference.length > 0 ? imagesReference[0] : undefined,
			dateOfBirth: dateOfBirth != null ? new Date(dateOfBirth) : undefined,
			cards: cards.map((el) => ({ id: el.nfcId })),
			hasUserLicense: validationStatus !== 'notApplicable',
		};
	}
}

interface UserResponse extends BaseReferenceResponse {
	// TODO: should this be nullable?
	// The backend returns null only if you request a user
	// that is not part of your organisation but booked
	// one of your items
	userGroupReference?: BaseReferenceResponse;
	organisationReference: {
		features: string[];
		logo: string;
	} & BaseReferenceResponse;
	emailAddress: string;
	firstName: string;
	lastName: string;
	role: string;
	cards: {
		nfcId: string;
		cardType: string;
	}[];
	preferences: {
		preferredCultureInfo: string;
	};
	imagesReference?: string[];
	phoneNumber: string;
	isEmailConfirmed: boolean;
	isAnonymous: boolean;
	licenseStatus: string;
	validationStatus: string;
	address?: AddressResponse;
	dateOfBirth?: string;
}

export default UsersService;
