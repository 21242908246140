import path from 'path';

import { PagedResults } from '~interfaces';
import { SearchPagedParameters } from '~interfaces/requests';
import { IdReferenceResponse, SkcPagedResponse } from '~interfaces/responses';
import { TopologyProxiedSkopeiConnectService } from '~services';

import Device from '../interfaces/device';

class SkcDevicesService extends TopologyProxiedSkopeiConnectService {
	public readonly path = 'devices';

	/**
	 * Get a list of devices
	 * @param page The number of the page
	 * @param pageSize The amount of results of the page
	 * @returns
	 */
	async getDevices({
		page = 1,
		pageSize = 10,
		...args
	}: SearchPagedParameters): Promise<PagedResults<Device>> {
		const { data } = await this._client.get<SkcPagedResponse<SkcDeviceResponse>>(this.path, {
			params: {
				'page-number': page,
				'page-size': pageSize,
				organisationId: args.organisationId,
				textQuery: args.searchQuery,
			},
		});

		return this.mapPagedResponse(data, SkcDevicesService.fromResponse);
	}
	
	static fromResponse(data: SkcDeviceResponse): Device {
		const { id, deviceHardwareId, deviceType, skopeiNr } = data;

		return {
			id: id.toString(),
			hardwareId: deviceHardwareId,
			type: deviceType,
			skopeiNumber: skopeiNr,
		};
	}
}

// This is validated for the tripsinsights
// Different for the normal trip?
interface SkcDeviceResponse extends IdReferenceResponse {
	deviceHardwareId: string;
	deviceType: string;
	hardwareProvider: string;
	skopeiNr: string;
}

export default SkcDevicesService;
