import { useState, useEffect, useReducer, useRef } from 'react';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ShareIcon from '@mui/icons-material/Share';
import { Link, Box, Typography, CircularProgress } from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { connect } from 'react-redux';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import ShareDialog from './ShareDialog/ShareDialog';
import { useStyles, MyTooltip } from './style';
import WarningIcon from '../../../assets/icons/label-filled-icon-warning@3x.png';
import {
	EmptyState,
	SearchBar,
	Label,
	AlertDialog,
	GenericMoreButton,
	CollapsibleTable,
	ActionDialog,
	IconInfoLabel,
} from '../../../components';
import { handleReservationType } from '../../../shared/functionality';
import {
	usePrevious,
	useSearchComponent,
	useBasicFilter,
	useComplexFilter,
	usePagination,
} from '../../../shared/hooks';
import { itemReducer } from '../../../shared/reducers';
import {
	isObject,
	isEmptyString,
	isEmptyArray,
	isNull,
	isArray,
	isFullArray,
	isInteger,
	isUndefined,
	isFullString,
	isSuperAdmin,
	handleHubReference,
} from '../../../shared/utility';
import * as actions from '../../../store/actions';

const bookingTypes = ['reservation', 'scanAndGo', 'tapAndGo', 'nonBookable'];

const availability = {
	AVAILABLE: 'makeAvailable',
	UNAVAILABLE: 'makeUnavailable',
	BOOKABLE: 'makeBookable',
	UNBOOKABLE: 'makeUnbookable',
};

const Items = (props) => {
	const {
		onFetchItems,
		itemsList,
		sharedItemsList,
		onFetchOrganisations,
		organisationsList,
		onFetchHubs,
		hubsList,
		onFetchCategories,
		categoriesList,
		deleteItem,
		onDeleteItem,
		onFetchItemInstancesList,
		itemInstances,
		onDeleteItemInstance,
		deleteItemInstance,
		userGroupId,
		userGroupData,
		userGroupReady,
		onUserGroupItemInstances,
		onUnassignUserGroupItem,
		unassignOrCancelItem,
		onUserGroupItems,
		onUpdatePagedState,
		partnerId,
		type,
		itemsOfOrganisation,
		itemsSharedBy,
		onFetchPartnerItemInstances,
		onFetchSharedItems,
		itemSharedWithMe,
		organisationId,
		onUnassignPartnershipsItems,
		onUpdateInstanceAvailability,
		updatedInstanceAvailability,
		itemAccess,
		onFetchBookingTypes,
		updateInstance,
		updatePublicShare,
		currentUser,
		onFetchOrganisation,
		currentOrganisation,
		itemUse,
		onResetState,
		onInstanceRestriction,
		instanceRestriction,
		onResetStateCondition,
	} = props;
	const { t } = useTranslation('general');
	const auth = useAuth();

	const isPublic = itemAccess === 'public';
	const isPrivate = itemAccess === 'private';
	const navigate = useNavigate();
	const location = useLocation();
	const refName = useRef(null);
	const classes = useStyles();

	const { tab } = useParams();

	const { enqueueSnackbar } = useSnackbar();

	const [shouldSearch, setShouldSearch] = useState(false);

	const [sortingCategory, setSortingCategory] = useState('id');
	const [order, setOrder] = useState('asc');
	const [orderDescending, setOrderDescending] = useState(true);
	const [shouldSort, setShouldSort] = useState(false);
	const [filters] = useState('none');
	const [excludes] = useState('images');

	const {
		data: currentUserData,
		loading: currentUserLoading,
		error: currentUserError,
	} = currentUser;
	const currentUserReady = isObject(currentUserData) && !currentUserLoading && !currentUserError;

	const {
		data: currentOrganisationData,
		loading: currentOrganisationLoading,
		error: currentOrganisationError,
	} = currentOrganisation;
	const currentOrganisationReady =
		isObject(currentOrganisationData) && !currentOrganisationLoading && !currentOrganisationError;

	const isSupplierSubscription =
		currentOrganisationReady &&
		currentOrganisationData.subscription &&
		currentOrganisationData.subscription.subscriptionType === 'supplier';

	const {
		data: updatePublicShareData,
		loading: updatePublicShareLoading,
		error: updatePublicShareError,
	} = updatePublicShare;
	const updatePublicShareReady =
		isObject(updatePublicShareData) && !updatePublicShareLoading && !updatePublicShareError;

	const {
		data: categoriesData,
		loading: categoriesLoading,
		error: categoriesError,
	} = categoriesList;
	const categoriesReady = isArray(categoriesData) && !categoriesLoading && !categoriesError;

	const {
		data: itemsData,
		loading: itemsLoading,
		error: itemsError,
	} =
		(
			(itemSharedWithMe && organisationId) ||
			(itemsSharedBy && type === 'consumer') ||
			(itemSharedWithMe && type === 'supplier')
		) ?
			sharedItemsList
		:	itemsList;
	const itemsReady = isObject(itemsData) && !itemsLoading && !itemsError;

	const { data: instancesData, loading: instancesLoading, error: instancesError } = itemInstances;
	const instancesReady = isArray(instancesData) && !instancesLoading && !instancesError;

	const { success: deleteSuccess, loading: deleteLoading, error: deleteError } = deleteItem;
	const deleteDone = deleteSuccess && !deleteLoading && !deleteError;
	const {
		success: deleteInstanceSucces,
		loading: deleteInstanceLoading,
		error: deleteInstanceError,
	} = deleteItemInstance;
	const deleteInstanceDone = deleteInstanceSucces && !deleteInstanceLoading && !deleteInstanceError;

	const previousDeleteLoading = usePrevious(deleteLoading);
	const previousDeleteInstanceLoading = usePrevious(deleteInstanceLoading);

	const {
		data: unassignOrCancelItemData,
		loading: unassignOrCancelItemLoading,
		error: unassignOrCancelItemError,
	} = unassignOrCancelItem;
	const unassignDone =
		isObject(unassignOrCancelItemData) &&
		!unassignOrCancelItemLoading &&
		!unassignOrCancelItemError;
	const previousUnassignOrCancelItemLoading = usePrevious(unassignOrCancelItemLoading);

	const [shouldFilter, setShouldFilter] = useState(false);
	const [shouldDoInitialFetch, setShouldDoInitialFetch] = useState(true);
	const [showingInitialResults, setShowingInitialResults] = useState(true);

	const [fetchedNewItems, setFetchedNewItems] = useState(false);
	const [updatedItems, setUpdatedItems] = useState(false);

	const [items, dispatchItems] = useReducer(itemReducer, []);

	const [selectedItems, setSelectedItems] = useState([]);

	const pagination = usePagination(`pageNumber${itemUse}`, `pageSize${itemUse}`);
	const itemCategoryFilter = useBasicFilter('itemCategoryFilter');
	const itemBookingTypeFilter = useBasicFilter('itemBookingTypeFilter', 'none');
	const itemOrganisationFilter = useComplexFilter(
		'itemOrganisationNameFilter',
		'itemOrganisationIdFilter',
	);
	const itemLocationFilter = useComplexFilter('itemLocationNameFilter', 'itemLocationIdFilter');
	const search = useSearchComponent(pagination.setPageNumber, setShouldSearch, 'itemSearch');

	const getSelectedInstances = (itemsSelected) => {
		return itemsSelected.reduce((acc, cur) => {
			let selectedInstances = [];
			if (!isEmptyArray(cur.selectedInstances)) {
				selectedInstances = selectedInstances.concat(cur.selectedInstances);
			} else {
				selectedInstances = selectedInstances.concat(Array(cur.item.totalItemInstances).fill({}));
			}
			return acc.concat(selectedInstances);
		}, []);
	};

	const [shouldFetchInstances, setShouldFetchInstances] = useState(false);
	const [fetchedNewInstances, setFetchedNewInstances] = useState(false);
	const [itemToFetchInstancesOf, setItemToFetchInstancesOf] = useState();

	const [itemName, setItemName] = useState(null);
	const [instanceNameValue, setInstanceName] = useState(null);

	// Dialog
	const [dialogItem, setDialogItem] = useState(false);
	const [dialogItemInstance, setDialogItemInstance] = useState(false);
	const [dialogUnassignItem, setDialogUnassignItem] = useState(false);
	const [dialogUnassignItemInstance, setDialogUnassignItemInstance] = useState(false);
	const [dialogUnassignMany, setDialogUnassignMany] = useState(false);
	const [dialogCancelSharingItem, setDialogCancelSharingItem] = useState(false);
	const [dialogCancelSharingInstance, setDialogCancelSharingInstance] = useState(false);
	const [dialogCancelSharingMany, setDialogCancelSharingMany] = useState(false);
	const [instanceId, setInstanceId] = useState(false);
	const [updatedInstanceIndex, setUpdatedInstanceIndex] = useState(null);
	const [openPublicInstanceDialog, setOpenPublicInstanceDialog] = useState(false);
	const [openUnshareDialog, setOpenUnshareDialog] = useState(false);
	const [openMassShareDialog, setOpenMassShareDialog] = useState(false);
	const [openMassUnshareDialog, setOpenMassUnshareDialog] = useState(false);

	const [openAvailabilityDialog, setOpenAvailabilityDialog] = useState(false);
	const [availabilityType, setAvailabilityType] = useState('');

	const [widthLocation, setWidthLocation] = useState(null);

	const resetPublickShareDialog = () => {
		if (openPublicInstanceDialog) {
			setOpenPublicInstanceDialog(false);
		} else if (openUnshareDialog) {
			setOpenUnshareDialog(false);
		} else if (openMassShareDialog) {
			setOpenMassShareDialog(false);
		} else if (openMassUnshareDialog) {
			setOpenMassUnshareDialog(false);
		}
	};
	const [massShare, setMassShare] = useState(null);

	const {
		data: updatedInstanceData,
		loading: updatedInstanceLoading,
		error: updatedInstanceError,
	} = updatedInstanceAvailability;
	const updatedInstanceReady =
		isObject(updatedInstanceData) && !updatedInstanceLoading && !updatedInstanceError;

	const {
		success: instanceRestrictionSuccess,
		loading: instanceRestrictionLoading,
		error: instanceRestrictionError,
	} = instanceRestriction;

	const {
		data: updateInstanceData,
		loading: updateInstanceLoading,
		error: updateInstanceError,
	} = updateInstance;
	const updateInstanceReady =
		isObject(updateInstanceData) && !updateInstanceLoading && !updateInstanceError;

	const [itemId, setItemId] = useState(null);
	const [itemInstance, setItemInstance] = useState(null);

	const [deleteInstanceData, setDeleteInstanceData] = useState(null);
	const [deleteItemsData, setDeleteItemsData] = useState(null);
	const [cancelItemsData, setCancelItemsData] = useState(null);

	const filterItems = {
		...(!isEmptyString(search.value) && { searchTerm: search.value }),
		...(!isEmptyString(sortingCategory) && { sortBy: sortingCategory }),
		...(itemCategoryFilter.value !== 'all' && { categoryId: itemCategoryFilter.value }),
		...(itemBookingTypeFilter.value && { bookingType: itemBookingTypeFilter.value }),
		...(itemOrganisationFilter.valueId !== 'all' && {
			organisationId: itemOrganisationFilter.valueId,
		}),
		...(itemLocationFilter.valueId !== 'all' && { hubId: itemLocationFilter.valueId }),
		...(isInteger(partnerId) && { type: type }),
		...(!itemSharedWithMe &&
			!isInteger(userGroupId) &&
			isFullString(itemAccess) && { itemAccess: itemAccess }),
		...(!isInteger(userGroupId) && { filters: filters }),
		...(!isInteger(userGroupId) && { excludes: excludes }),
		orderDescending,
	};

	//fetch current user's organisation to see if organisation can share items publicly
	useEffect(() => {
		if (currentUserReady && !currentOrganisationLoading) {
			onFetchOrganisation(currentUserData.organisationReference.id);
		}
	}, [currentUserReady]);

	useEffect(() => {
		if (unassignDone) {
			const fetchAll = unassignOrCancelItemData.accepted.reduce((acc, accept) => {
				const existing = items.find((item) => item.id === accept.itemId);

				return (
					acc &&
					(accept.instanceIds.length === 0 ||
						(isObject(existing) &&
							accept.instanceIds.length === existing.numberOfAssignedInstances))
				);
			}, false);
			if (fetchAll) {
				onUserGroupItems(userGroupId, pagination.page, filterItems);
			} else {
				const updatedResults = itemsData.results.reduce((acc, item) => {
					const updatedItem = unassignOrCancelItemData.accepted.find(
						(assigned) => assigned.itemId === item.id,
					);
					const assignedInstances =
						!isUndefined(updatedItem) ?
							item.numberOfAssignedInstances - updatedItem.instanceIds.length
						:	null;

					return [
						...acc,
						...(!isInteger(assignedInstances) || assignedInstances > 0 ?
							[
								{
									...item,
									...(assignedInstances && { numberOfAssignedInstances: assignedInstances }),
								},
							]
						:	[]),
					];
				}, []);

				onUpdatePagedState('items', { ...itemsData, results: updatedResults });
				setFetchedNewItems(true);
				unassignOrCancelItemData.accepted.forEach((current) => {
					dispatchItems({ type: 'setSelectAllFalse', id: current.itemId });
					dispatchItems({
						type: 'removeInstanceIds',
						id: current.itemId,
						selectedInstances: current.instanceIds,
					});
				});
				setSelectedItems([]);
			}
			setFetchedNewItems(true);
		}
	}, [unassignDone]);

	useEffect(() => {
		if (previousDeleteLoading && !deleteLoading) {
			if (isObject(deleteError) || isFullString(deleteError)) {
				enqueueSnackbar(
					isObject(deleteError) ? deleteError.message
					: isFullString(deleteError) ? deleteError
					: null,
					{ variant: 'error' },
				);
			} else {
				enqueueSnackbar(`${t('ui.successfullyDeleted')} ${itemName}`, { variant: 'success' });
			}
		}
	}, [deleteLoading]);

	useEffect(() => {
		if (previousDeleteInstanceLoading && !deleteInstanceLoading) {
			if (isObject(deleteInstanceError) || isFullString(deleteInstanceError)) {
				enqueueSnackbar(
					isObject(deleteInstanceError) ? deleteInstanceError.message
					: isFullString(deleteInstanceError) ? deleteInstanceError
					: null,
					{ variant: 'error' },
				);
			} else {
				enqueueSnackbar(`${t('ui.successfullyDeleted')} ${instanceNameValue}`, {
					variant: 'success',
				});
				//locally remove instance
				if (deleteInstanceData) {
					const fetchAll = items.reduce((acc, item) => {
						const totalInstances =
							item.id === deleteInstanceData.itemReference.id ? item.totalItemInstances - 1 : null;

						return acc && totalInstances > 0;
					}, false);

					if (fetchAll) {
						onFetchItems(pagination.page, filterItems);
					} else {
						const updatedResults = itemsData.results.reduce((acc, item) => {
							const totalInstances =
								item.id === deleteInstanceData.itemReference.id ?
									item.totalItemInstances - 1
								:	null;

							return [
								...acc,
								...(!isInteger(totalInstances) || totalInstances >= 0 ?
									[
										{
											...item,
											...(isInteger(totalInstances) && { totalItemInstances: totalInstances }),
											...(totalInstances === 0 && { isPublished: false }),
										},
									]
								:	[]),
							];
						}, []);
						onUpdatePagedState('items', { ...itemsData, results: updatedResults });
						setFetchedNewItems(true);
					}
					dispatchItems({
						type: 'removeInstance',
						id: deleteInstanceData.itemReference.id,
						instanceId: deleteInstanceData.id,
					});
				}
			}
		}
	}, [deleteInstanceLoading]);

	useEffect(() => {
		if (previousUnassignOrCancelItemLoading && !unassignOrCancelItemLoading) {
			if (unassignOrCancelItemError) {
				enqueueSnackbar(
					isObject(deleteInstanceError) ? deleteInstanceError.message : deleteInstanceError,
					{ variant: 'error' },
				);
			} else {
				if (userGroupId) {
					if (!isEmptyArray(unassignOrCancelItemData.accepted)) {
						enqueueSnackbar(`${t('views.tableResults.items.message.unassign.success.accepted')} `, {
							variant: 'success',
						});
					}
					if (!isEmptyArray(unassignOrCancelItemData.rejected)) {
						enqueueSnackbar(`${t('views.tableResults.items.message.unassign.success.rejected')} `);
					}
				} else if (itemSharedWithMe) {
					if (!isEmptyArray(unassignOrCancelItemData.accepted)) {
						enqueueSnackbar(`${t('views.tableResults.items.successfully')} `, {
							variant: 'success',
						});
					}
					if (!isEmptyArray(unassignOrCancelItemData.rejected)) {
						enqueueSnackbar(`${t('views.itemManagement.items.stopSharing.error')} `, {
							variant: 'error',
						});
					}
				}
				if (unassignOrCancelItemData) {
					unassignOrCancelItemData.accepted.forEach((el) => {
						el.instanceIds.forEach((instanceId) => {
							dispatchItems({ type: 'removeInstance', id: el.itemId, instanceId: instanceId });
						});
					});
				}
			}
		}
	}, [unassignOrCancelItemLoading]);

	useEffect(() => {
		if ((updatedInstanceReady && updatedInstanceIndex) || isObject(updateInstanceData)) {
			setUpdatedInstanceIndex(null);
			setOpenAvailabilityDialog(false);
			resetPublickShareDialog();
			const updatedItems = items.map((item) => {
				return {
					...item,
					instances: item.instances.map((instance) => {
						if (instance.id === updatedInstanceIndex) {
							return isObject(updateInstanceData) ?
									{ ...updateInstanceData }
								:	{ ...updatedInstanceData };
						}
						return instance;
					}),
				};
			});
			dispatchItems({ type: 'update', updatedItems: updatedItems });
			enqueueSnackbar(t('views.itemManagement.items.availability.message'), { variant: 'success' });
			onResetState('patchInstance');
			onResetState('updatedInstanceAvailability');
		} else if (updatedInstanceIndex && updatePublicShareReady) {
			const updatedItems = items.map((item) => {
				return {
					...item,
					instances: item.instances.map((instance) => {
						{
							const itemToUpdate = updatePublicShareData.accepted.find((i) => i.itemId === item.id);
							if (itemToUpdate && itemToUpdate.instanceIds.includes(instance.id)) {
								return { ...instance, isPublic: !instance.isPublic };
							}
							return instance;
						}
					}),
				};
			});
			dispatchItems({ type: 'update', updatedItems: updatedItems });
			setUpdatedInstanceIndex(null);
			resetPublickShareDialog();
		} else if (updateInstanceError) {
			enqueueSnackbar(updateInstanceError.message, { variant: 'error' });
			onResetState('patchInstance');
			resetPublickShareDialog();
		}
	}, [
		updatedInstanceReady,
		updatedInstanceIndex,
		updateInstanceReady,
		updatePublicShareReady,
		updateInstanceError,
	]);

	useEffect(() => {
		if (instanceRestrictionSuccess) {
			dispatchItems({ type: 'instanceRestriction', items: { itemId, instanceId } });
			setOpenAvailabilityDialog(false);
			enqueueSnackbar(t('views.itemManagement.items.availability.message'), { variant: 'success' });
			onResetStateCondition('instanceRestriction', false);
		} else if (instanceRestrictionError) {
			enqueueSnackbar(t(instanceRestrictionError?.message), { variant: 'error' });
			onResetStateCondition('instanceRestriction', false);
		}
	}, [instanceRestriction]);

	useEffect(() => {
		if (shouldSearch || pagination.fetch || shouldSort || shouldFilter || shouldDoInitialFetch) {
			if ((shouldFilter || shouldSearch || shouldSort) && showingInitialResults) {
				setShowingInitialResults(false);
			}
			if (isInteger(userGroupId)) {
				onUserGroupItems(userGroupId, pagination.page, filterItems);
			} else if (
				(itemSharedWithMe && organisationId) ||
				(itemsSharedBy && type === 'consumer') ||
				(itemSharedWithMe && type === 'supplier')
			) {
				if (!itemsLoading && partnerId) {
					onFetchSharedItems(partnerId, pagination.page, filterItems);
				} else if (!itemsLoading && organisationId) {
					onFetchSharedItems(organisationId, pagination.page, filterItems);
				}
			} else {
				onFetchItems(pagination.page, filterItems);
			}
			setFetchedNewItems(true);
		}

		if (shouldSearch) {
			setShouldSearch(false);
		} else if (shouldFilter) {
			setShouldFilter(false);
		} else if (shouldSort) {
			setShouldSort(false);
		} else if (shouldDoInitialFetch) {
			setShouldDoInitialFetch(false);
		} else if (pagination.fetch) {
			pagination.setFatch(false);
		}
	}, [shouldSearch, shouldFilter, shouldSort, pagination, shouldDoInitialFetch]);

	useEffect(() => {
		return () => {
			setShouldDoInitialFetch(true);
			setShowingInitialResults(true);
		};
	}, [setShouldDoInitialFetch, setShowingInitialResults]);

	useEffect(() => {
		if (shouldFetchInstances && itemsOfOrganisation && !userGroupId && !type) {
			onFetchItemInstancesList(itemToFetchInstancesOf, itemAccess);
			setFetchedNewInstances(true);
			setShouldFetchInstances(false);
		} else if (shouldFetchInstances && isInteger(userGroupId)) {
			onUserGroupItemInstances(userGroupId, itemToFetchInstancesOf);
			setFetchedNewInstances(true);
			setShouldFetchInstances(false);
		} else if (
			(shouldFetchInstances && type === 'supplier') ||
			(shouldFetchInstances && type === 'consumer')
		) {
			onFetchPartnerItemInstances(partnerId, itemToFetchInstancesOf);
			setFetchedNewInstances(true);
			setShouldFetchInstances(false);
		} else if (shouldFetchInstances && itemSharedWithMe) {
			onFetchPartnerItemInstances(organisationId, itemToFetchInstancesOf);
			setFetchedNewInstances(true);
			setShouldFetchInstances(false);
		}
	}, [shouldFetchInstances, itemToFetchInstancesOf]);

	/* * * * * * * * * * * * * * *
	 * LOCALLY STORE/UPDATE DATA *
	 * * * * * * * * * * * * * * */
	useEffect(() => {
		if (fetchedNewInstances && instancesReady) {
			dispatchItems({ type: 'addInstances', instances: instancesData, id: itemToFetchInstancesOf });
		}
	}, [fetchedNewInstances, instancesReady]);

	useEffect(() => {
		if (itemsReady && fetchedNewItems) {
			dispatchItems({ type: 'empty' });
			dispatchItems({ type: 'add', items: itemsData.results });
			setFetchedNewItems(false);
			setUpdatedItems(true);
		}
	}, [itemsReady, itemsData, fetchedNewItems]);

	useEffect(() => {
		if (updatedItems) {
			if (!isEmptyArray(selectedItems)) {
				items.map((item) => {
					if (selectedItems.map((sItem) => sItem.item.id).indexOf(item.id) !== -1) {
						const selectedItem =
							selectedItems[selectedItems.map((sItem) => sItem.item.id).indexOf(item.id)];

						const selectedInstanceIds = selectedItem.selectedInstances.map(
							(sInstance) => sInstance.id,
						);
						dispatchItems({
							type: 'addInstanceIds',
							id: item.id,
							selectedInstances: selectedInstanceIds,
						});

						if (
							selectedItem.selectedInstances.length === 0 ||
							selectedItem.selectedInstances.length === selectedItem.item.totalItemInstances
						) {
							dispatchItems({ type: 'toggleSelectAll', id: item.id });
						}
					}
				});
			}
			setUpdatedItems(false);
		}
	}, [updatedItems, items, selectedItems]);

	const handleFetchInstances = (item) => {
		setItemToFetchInstancesOf(item.id);
		setShouldFetchInstances(true);
	};

	const handleToggleShowInstances = (item) =>
		dispatchItems({ type: 'toggleShowingInstances', id: item.id });

	const handleAddSelection = (item = null, instance = null) => {
		const selectedItemIds = selectedItems.map((item) => item.item.id);
		const itemIndex = selectedItemIds.indexOf(item.id);

		if (!isNull(item) && isNull(instance)) {
			// selectAll
			if (itemIndex === -1) {
				setSelectedItems(
					selectedItems.concat([
						{ item, selectedInstances: isFullArray(item.instances) ? item.instances : [] },
					]),
				);
			} else {
				setSelectedItems(
					selectedItems.map((selectedItem) => ({
						...selectedItem,
						selectedInstances:
							selectedItem.item.id === item.id ?
								isFullArray(item.instances) ? item.instances
								:	[]
							:	selectedItem.selectedInstances,
					})),
				);
			}
		} else {
			//instance adding
			if (selectedItemIds.indexOf(item.id) === -1) {
				setSelectedItems(selectedItems.concat([{ item, selectedInstances: [instance] }]));
			} else {
				setSelectedItems(
					selectedItems.map((selectedItem) => ({
						...selectedItem,
						selectedInstances:
							selectedItem.item.id === item.id ?
								selectedItem.selectedInstances.concat([instance])
							:	selectedItem.selectedInstances,
					})),
				);
			}
		}
	};

	const handleRemoveSelection = (item = null, instance = null) => {
		const selectedItemIds = selectedItems.map((selectedItem) => selectedItem.item.id);
		const itemIndex = selectedItemIds.indexOf(item.id);
		const selectedItemInstanceIds =
			selectedItems[itemIndex].selectedInstances ?
				selectedItems[itemIndex].selectedInstances.map(
					(selectedItemInstance) => selectedItemInstance.id,
				)
			:	[];
		if (
			(!isNull(item) && isNull(instance)) ||
			(!isNull(instance) && selectedItemInstanceIds.length === 1)
		) {
			//removing whole item from selection
			setSelectedItems(
				Array(0).concat(selectedItems.slice(0, itemIndex), selectedItems.slice(itemIndex + 1)),
			);
		} else {
			//remove instance
			setSelectedItems(
				selectedItems.map((selectedItem) => ({
					...selectedItem,
					selectedInstances:
						selectedItem.item.id === item.id ?
							isEmptyArray(selectedItem.selectedInstances) ?
								item.instances.filter((newInstance) => newInstance.id !== instance.id)
							:	Array(0).concat(
									selectedItem.selectedInstances.slice(
										0,
										selectedItemInstanceIds.indexOf(instance.id),
									),
									selectedItem.selectedInstances.slice(
										selectedItemInstanceIds.indexOf(instance.id) + 1,
									),
								)
						:	selectedItem.selectedInstances,
				})),
			);
		}
	};

	const handleAddItem = (item = null, instance = null) => {
		if (!isNull(item) && isNull(instance)) {
			dispatchItems({ type: 'toggleSelectAll', id: item.id });
			dispatchItems({ type: 'clearInstanceIds', id: item.id });
			//add all instances if they are there
			if (item.instances.length > 0) {
				dispatchItems({
					type: 'addInstanceIds',
					id: item.id,
					selectedInstances: item.instances.map((newInstance) => newInstance.id),
				});
			} else {
				handleFetchInstances(item);
			}
		} else {
			//add specific instance
			dispatchItems({ type: 'addInstanceIds', id: item.id, selectedInstances: [instance.id] });
		}
		handleAddSelection(item, instance);
	};

	const handleRemoveItem = (item = null, instance = null) => {
		if (!isNull(item) && isNull(instance)) {
			dispatchItems({ type: 'setSelectAllFalse', id: item.id });
			dispatchItems({ type: 'clearInstanceIds', id: item.id });
		} else {
			dispatchItems({ type: 'setSelectAllFalse', id: item.id });
			dispatchItems({ type: 'removeInstanceIds', id: item.id, selectedInstances: [instance.id] });
		}
		handleRemoveSelection(item, instance);
	};

	const selectionEvents = {
		onAdd: (item = null, instance = null) => handleAddItem(item, instance),
		onRemove: (item = null, instance = null) => handleRemoveItem(item, instance),
		onShowInstances: (item) => {
			handleToggleShowInstances(item);
			if (isEmptyArray(item.instances)) {
				setItemToFetchInstancesOf(item.id);
				setShouldFetchInstances(true);
			}
		},
	};

	useEffect(() => {
		if (isSuperAdmin(auth.user?.profile.role)) {
			onFetchOrganisations();
		}
		if (!categoriesLoading && isNull(categoriesData)) {
			onFetchCategories();
		}
	}, [categoriesData]);
	const refNameCurrent = isObject(refName.current) && !itemsLoading;

	useEffect(() => {
		if (refNameCurrent && isObject(refName.current)) {
			setWidthLocation(refName.current.offsetWidth);
		}
	}, [!refNameCurrent]);

	const deleteSingleItem = (data) => {
		setItemName(data.name);
		setItemId(data.id);
		setDialogItem(true);
	};

	const deleteSingleInstances = (data) => {
		setInstanceName(data.name);
		setDeleteInstanceData(data);
		setDialogItemInstance(true);
	};

	const unassignItem = (data) => {
		setItemName(data.name);
		setItemId(data.id);
		setDialogUnassignItem(true);
	};

	const unassignItemInstance = (data) => {
		setInstanceName(data.name);
		setCancelItemsData(data);
		setDialogUnassignItemInstance(true);
	};

	const handleCancelSharingItem = (data) => {
		setItemName(data.name);
		setItemId(data.id);
		setDialogCancelSharingItem(true);
	};

	const cancelSharingInstance = (data) => {
		setInstanceName(data.name);
		setCancelItemsData(data);
		setDialogCancelSharingInstance(true);
	};

	const handleSharingUnshareItem = (data, shareType) => {
		if (shareType === 'unshare') {
			setOpenUnshareDialog(true);
		} else if (shareType === 'share') {
			setOpenPublicInstanceDialog(true);
		}
		setItemInstance(data);
		setItemId(data.itemReference.id);
	};

	const createMenuItems = (data) => [
		...(isInteger(userGroupId) ?
			[
				{
					icon: <RemoveCircleOutlineIcon className={classes.buttonIcon} color='error' />,
					text: t('ui.button.inline.unassign'),
					action: () => unassignItem(data),
					isRed: true,
				},
			]
		:	[]),
		...(itemsOfOrganisation ?
			[
				{
					icon: <DeleteIcon />,
					text: t('ui.button.inline.delete'),
					action: () => deleteSingleItem(data),
					isRed: true,
				},
			]
		:	[]),
		...(itemSharedWithMe ?
			[
				{
					icon: <RemoveCircleOutlineIcon className={classes.buttonIcon} color='error' />,
					text: t('ui.button.inline.cancel'),
					action: () => handleCancelSharingItem(data),
					isRed: true,
				},
			]
		:	[]),
	];

	const createMenuItemsInstances = (data) => [
		...(isInteger(userGroupId) ?
			[
				{
					icon: <RemoveCircleOutlineIcon className={classes.buttonIcon} color='error' />,
					text: t('ui.button.inline.unassign'),
					action: () => unassignItemInstance(data),
				},
			]
		:	[]),
		...(itemsOfOrganisation ?
			[
				{
					icon: <DeleteIcon />,
					text: t('ui.button.inline.delete'),
					action: () => deleteSingleInstances(data),
					isRed: true,
				},
				...(isSupplierSubscription && data.isPublic ?
					[
						{
							icon: <ClearIcon />,
							text: t('view.itemmanagement.button.inline.unsharepublicly'),
							action: () => handleSharingUnshareItem(data, 'unshare'),
						},
					]
				:	[]),
				...(isSupplierSubscription && !data.isPublic ?
					[
						{
							icon: <ShareIcon />,
							text: t('view.itemmanagement.button.inline.sharepublicly'),
							action: () => handleSharingUnshareItem(data, 'share'),
						},
					]
				:	[]),
			]
		:	[]),
		...(itemSharedWithMe ?
			[
				{
					icon: <RemoveCircleOutlineIcon className={classes.buttonIcon} color='error' />,
					text: t('ui.button.inline.cancel'),
					action: () => cancelSharingInstance(data),
				},
			]
		:	[]),
		...(itemsOfOrganisation && data.isAvailable ?
			[
				{
					icon: <BlockIcon />,
					text: t('ui.button.inline.makeunavailable'),
					isRed: true,
					action: () => handleClickAvailability(data, availability.UNAVAILABLE),
				},
			]
		:	[]),
		...(itemsOfOrganisation && (!data.isAvailable || data.restrictAvailable) ?
			[
				{
					icon: <CheckCircleOutlinedIcon />,
					text: t('ui.button.inline.makeavailable'),
					disabled: !isSuperAdmin(auth.user?.profile.role) && data.restrictAvailable,
					action: () =>
						handleClickAvailability(
							data,
							!data.isAvailable && data.restrictAvailable && isSuperAdmin(auth.user?.profile.role) ?
								availability.BOOKABLE
							:	availability.AVAILABLE,
						),
				},
			]
		:	[]),
		...(isSuperAdmin(auth.user?.profile.role) && !data.restrictAvailable ?
			[
				{
					icon: <ErrorOutlineOutlinedIcon />,
					text: t('ui.button.unbookable'),
					isRed: true,
					action: () => handleClickAvailability(data, availability.UNBOOKABLE),
				},
			]
		:	[]),
	];

	const handleSharingInstances = (data, typeShare) => {
		if (typeShare === 'share') {
			setOpenMassShareDialog(true);
		} else if (typeShare === 'cancel') {
			setOpenMassUnshareDialog(true);
		}
		setMassShare(data);
		setItemId(data[0].item.id);
	};

	const handleUnassignItem = (data) => {
		setDeleteItemsData(data);
		setDialogUnassignMany(true);
	};

	const handleCancelSharingMany = (data) => {
		setCancelItemsData(data);
		setDialogCancelSharingMany(true);
	};

	const editActionButtons = [
		...(isSupplierSubscription && isPrivate ?
			[
				{
					icon: <ShareIcon className={classes.buttonIcon} />,
					text: t('view.itemmanagement.button.inline.sharepublicly'),
					action: () => handleSharingInstances(selectedItems, 'share'),
					variant: 'inline-default',
				},
				{
					icon: <ClearIcon className={classes.buttonIcon} color='error' />,
					text: t('view.itemmanagement.button.inline.unsharepublicly'),
					action: () => handleSharingInstances(selectedItems, 'cancel'),
					variant: 'inline-delete',
				},
			]
		:	[]),
		...(isInteger(userGroupId) ?
			[
				{
					icon: <RemoveCircleOutlineIcon className={classes.buttonIcon} color='error' />,
					text: t('view.itemmanagement.button.inline.removefromusergroup'),
					action: () => handleUnassignItem(selectedItems),
					variant: 'inline-delete',
				},
			]
		:	[
				...((
					!partnerId && !isSuperAdmin(auth.user?.profile.role) && itemsOfOrganisation && isPrivate
				) ?
					[
						{
							icon: <DeviceHubIcon className={classes.buttonIcon} />,
							text: t('view.itemmanagement.button.inline.sharewithpartner'),
							action: () =>
								navigate('/item-management/items/assign/partner', {
									state: { selectedItems },
								}),
							variant: 'inline-default',
						},
					]
				:	[]),
				...(!(itemSharedWithMe && type === 'supplier') ?
					[
						{
							icon: <AddCircleIcon className={classes.buttonIcon} />,
							text: t('view.itemmanagement.button.inline.assigntousergroup'),
							action: () =>
								navigate('/item-management/items/assign/user-group', {
									state: { selectedItems },
								}),
							variant: 'inline-default',
						},
					]
				:	[]),
			]),
		...(itemSharedWithMe && type === 'supplier' ?
			[
				{
					icon: <CancelIcon className={classes.buttonIcon} color='error' />,
					text: t('view.itemmanagement.button.inline.cancelsharingwithpartner'),
					action: () => handleCancelSharingMany(selectedItems),
					variant: 'inline-delete',
				},
			]
		:	[]),
	];

	const handleChangeCategoryFilterSelect = (event) => {
		const value = event.target.value;
		if (value === itemCategoryFilter.value) {
			return;
		}
		itemCategoryFilter.setValue(value);
		setShouldFilter(true);
	};

	const handleBookingTypeFilterSelect = (event) => {
		const value = event.target.value;
		if (value === itemBookingTypeFilter.value) {
			return;
		}
		itemBookingTypeFilter.setValue(value);
		setShouldFilter(true);
	};

	const handleChangeOrganisationFilterSelect = (item) => {
		if (item.id === itemOrganisationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			itemOrganisationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			itemOrganisationFilter.setValueName('');
		}
		itemOrganisationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const handleChangeHubFilterSelect = (item) => {
		if (item.id === itemLocationFilter.valueId) {
			return;
		}
		if (isInteger(item.id)) {
			itemLocationFilter.setValueName(item.name);
		} else if (isEmptyString(item.id)) {
			itemLocationFilter.setValueName('');
		}
		itemLocationFilter.setValueId(item.id);
		setShouldFilter(true);
	};

	const mainFilters = [
		...(isSuperAdmin(auth.user?.profile.role) ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeOrganisationFilterSelect,
						searchHandle: itemOrganisationFilter.setValueName,
						filter: 'verified',
					},
					value: itemOrganisationFilter.valueName,
					dataList: organisationsList,
					placeholder: t('ui.filter.organisations.all'),
					onFetchData: onFetchOrganisations,
					listType: 'organisations',
					defaultListItem: { id: '', name: t('ui.filter.organisations.all') },
				},
			]
		:	[]),
		...(itemAccess === 'private' ?
			[
				{
					isSelectWithLazyLoading: true,
					events: {
						onChange: handleChangeHubFilterSelect,
						searchHandle: itemLocationFilter.setValueName,
						filter: 'none',
					},
					value: itemLocationFilter.valueName,
					dataList: hubsList,
					placeholder: t('ui.filter.locations.all'),
					onFetchData: onFetchHubs,
					listType: 'hubs',
					defaultListItem: { id: '', name: t('ui.filter.locations.all') },
				},
			]
		:	[]),
		{
			events: { onChange: (e) => handleBookingTypeFilterSelect(e) },
			value: itemBookingTypeFilter.value,
			selectOptions: [
				{
					value: 'none',
					label: t('views.assets.items.mainFilters.none'),
				},
			].concat(
				bookingTypes.map((bookingType) => ({
					value: bookingType,
					label: t(`views.assets.items.mainFilters.${bookingType}`),
				})),
			),
		},
		{
			events: { onChange: (e) => handleChangeCategoryFilterSelect(e) },
			value: itemCategoryFilter.value,
			selectOptions: [
				{
					value: 'all',
					label: t('views.assets.items.mainFilters.category.all'),
				},
			].concat(
				categoriesReady ?
					categoriesData.map((category) => ({
						value: category.id.toString(),
						label: category.name,
					}))
				:	[],
			),
		},
	];

	const handleClearFilters = () => {
		itemCategoryFilter.setValue('all');
		itemBookingTypeFilter.setValue('none');
		itemOrganisationFilter.setValueName('');
		itemOrganisationFilter.setValueId('all');
		itemLocationFilter.setValueName('');
		itemLocationFilter.setValueId('all');
		search.events.onClear();
		pagination.resetPagination();
	};

	const clearFilters = {
		clear:
			pagination.pageNumber !== 1 ||
			pagination.pageSize !== 10 ||
			itemCategoryFilter.value !== 'all' ||
			itemBookingTypeFilter.value !== 'none' ||
			itemOrganisationFilter.valueId !== 'all' ||
			itemLocationFilter.valueId !== 'all',
		btnText: 'ui.button.inline.clearfilters',
		action: handleClearFilters,
	};

	const handleRequestSort = (property) => {
		const isDesc = sortingCategory === property && order === 'desc';
		setOrder(isDesc ? 'asc' : 'desc');
		setOrderDescending(isDesc);
		setSortingCategory(property);
		setShouldSort(true);
	};

	const handleCancelDialog = () => {
		if (dialogItem) {
			setDialogItem(false);
		} else if (dialogItemInstance) {
			setDialogItemInstance(false);
		} else if (dialogUnassignItem) {
			setDialogUnassignItem(false);
		} else if (dialogUnassignItemInstance) {
			setDialogUnassignItemInstance(false);
		} else if (dialogUnassignMany) {
			setDialogUnassignMany(false);
		} else if (dialogCancelSharingItem) {
			setDialogCancelSharingItem(false);
		} else if (dialogCancelSharingInstance) {
			setDialogCancelSharingInstance(false);
		} else if (dialogCancelSharingMany) {
			setDialogCancelSharingMany(false);
		}
	};

	const handleConfirmDialog = () => {
		if (dialogItem) {
			onDeleteItem(itemId);
			setDialogItem(false);
		} else if (dialogItemInstance) {
			onDeleteItemInstance(deleteInstanceData.itemReference.id, deleteInstanceData.id);
			setDialogItemInstance(false);
		} else if (dialogUnassignItem) {
			onUnassignUserGroupItem(userGroupId, [{ itemId: itemId, instanceIds: [] }]);
			setDialogUnassignItem(false);
		} else if (dialogUnassignItemInstance) {
			onUnassignUserGroupItem(userGroupId, [
				{ itemId: deleteInstanceData.itemReference.id, instanceIds: [deleteInstanceData.id] },
			]);
			setDialogUnassignItemInstance(false);
		} else if (dialogUnassignMany) {
			const body = deleteItemsData.map((current) => ({
				itemId: current.item.id,
				instanceIds: current.selectedInstances.map((instance) => instance.id),
			}));
			setDialogUnassignMany(false);
			onUnassignUserGroupItem(userGroupId, body);
		} else if (dialogCancelSharingItem) {
			onUnassignPartnershipsItems(partnerId, [{ itemId: itemId, instanceIds: [] }]);
			setDialogCancelSharingItem(false);
		} else if (dialogCancelSharingInstance) {
			onUnassignPartnershipsItems(partnerId, [
				{ itemId: cancelItemsData.itemReference.id, instanceIds: [cancelItemsData.id] },
			]);
			setDialogCancelSharingInstance(false);
		} else if (dialogCancelSharingMany) {
			const body = cancelItemsData.map((current) => ({
				itemId: current.item.id,
				instanceIds: current.selectedInstances.map((instance) => instance.id),
			}));
			onUnassignPartnershipsItems(partnerId, body);
			setDialogCancelSharingMany(false);
		}
	};

	const handleCancelAvailabilityDialog = () => setOpenAvailabilityDialog(false);

	const handleClickAvailability = (data, type) => {
		setItemId(data.itemReference.id);
		setInstanceId(data.id);
		setAvailabilityType(type);
		setOpenAvailabilityDialog(true);
	};

	const handleConfirmChangeAvailability = () => {
		if (availabilityType === availability.UNAVAILABLE) {
			onUpdateInstanceAvailability(itemId, instanceId, true);
			setUpdatedInstanceIndex(instanceId);
		} else if (availabilityType === availability.AVAILABLE) {
			setUpdatedInstanceIndex(instanceId);
			onUpdateInstanceAvailability(itemId, instanceId, false);
		} else if (availabilityType === availability.UNBOOKABLE) {
			onInstanceRestriction(itemId, instanceId, false);
		} else if (availabilityType === availability.BOOKABLE) {
			onInstanceRestriction(itemId, instanceId, true);
		}
	};

	const handleAddItme = () => {
		onFetchBookingTypes();
		navigate('/item-management/items/add');
	};

	const extraButtons = [
		...(!isUndefined(userGroupReady) && !userGroupId ?
			[
				{
					text: t('view.usermanagement.usergroupdetails.button.contained.assignitems'),
					onClick: () =>
						navigate('/item-management/items/assign/user-group', {
							state: userGroupReady ? { selectedUserGroup: userGroupData } : {},
						}),
					variant: 'contained-primary',
				},
			]
		:	[]),
		...(itemsOfOrganisation && itemAccess === 'private' ?
			[
				{
					text: t('ui.button.contained.additem'),
					onClick: () => handleAddItme(),
					variant: 'contained-primary',
				},
			]
		:	[]),
	];

	const createCollapseData = (instances) => {
		if (instances.length > 0) {
			return instances.map((instance) => ({
				innerId: instance.id,
				id: instance.itemReference.id,
				instance: instance,
				...{
					content: (
						<div className={classes.instanceContent}>
							<Box width={`${widthLocation}px`}>
								<IconInfoLabel
									content={
										<Link
											className={clsx({
												[classes.tableLink]: true,
												[classes.notAvailable]:
													!isUndefined(instance.isAvailable) ?
														!instance.isAvailable || instance.restrictAvailable
													:	false,
											})}
											color='inherit'
											component={RouterLink}
											to={`/item-management/${instance.itemReference.id}/instance/${instance.id}/summary`}
											state={{ from: location.pathname }}
										>
											{instance.name}
										</Link>
									}
									icon={
										<img
											alt='warning icon'
											className={classes.icon}
											height='16'
											src={WarningIcon}
											width='16'
										/>
									}
									infoTitle={t('ui.tooltip.description')}
									showIcon={instance.restrictAvailable}
								/>
							</Box>
							<Box pl={4}>{handleHubReference(instance.hubReference)}</Box>
						</div>
					),
					align: 'left',
					button:
						!itemSharedWithMe && !itemsSharedBy && isPrivate ?
							<Box alignItems='center' display='flex'>
								<Box pr={3}>
									<Label type={instance.isPublic ? 'success' : 'default'}>
										{instance.isPublic ? t('ui.public.label') : t('ui.private.label')}
									</Label>
								</Box>
								<GenericMoreButton menuItems={createMenuItemsInstances(instance)} />
							</Box>
						:	null,
				},
			}));
		} else {
			return Array(5)
				.fill()
				.map(() => ({ loading: true }));
		}
	};

	const tableHeader = [
		{ name: 'qty', content: 'QTY' },
		{ name: 'name', content: t('ui.label.item'), hasSorting: true },
		{ name: 'location', content: t('ui.label.location') },
		{ name: 'bookingType', content: t('views.addItem.bookingType.title') },
		{ name: 'category', content: t('ui.label.category') },
		...(isPublic || isSuperAdmin(auth.user?.profile.role) || itemSharedWithMe ?
			[{ name: 'organisation', content: t('ui.label.organisation') }]
		:	[]),
		...(!isPublic && !isSuperAdmin(auth.user?.profile.role) && itemsOfOrganisation ?
			[{ name: 'sharedWith', content: t('views.tableResults.items.sharedWith') }]
		:	[]),
		...(!isPublic && (itemsOfOrganisation || itemSharedWithMe || userGroupId) ?
			[{ name: 'status', content: t('ui.label.status') }]
		:	[]),
	];

	const tableBody =
		itemsReady && items && !isEmptyArray(items) ?
			items.map((item) => ({
				id: item.id,
				item: { ...item },
				isExpandable: item.totalItemInstances > 0,
				isExpanded: item.isShowingInstances,
				isSelectable: tab !== 'publicItems',
				hasSelectAllSelected: item.hasSelectAllSelected,
				instances: createCollapseData(item.instances),
				results: [
					{
						content:
							userGroupId || type === 'supplier' ?
								`${item.numberOfAssignedInstances}/${item.totalItemInstances}`
							: organisationId || type === 'consumer' ? item.numberOfAssignedInstances
							: item.totalItemInstances,
					},
					{
						content:
							isPublic && !itemSharedWithMe && !isSuperAdmin(auth.user?.profile.role) ?
								item.name
							:	<div ref={refName}>
									<Link
										className={classes.tableLink}
										color='inherit'
										component={RouterLink}
										to={`/item-management/items/${item.id}/summary`}
										state={{ from: location.pathname }}
									>
										{item.name}
									</Link>
								</div>,
					},
					{ content: handleHubReference(item.hubReference) },
					{ content: handleReservationType(item) },
					{ content: item.categoryReference.name },
					...(isSuperAdmin(auth.user?.profile.role) ?
						[
							{
								content: (
									<Link
										className={classes.tableLink}
										color='inherit'
										component={RouterLink}
										to={`/organisations/${item.hubReference.organisationReference.id}/summary`}
										state={{ from: location.pathname }}
									>
										{item.hubReference.organisationReference.name}
									</Link>
								),
							},
						]
					: itemSharedWithMe || isPublic ?
						[{ content: item.hubReference.organisationReference.name }]
					:	[]),
					...(!isPublic && !isSuperAdmin(auth.user?.profile.role) && itemsOfOrganisation ?
						[
							{
								content: (
									<Box display='flex'>
										{item.consumers.length > 1 ?
											<MyTooltip
												arrow
												placement='top'
												title={item.consumers.map((consum, index) => (
													<Box key={index} pb={1} pt={1}>
														<Typography className={classes.tableText}>{consum}</Typography>
													</Box>
												))}
											>
												<Typography className={classes.detailTitle}>
													{item.consumers.length === 1 ?
														item.consumers[0]
													: item.consumers.length > 1 ?
														`${item.consumers.length} ${t('views.financeHistory.organisationalBilling.header')}`
													: item.consumers.length === 0 ?
														'-'
													:	null}
												</Typography>
											</MyTooltip>
										:	item.consumers[0]}
									</Box>
								),
							},
						]
					:	[]),
					...(!isPublic && (itemsOfOrganisation || itemSharedWithMe || userGroupId) ?
						[
							{
								content: (
									<Box alignItems='center' display='flex' justifyContent='space-between'>
										{!itemsOfOrganisation ?
											'-'
										:	<Label type={item.isPublished ? 'success' : 'error'}>
												{item.isPublished ? t('ui.published') : t('ui.notPublished')}
											</Label>
										}
										{!isPublic ?
											<Box display='flex'>
												<GenericMoreButton menuItems={createMenuItems(item)} />
											</Box>
										:	null}
									</Box>
								),
							},
						]
					:	[]),
				],
			}))
		:	Array(5)
				.fill(Array(tableHeader.length).fill())
				.map((arr) => ({ results: arr.map(() => ({ loading: true })) }));

	const handleEmptySate = () => {
		if (!partnerId) {
			if (isInteger(userGroupId)) {
				return navigate('/item-management/items/assign/user-group', {
					state: userGroupReady ? { selectedUserGroup: userGroupData } : {},
				});
			} else if (itemsOfOrganisation) {
				return navigate('/item-management/items/add');
			}
		}
	};

	/* * * * * * * *
	 * EMPTY STATE *
	 * * * * * * * */
	const itemsListEmpty =
		isObject(itemsData) && !itemsLoading && isEmptyArray(itemsData.results) ?
			showingInitialResults ?
				<EmptyState
					callToActionText={
						userGroupId ?
							t('view.usermanagement.usergroupdetails.button.contained.assignitems')
						:	t('ui.button.contained.additem')
					}
					handleAction={isInteger(userGroupId) ? null : handleEmptySate}
					image={'booking'}
					subTitle={t('views.assets.items.empty.description.zeroInSystem')}
					title={t('views.assets.items.empty.title')}
				/>
			:	<EmptyState
					callToActionText={
						userGroupId ?
							t('view.usermanagement.usergroupdetails.button.contained.assignitems')
						:	t('ui.button.contained.additem')
					}
					handleAction={isInteger(userGroupId) ? null : handleEmptySate}
					image={'booking'}
					subTitle={t('views.assets.items.empty.description.zeroMatching')}
					title={t('views.assets.items.empty.title')}
				/>
		: itemsError && !itemsLoading ?
			<EmptyState
				image={'booking'}
				subTitle={t('views.assets.items.empty.description.zeroInSystem')}
				title={t('views.assets.items.empty.title')}
			/>
		:	null;

	const dialogDetails = [
		{
			label: 'deleteItem',
			open: dialogItem,
			title: t('ui.delete'),
			loading: deleteLoading && !deleteDone,
			description: `${t('ui.deleted.dialogDescription')} ${itemName} ${t('ui.permanently')}`,
		},
		{
			label: 'deleteItemInstance',
			open: dialogItemInstance,
			title: t('ui.delete'),
			loading: deleteInstanceLoading && !deleteInstanceDone,
			description: `${t('ui.deleted.dialogDescription')} ${instanceNameValue} ${t('ui.permanently')}`,
		},
		{
			label: 'unassignItem',
			open: dialogUnassignItem,
			title: t('ui.unassign'),
			loading: unassignOrCancelItemLoading && !unassignDone,
			description: `${t('ui.onConfirmation')}, ${itemName} ${t('ui.unassignedFrom')} ${isObject(userGroupData) && userGroupData.name}`,
		},
		{
			label: 'unassignItemInstance',
			open: dialogUnassignItemInstance,
			title: t('ui.unassign'),
			loading: unassignOrCancelItemLoading && !unassignDone,
			description: `${t('ui.onConfirmation')}, ${instanceNameValue} ${t('ui.unassignedFrom')} ${isObject(userGroupData) && userGroupData.name}`,
		},
		{
			label: 'dialogUnassignMany',
			open: dialogUnassignMany,
			title: t('ui.unassign'),
			loading: unassignOrCancelItemLoading && !unassignDone,
			description: `${t('views.tableresults.items.unassignMultiple')}`,
		},
		{
			label: 'dialogCancelSharing',
			open: dialogCancelSharingItem,
			title: t('ui.cancel'),
			loading: unassignOrCancelItemLoading && !unassignDone,
			description: t('view.partnerships.itemsSharedWithPartner.cancelSharingWithPartners'),
		},
		{
			label: 'dialogCancelSharingInstance',
			open: dialogCancelSharingInstance,
			title: t('ui.cancel'),
			loading: unassignOrCancelItemLoading && !unassignDone,
			description: t('view.partnerships.itemsSharedWithPartner.cancelSharingWithPartners'),
		},
		{
			label: 'dialogCancelSharingMany',
			open: dialogCancelSharingMany,
			title: t('ui.cancel'),
			loading: unassignOrCancelItemLoading && !unassignDone,
			description: t('view.partnerships.itemsSharedWithPartner.cancelSharingWithPartners'),
		},
	];

	const openAlertDialog =
		dialogItem ||
		dialogItemInstance ||
		dialogUnassignItem ||
		dialogUnassignItemInstance ||
		dialogUnassignMany ||
		dialogCancelSharingItem ||
		dialogCancelSharingInstance ||
		dialogCancelSharingMany;

	const openShareDialog =
		openPublicInstanceDialog || openUnshareDialog || openMassShareDialog || openMassUnshareDialog;

	const searchBarProps = {
		clearFilters,
		extraButtons,
		hideMoreFilters: true,
		hasMainFilter: true,
		mainFilters,
		hasExtraButtons: isFullArray(extraButtons),
		placeholder: t('views.assets.items.placeholder.search'),
		searchEvents: search.events,
		searchValue: search.value,
	};

	const collapsibleTableProps = {
		dataList: itemsList,
		editActionButtons,
		handleChangeRowsPerPage: (event) => pagination.pageSizeChange(event.target.value),
		handlePageChange: pagination.pageNumberChange,
		handleSorting: handleRequestSort,
		hasSelectionEnabled: true,
		order,
		orderBy: sortingCategory,
		page: pagination.pageNumber,
		rowsPerPage: pagination.pageSize,
		selection: getSelectedInstances(selectedItems),
		selectionEvents,
		tableBody,
		tableHeader,
		title: t('views.assets.items.table.title'),
		total: isObject(itemsData) ? itemsData.total : 0,
	};

	const shareDialogProps = {
		itemInstance,
		massSelection: massShare,
		openMassShareDialog,
		openMassUnshareDialog,
		openPublicInstanceDialog,
		openUnshareDialog,
		resetPublickShareDialog,
		setOpenMassShareDialog,
		setOpenMassUnshareDialog,
		setOpenPublicInstanceDialog,
		setOpenUnshareDialog,
		setUpdatedInstanceIndex,
	};

	const availabilityLoading = updatedInstanceLoading || instanceRestrictionLoading;
	const actionDialogProps = {
		title: t(`views.itemDetail.instances.dialog.${availabilityType}.title`),
		loading: availabilityLoading,
		handleClose: handleCancelAvailabilityDialog,
		open: openAvailabilityDialog,
		actionButtonProps: {
			text:
				availabilityLoading ?
					<CircularProgress disableShrink size={24} />
				:	t(`views.itemDetail.instances.dialog.${availabilityType}.confirm`),
			action: () => handleConfirmChangeAvailability(),
		},
	};

	/* * * * * *
	 * RENDER  *
	 * * * * * */
	return (
		<div className={classes.root}>
			<SearchBar {...searchBarProps} />
			{itemsListEmpty ? itemsListEmpty : <CollapsibleTable {...collapsibleTableProps} />}
			{openAlertDialog ?
				dialogDetails.map((item) => (
					<AlertDialog
						dialogDescription={item.description}
						dialogTitle={item.title}
						handleClose={handleCancelDialog}
						handleConfirm={handleConfirmDialog}
						key={item.label}
						loading={item.loading}
						open={item.open}
					/>
				))
			:	null}
			{openAvailabilityDialog ?
				<ActionDialog {...actionDialogProps}>
					{' '}
					{t(`views.itemDetail.instances.dialog.${availabilityType}.description`)}
				</ActionDialog>
			:	null}
			{openShareDialog ?
				<ShareDialog {...shareDialogProps} />
			:	null}
		</div>
	);
};

Items.propTypes = {
	onFetchItems: PropTypes.func,
	itemUse: PropTypes.string,
	onSetFilter: PropTypes.func.isRequired,

	onFetchOrganisations: PropTypes.func,
	onFetchHubs: PropTypes.func,
	onFetchCategories: PropTypes.func,
	onDeleteItem: PropTypes.func,
	userGroupId: PropTypes.number,
	onFetchItemInstancesList: PropTypes.func.isRequired,
	userGroupData: PropTypes.object,
	userGroupReady: PropTypes.bool,
	onUserGroupItemInstances: PropTypes.func,
	onUnassignUserGroupItem: PropTypes.func,
	onFetchBookingTypes: PropTypes.func,
	onUpdateInstanceAvailability: PropTypes.func,
	onUserGroupItems: PropTypes.func,
	onUpdatePagedState: PropTypes.func,
	myItems: PropTypes.bool,
	itemSharedWithMe: PropTypes.bool,
	onFetchItemInstanceSharings: PropTypes.func,
	organisationId: PropTypes.number,
	partnerId: PropTypes.number,
	type: PropTypes.string,
	itemsOfOrganisation: PropTypes.bool,
	onFetchPartnerItemInstances: PropTypes.func,
	onFetchSharedItems: PropTypes.func,
	onUnassignPartnershipsItems: PropTypes.func,
	itemsSharedBy: PropTypes.bool,

	itemAccess: PropTypes.string,
	onResetState: PropTypes.func,
	onInstanceRestriction: PropTypes.func,
	onFetchOrganisation: PropTypes.func,
	onDeleteItemInstance: PropTypes.func,
	onResetStateCondition: PropTypes.func,
	itemsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	sharedItemsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	organisationsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	hubsList: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	categoriesList: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	deleteItem: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	itemInstances: PropTypes.shape({
		data: PropTypes.array,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	deleteItemInstance: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	unassignOrCancelItem: PropTypes.PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updatedInstanceAvailability: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updateInstance: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	updatePublicShare: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentUser: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	currentOrganisation: PropTypes.shape({
		data: PropTypes.object,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
	instanceRestriction: PropTypes.shape({
		success: PropTypes.bool,
		loading: PropTypes.bool,
		error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	}),
};

const mapStateToProps = (state) => {
	return {
		itemsList: state.paged.items,
		sharedItemsList: state.paged.items,
		itemInstances: state.list.itemInstancesList,
		filters: state.filters,
		organisationsList: state.paged.organisations,
		hubsList: state.paged.hubs,
		categoriesList: state.list.categories,
		updatedInstanceAvailability: state.details.updatedInstanceAvailability,
		deleteItem: state.condition.deleteItem,
		deleteItemInstance: state.condition.deleteItemInstance,
		unassignOrCancelItem: state.details.unassignOrCancelItem,

		updateInstance: state.details.patchInstance,
		updatePublicShare: state.details.updatePublicShare,
		currentUser: state.details.currentUser,
		currentOrganisation: state.details.organisation,
		instanceRestriction: state.condition.instanceRestriction,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchItems: (page, filters) => dispatch(actions.fetchItems(page, filters)),
		onFetchItemInstancesList: (itemId, itemAccess) =>
			dispatch(actions.fetchItemInstancesList(itemId, itemAccess)),
		onSetFilter: (identifier, value) => dispatch(actions.setFilter(identifier, value)),
		onFetchOrganisations: (page, filters, concat) =>
			dispatch(actions.fetchOrganisations(page, filters, concat)),
		onFetchHubs: (page, filters, concat) => dispatch(actions.fetchHubs(page, filters, concat)),
		onFetchCategories: () => dispatch(actions.fetchCategories()),
		onDeleteItem: (id) => dispatch(actions.deleteItem(id)),
		onDeleteItemInstance: (itemId, instanceId) =>
			dispatch(actions.deleteItemInstance(itemId, instanceId)),
		onUserGroupItemInstances: (userGroupId, itemId) =>
			dispatch(actions.userGroupItemInstances(userGroupId, itemId)),
		onUnassignUserGroupItem: (userGroupId, items) =>
			dispatch(actions.unassignUserGroupItem(userGroupId, items)),
		onUserGroupItems: (userGroupId, page, filters) =>
			dispatch(actions.userGroupItems(userGroupId, page, filters)),
		onUpdatePagedState: (identifier, data) => dispatch(actions.updatePagedState(identifier, data)),
		onFetchPartnerItemInstances: (partnerId, itemId) =>
			dispatch(actions.fetchPartnerItemInstances(partnerId, itemId)),
		onFetchSharedItems: (partnerId, page, filters) =>
			dispatch(actions.fetchSharedItems(partnerId, page, filters)),
		onUnassignPartnershipsItems: (partnerId, items) =>
			dispatch(actions.unassignPartnershipsItems(partnerId, items)),
		onUpdateInstanceAvailability: (itemId, instanceId, isAvailable) =>
			dispatch(actions.updateInstanceAvailability(itemId, instanceId, isAvailable)),
		onInstanceRestriction: (itemId, instanceId, isAvailable) =>
			dispatch(actions.instanceRestriction(itemId, instanceId, isAvailable)),
		onFetchOrganisation: (id) => dispatch(actions.fetchOrganisation(id)),
		onFetchBookingTypes: () => dispatch(actions.fetchBookingTypes()),
		onResetState: (identifier) => dispatch(actions.resetState(identifier)),
		onResetStateCondition: (state, value) => dispatch(actions.resetStateCondition(state, value)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
