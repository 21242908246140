import { useEffect, useRef, useState } from 'react';

import { AutocompleteInputChangeReason } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useSWRInfinite from 'swr/infinite';

import { AsyncAutocomplete, AsyncAutocompleteProps } from '~components';
import { IdReference, PagedResults } from '~interfaces';
import i18n from '~lib/i18n';

import NfcTag from '../../interfaces/nfcTag';
import NfcTagsService from '../../services/nfcTagsService';

const service = new NfcTagsService();

interface NfcAutocompleteProps extends Omit<AsyncAutocompleteProps<NfcTag>, 'options' | 'label'> {
	label?: string;
	count?: number;
	organisation?: IdReference;

	linkedFilter?: boolean;
}

const NfcAutocomplete = ({
	label = i18n.t('nfcTags'),
	count = 10,
	organisation,
	linkedFilter,
	...asyncAutocompleteProps
}: NfcAutocompleteProps) => {
	const { t } = useTranslation('general');

	const [searchQuery, setSearchQuery] = useState('');
	const [open, setOpen] = useState(false);

	const total = useRef<number>(null!);
	const page = useRef(1);

	const getKey = (i: number, previousData: PagedResults<NfcTag[]>) => {
		if (!open || (previousData && !previousData.hasMore)) {
			// reached the end
			return null;
		}

		page.current = i + 1;

		return [
			service.basePath,
			{
				page: page.current,
				pageSize: count,
				searchQuery: searchQuery || undefined,
				organisationId: organisation?.id,
				isLinked: linkedFilter,
			},
		] as const;
	};

	const { data, isLoading, isValidating, size, setSize, mutate } = useSWRInfinite(
		getKey,
		([_, args]) => service.getNfcTags(args),
		{
			revalidateFirstPage: false,
			onSuccess: (res) => (total.current = res[0].total),
		},
	);

	useEffect(() => {
		// Revalidate on open only if the data is not already loading
		if (open && !(isLoading && isValidating)) {
			mutate();
		}
	}, [open]);

	/**
	 * Just set the size of the pages
	 */
	const handleOverflow = () => {
		if ((size - 1) * count <= total.current) {
			setSize(size + 1);
		}
	};

	const handleInputChange = (
		e: React.SyntheticEvent<Element, Event>,
		value: string,
		reason: AutocompleteInputChangeReason,
	) => {
		setSearchQuery(value);

		asyncAutocompleteProps.onInputChange?.(e, value, reason);
	};

	const mappedData = data
		?.flatMap((el) => el.results)
		.map((el) => ({
			label: el.tagNumber,
			...el,
		}));

	return (
		<AsyncAutocomplete
			{...asyncAutocompleteProps}
			label={label}
			loading={isLoading || isValidating}
			noOptionsText={t('noResults')}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			options={data ? mappedData : []}
			onInputChange={handleInputChange}
			onOverflow={handleOverflow}
		/>
	);
};

export default NfcAutocomplete;
