import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';

import {
	DeleteOutlined as DeleteOutlinedIcon,
	Edit as EditIcon,
	ImportExport as ImportExportIcon,
} from '@mui/icons-material';
import { Box, Button, MenuItem, Link as MuiLink, Portal, Stack, useTheme } from '@mui/material';
import {
	GridCallbackDetails,
	GridColDef,
	GridFilterModel,
	GridPaginationModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { DataGrid, DataGridToolbar, GridActionsCellItem, Select } from '~components';
import { useAuthorize } from '~features/authentication';
import { Item } from '~features/items';
import { OrganisationAutocomplete } from '~features/organisations';
import { BaseReference } from '~interfaces';
import { ListMutationRefProps } from '~interfaces/refProps';
import { datetimeWithoutSecondsFormat, time24FormatWithoutSeconds } from '~utils/dateUtils';

import ServiceTicketStatusEnum from '../../enum/serviceTicketStatusEnum';
import TicketSeverityEnum from '../../enum/ticketSeverityEnum';
import ServiceTicket from '../../interfaces/serviceTicket';
import ServiceTicketsService from '../../services/serviceTicketsService';
import ServiceTicketStatusChip from '../chips/serviceTicketStatusChip';
import TicketSeverityChip from '../chips/ticketSeverityChip';
import { ItemCategoryEnum } from '~enums';

const toolbarIdString = 'topology-participant-toolbar';

const service = new ServiceTicketsService();

interface ServiceTicketsDataGridProps {
	ticketStatus?: 'closed' | 'unclosed',
	onStatusChange?: (value: BaseReference) => void;
}

const ServiceTicketsDataGrid = forwardRef<ListMutationRefProps, ServiceTicketsDataGridProps>(
	({ ticketStatus, onStatusChange }, ref) => {
		const { t } = useTranslation('general');
		const theme = useTheme();
		const location = useLocation();

		const { isSuperAdmin } = useAuthorize();

		const [fetchParameters, setFetchParameters] = useState<object | null>({
			status: ticketStatus ? [ticketStatus] : undefined
		});

		const columns = useMemo<GridColDef<ServiceTicket>[]>(
			() => [
				{
					field: 'id',
					headerName: t('ticketId'),
					width: 80,
					hideable: false,
					renderCell: (params) => (
						<MuiLink
							component={ReactRouterLink}
							to={params.value.toString()}
							state={{ from: location.pathname }}
						>
							{params.value}
						</MuiLink>
					),
				},
				{
					field: 'dateCreated',
					headername: t('dateCreated'),
					valueFormatter: (value: Date) => datetimeWithoutSecondsFormat.format(value),
					flex: 1,
					sortable: false
				},
				{
					field: 'itemGroup',
					headername: t('itemGroup'),
					valueGetter: (_, row) => row.item.itemGroup,
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1
				},
				{
					field: 'item',
					headername: t('item'),
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
					sortable: false
				},
				{
					field: 'category',
					headername: t('category'),
					valueGetter: (_, row) => row.item.category,
					flex: 1,
					sortable: false
				},
				{
					field: 'location',
					headername: t('location'),
					valueGetter: (_: Item, row) => row.item.hub,
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
					sortable: false
				},
				{
					field: 'provider',
					headername: t('provider'),
					valueFormatter: (value: BaseReference) => value?.label,
					flex: 1,
					sortable: false
				},
				{
					field: 'serviceOrganisation',
					headername: t('serviceCompany'),
					valueFormatter: (value?: BaseReference) => value?.label ?? '-',
					flex: 1,
					sortable: false
				},
				{
					field: 'defect',
					headername: t('defect'),
					valueFormatter: (value) => value.label,
					flex: 1
				},
				{
					field: 'severity',
					headername: t('severity'),
					valueGetter: (_, row) => row.defect.severity,
					renderCell: (params) => <TicketSeverityChip status={params.value} />,
					sortable: false,
					width: 80,
				},
				{
					field: 'status',
					headername: t('status'),
					renderCell: (params) => <ServiceTicketStatusChip status={params.value} />,
					width: 80,
				},
				{
					field: 'actions',
					type: 'actions',
					display: 'text',
					width: 50,
					resizable: false,
					align: 'right',
					hideable: false,
					getActions: (params) => [
						<GridActionsCellItem
							key={`${params.id}-changestatus`}
							icon={<ImportExportIcon />}
							label={t('changeStatus')}
							onClick={() =>
								onStatusChange?.({ id: params.id.toString(), label: params.row.label })
							}
							showInMenu
						/>,
					],
				},
			],
			[],
		);

		const { data, isLoading, isValidating, error, mutate } = useSWR(
			fetchParameters ? [service.basePath, fetchParameters] : null,
			([_, args]) => service.getServiceTickets(args),
			{
				keepPreviousData: true,
			},
		);
		const [totalCount, setTotalCount] = useState<number>(data?.total || 0);

		useEffect(() => {
			if (data?.total != null) {
				setTotalCount(data.total);
			}
		}, [data]);

		useImperativeHandle(
			ref,
			() => ({
				mutate: mutate,
			}),
			[mutate],
		);

		const handlePaginationChange = (value: GridPaginationModel, details: GridCallbackDetails) => {
			if (details.reason === 'setPaginationModel' && (isLoading || isValidating)) {
				service.abortCurrentRequest('pagination change');
			}

			setFetchParameters((prev) => ({
				...prev,
				page: value.page + 1,
				pageSize: value.pageSize,
			}));
		};

		const handleFilterModelChange = (model: GridFilterModel, details: GridCallbackDetails) => {
			const searchQuery =
				model.quickFilterValues && model.quickFilterValues.length > 0 ?
					model.quickFilterValues[0]
				:	undefined;
			const organisation =
				model.items.length > 0 && model.items[0].field === 'organisation' ?
					model.items[0].value
				:	undefined;

			setFetchParameters((prev) => ({
				...prev,
				organisationId: organisation?.id,
				searchQuery: searchQuery,
			}));
		};

		const handleFiltersChange = (newVal: object) => {
			setFetchParameters(prev => ({
				...prev,
				...newVal
			}));
		};

		return (
			<Stack
				spacing={2}
				sx={{
					display: 'flex',
					height: 1,
				}}
			>
				<Box id={toolbarIdString} />
				<DataGrid
					snapshotKey='accs'
					columns={columns}
					rows={data?.results ?? []}
					rowCount={totalCount}
					loading={isLoading || isValidating}
					error={error}
					onPaginationModelChange={handlePaginationChange}
					onFilterModelChange={handleFilterModelChange}
					slots={{
						toolbar: Toolbar,
					}}
					slotProps={{
						toolbar: {
							filters: fetchParameters,
							onFiltersChange: handleFiltersChange
						},
						noRowsOverlay: {
							title: t('noResourceFound', { resource: t('serviceTickets') }),
						},
						noResultsOverlay: {
							title: t('noResourceFound', { resource: t('serviceTickets') }),
						},
					}}
					sx={{ flexGrow: 1, flexBasis: 200 }}
				/>
			</Stack>
		);
	},
);
ServiceTicketsDataGrid.displayName = 'ServiceTicketsDataGrid';

const Toolbar = (props) => {
	const { t } = useTranslation('general');

	return (
		<Portal container={() => document.getElementById(toolbarIdString)}>
			<DataGridToolbar
				disabledOrganisationFilter
				filters={
					<>
						<OrganisationAutocomplete
							label={t('provider')}
							onChange={(_, newValue) => props.onFiltersChange?.({
								...props.filters,
								organisationId: newValue?.id
							})}
							sx={{ width: 150 }}
						/>
						<Select
							label='Status'
							onChange={(_, newValue) => props.onFiltersChange?.({
								...props.filters,
								status: newValue
							})}
							sx={{ width: 150 }}
						>
							{Object.values(ServiceTicketStatusEnum).map((el: ServiceTicketStatusEnum) =>
								<MenuItem value={el}>{el}</MenuItem>
							)}
						</Select>
						<Select label='Severity' sx={{ width: 150 }}>
							{Object.values(TicketSeverityEnum).map((el: TicketSeverityEnum) =>
								<MenuItem value={el}>{el}</MenuItem>
							)}
						</Select>
						<Select label='Category' sx={{ width: 150 }}>
							{Object.values(ItemCategoryEnum).map((el: ItemCategoryEnum) =>
								<MenuItem value={el}>{el}</MenuItem>
							)}
						</Select>
					</>
				}
			>
				<Button variant='contained'>
					Add ticket
				</Button>
			</DataGridToolbar>
		</Portal>
	);
};

export default ServiceTicketsDataGrid;
