//List of allowed countries - Source: https://skopei.atlassian.net/browse/TP-3128
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export const countryList = [
	'Belgium',
	'Bulgaria',
	'Cyprus',
	'Denmark',
	'Germany',
	'Estonia',
	'Finland',
	'France',
	'Greece',
	'Hungary',
	'Ireland',
	'Italy',
	'Croatia',
	'Latvia',
	'Lithuania',
	'Luxembourg',
	'Malta',
	'Netherlands',
	'Austria',
	'Poland',
	'Portugal',
	'Romania',
	'Slovenia',
	'Slovakia',
	'Spain',
	'Czech Republic',
	'Sweden',
	'United Kingdom',
];

export const languages = [
	{
		code: 'nl',
		language: 'dutch',
	},
	{
		code: 'de',
		language: 'german',
	},
	{
		code: 'en',
		language: 'english',
	},
];

export const gpsLocationIcons = {
	gpsFix: <GpsFixedIcon fontSize='small' />,
	gpsCopied: <ArrowDownwardIcon fontSize='small' />,
	cellTowerRequested: <CellTowerIcon fontSize='small' />,
	cellTowerCopied: <ContentCopyIcon fontSize='small' />,
	dockingLocation: <LocationOnIcon fontSize='small' />,
	none: '',
};

export const mapRestriction = {
	latLngBounds: { east: 179.9999, north: 85, south: -85, west: -179.9999 },
	strictBounds: true,
};

export const mapStyles = [
	{
		featureType: 'administrative.country',
		elementType: 'geometry.stroke',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'administrative.neighborhood',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'landscape.natural.landcover',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '19',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'all',
		stylers: [
			{
				visibility: 'on',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'geometry.fill',
		stylers: [
			{
				gamma: '1.00',
			},
		],
	},
	{
		featureType: 'poi',
		elementType: 'labels',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.park',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '39',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'geometry.fill',
		stylers: [
			{
				lightness: '60',
			},
		],
	},
	{
		featureType: 'road',
		elementType: 'labels.text',
		stylers: [
			{
				visibility: 'simplified',
			},
		],
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [
			{
				weight: '0.60',
			},
		],
	},
	{
		featureType: 'road.highway.controlled_access',
		elementType: 'geometry.stroke',
		stylers: [
			{
				weight: '0.70',
			},
		],
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [
			{
				lightness: '29',
			},
		],
	},
];

export const categories = {
	CARS: 3,
	TRAILERS: 4,
	BIKES: 1,
	OFFICES: 2,
	BOATS: 7,
	PARKINGLOTS: 5,
	ASSETS: 6,
};

export const exportType = {
	XLSX: 'xlsx',
	CSV: 'csv',
};

export const licensePlateLength = 12;

/**
 * Maximum length allowed for descriptions.
 * @type {number}
 * @constant
 * @default 1000
 */
export const descriptionMaxLength = 1000;

/**
 * Initial state for NFC-related data.
 *
 * @type {Object}
 * @property {string} cardType - The type of NFC card.
 * @property {string} card - The NFC card information.
 * @property {string} nfcId - The NFC ID.
 * @property {string} tagType - The tag type of card.
 * @property {number} organisationId - The organization ID for NFC.
 */
export const nfcInitialState = {
	cardType: 'business',
	card: '',
	nfcId: '',
	tagType: 'none',
	organisationId: 'all',
};
