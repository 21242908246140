import React, { forwardRef, PropsWithChildren, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
	Add as AddIcon,
	Business as BusinessIcon,
	Delete as DeleteIcon,
	DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';
import {
	Autocomplete,
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Chip,
	Dialog as MuiDialog,
	Divider,
	Icon,
	IconButton,
	Stack,
	SvgIcon,
	Switch,
	Table,
	TableCell,
	TableRow,
	TextFieldProps,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	useTheme,
	DialogTitle,
	DialogContent,
	DialogActions,
	MenuItem,
	Select,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { DateRangePicker } from 'react-dates';

import { ConfirmationDialog, Dialog, FormDialog, FormDrawer, PhoneNumberTextField, TextField, TransferList, WeeklyScheduleForm } from '~components';
import SkcNfcTagsService from '~features/nfc/services/skcNfcTagsService';
import { DayOfWeekEnum } from '~enums';
import WeekdayEnum from '~enums/dayOfWeekEnum';
import { useSnackbar } from '~hooks';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { CreateNfcTagForm, NfcAutocomplete } from '~features/nfc';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { ajvResolver } from '@hookform/resolvers/ajv';
import { formContainerAtom } from '~atoms';
import { useFormContainerState } from '~components/dialogs/formContainerProvider';
import { UserGroupAutocomplete } from '~features/userGroups';
import { getMutationDifferences } from '~utils/arrayUtils';
import { UserAutocomplete } from '~features/users';
import useSWRMutation from 'swr/mutation';
import { BookingsService } from '~features/bookings';
import { BaseReference } from '~interfaces';
import { BaseReferenceResponse } from '~interfaces/responses';
import { Item, ItemAutocomplete } from '~features/items';
import { Organisation, OrganisationAutocomplete } from '~features/organisations';
import { ServiceTicketsDataGrid } from '~features/maintenance';
import ItemGroupCard from '~features/itemGroups/components/cards/itemGroupCard';
import ItemCard from '~features/items/components/cards/itemCard';


const bookingService = new BookingsService();

/**
 * This page is meant as a sandbox for a develop to develop or
 * test a component outside of the normal flow
 */
const SandboxPage = () => {
	const [orgVal, setOrgVal] = useState<Organisation | null>(null);
	const [itemVal, setItemVal] = useState<Item | null>(null);
	const [val, setVal] = useState<string>();
	const theme = useTheme();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState(null);
	const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useSnackbar();

	const handleSendInvoiceClick = async (email = 'dennis.van.der.valk@skopei.com') => {
		try {
			await bookingService.sendBookingInvoice('10899', email);
			enqueueSuccessSnackbar('Super');
		} catch (error) {
			enqueueErrorSnackbar(error);
		}
	};

	// return (
	// 	<ServiceTicketsDataGrid ticketStatus='unclosed' />
	// )

	return (
		<Box>
			<ItemCard clearable itemGroupId={'1794'} id={'3241'} />
			<ItemGroupCard id={'1794'} />
			<ItemAutocomplete
				value={itemVal}
				onChange={(_, value) => setItemVal(value)}
			/>
			<UserAutocomplete
				organisationId='423'
				value={user}
				onChange={(_, value) => setUser(value)}
			/>
			<FormDrawer open={open} onClose={() => setOpen(false)}>
				<CreateNfcTagForm />
			</FormDrawer>
		</Box>
	);
};

interface RefProps {
	onSubmit: (value: any) => void;
}

interface FormContainerProps {
	onSubmit: (value: any) => void;
}

const FormContainer = forwardRef<RefProps, FormContainerProps>(({
	onSubmit
}, ref) => {
	const [value, setValue] = useState({});
	
	const { getValues, control } = useForm();

	useImperativeHandle(ref, () => ({
		onSubmit: () => onSubmit(getValues()),
	}), [ref, onSubmit]);

	return (
		<Controller
			name='val'
			control={control}
			render={({ field }) =>
				<TextField {...field} />
			}
		/>
	);
});
FormContainer.displayName = 'henk';

const FormUlt = ({
	children
}: {
	children: JSX.Element
}) => {
	const childRef = useRef<{ onSubmit: () => void }>(null);

	const handleClick = () => {
		childRef.current?.onSubmit();
	};

	return (
		<Box>
			{React.cloneElement(children, { ref: childRef })}
			<Button onClick={handleClick}>Sub</Button>
		</Box>
	);
};

export default SandboxPage;
