import {
	FormControl,
	InputLabel,
	Select as MuiSelect,
	SelectProps as MuiSelectProps,
} from '@mui/material';

/**
 * A generic wrapper around a select component
 * @param props 
 * @returns 
 */
const Select = (props: MuiSelectProps) => {
	const label = props.required && props.label ? `${props.label} *` : props.label;

	return (
		<FormControl fullWidth sx={props.sx}>
			<InputLabel id={props.labelId}>
				{label}
			</InputLabel>
			<MuiSelect {...props} label={label} />
		</FormControl>
	);
};

export default Select;
